.performance-module-container {
	width: 100%;
	height: inherit;
	border-radius: 12px;
	display: flex;
	overflow: hidden;
	--performance-module-sidebar-width: 30%;
}

.performance-module-sidebar {
	width: var(--performance-module-sidebar-width);
	background-color: var(--bg);
	/* background-color: #d2e1ff; */
	border-radius: 12px;
	padding: 14px 0px;
	padding-bottom: 80px;
	position: relative;
	overflow: auto;
}

.performance-module-content {
	width: calc(100% - var(--performance-module-sidebar-width) - 10px);
	margin-left: auto;
	border-radius: 12px;
	background-color: var(--bg);
	padding: 20px;
	display: flex;
	flex-direction: column;
	overflow: auto;
}

.performance-module-sidebar-item {
	width: 90%;
	/* border: 2px solid var(--theme-dark); */
	padding: 14px;
	border-radius: 10px;
	margin: auto;
	margin-top: 16px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	transition: backround-color 0.12s ease-in, border 0.12s ease-in,
		box-shadow 0.12s ease-in;
}

.performance-module-sidebar-item:hover {
	box-shadow: 0px 8px 4px rgba(173, 187, 210, 0.2);
}
.performance-module-sidebar-item-active {
	box-shadow: 0px 2px 4px rgba(173, 187, 210, 0.2);
	background-color: rgba(210, 225, 255, 0.5);
	border: 1px solid var(--theme);
}
.performance-module-sidebar-empty {
	width: 90%;
	margin: auto;
	margin-top: 20px;
}
.performance-module-redirect-btn {
	position: absolute;
	width: 300px;
	height: 40px;
	border-radius: 100px;
	background-color: var(--theme);
	color: #fff;
	display: flex;
	box-shadow: 0px 8px 4px rgba(173, 187, 210, 0.2);
	align-items: center;
	justify-content: center;
	bottom: 20px;
	font-weight: 600;
	left: calc(50% - 150px);
	/* left: 20px; */
}
.performance-module-redirect-btn-sideline {
	bottom: 50px;
	width: 80%;
	width: calc(var(--performance-module-sidebar-width) - 50px);
	left: 42px;
}
