/* TODO: Cleanup unused CSS, now only being used for RegisterTeamStep */
.register-step .onboarding-step-main-footer {
	flex-wrap: wrap !important;
}

.register-step .onboarding-step-main-content {
	padding-bottom: 30px;
}

.register-step .register-step-tos {
	width: 100%;
	text-align: right;
	font-size: 1.1rem;
	position: relative;
	bottom: 5px;
}

.register-step .onboarding-step-aside-hero {
	right: 30%;
}

.register-step-tos .checkbox {
	margin-right: 8px;
	margin-left: -15px;
	position: relative;
	top: 5px;
	background-color: var(--bg-light);
	box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.08);
}

.register-step-tos a {
	color: var(--theme);
	font-weight: 400;
	margin: 0 4px;
}

.register-step .tab-switcher-container {
	width: 100%;
	cursor: pointer;
}
.register-step-disabled .inp {
	background-color: var(--bg-dark-2);
	color: var(--text-light);
}
