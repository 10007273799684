.module-rules {
	width: 100%;
	position: relative;
	border-radius: 12px;
	background-color: var(--bg-light);
	margin-bottom: 20px;
	margin-top: 12px;
}

.module-rules .select {
	padding: 9px;
}

.module-rules .select .select-icon {
	top: 8px;
}

.module-rules-edit-mode {
	padding: 0px;
	padding-bottom: 5px;
	width: calc(100% + 30px);

	margin-left: -30px;
	margin-bottom: 0px;
}
.module-rule-conditional {
	flex-direction: row;
	align-items: center;
	margin-bottom: 8px;
	background-color: var(--bg);
	border-radius: 8px;
	font-size: 1.1rem;
	display: flex;
	padding: 8px;
	color: var(--text-light);
}
.module-rule-remove {
	font-size: 1.4rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
}

.module-rules-apply-select {
	width: 135px;
	margin: 0 10px;
}

.module-rules-add {
	display: block !important;
	margin: auto;
	margin-top: 20px;
	width: 100%;
	height: 45px;
	border-radius: 12px !important;
}

.module-rules-sep {
	position: relative;
	/* width: 100%; */
	width: 50%;
	text-align: center;
	text-transform: uppercase;
	font-size: 1.05rem;
	margin: auto;
	/* margin-right: auto; */
	/* margin-left: -22px; */
	font-weight: 600;
	color: var(--theme);
	padding: 0px;
}

.module-rules-sep-edit {
	padding: 7px 0;
	margin: auto;
}

.module-rules-sep::after,
.module-rules-sep::before {
	position: absolute;
	height: 2px;
	width: 60px;
	content: "";
	border-radius: 2px;
	background-color: var(--theme);
	top: calc(50% - 1px);
	left: 50%;
}

.module-rules-sep::after {
	margin-left: -90px;
}

.module-rules-sep::before {
	margin-left: 30px;
}
