.stepped-modal {
	padding: 0px;
	max-height: 80vh !important;
}
.stepped-modal-fixed {
	overflow: hidden;
}
.stepped-modal .modal-header {
	background-color: var(--bg-light);
	margin-top: 0px;
	padding: 14px 20px 0px 20px;
	/* position: sticky; */
	top: 0px;
	z-index: 20;
}
.stepped-modal .modal-content {
	margin-top: -10px;
}
.stepped-modal-progress-bar {
	padding: 10px 20px;
	/* position: sticky; */
	top: 44px;
	background-color: var(--bg-light);
	margin-bottom: 10px;
	z-index: 20;
}
.stepped-modal-content {
	margin: auto;
	width: 100%;
	padding-left: calc(5%);
	padding-right: calc(5%);
	z-index: 1;
	padding-bottom: 8px;
	max-height: 51vh;
	overflow: auto;
}
.stepped-modal-error {
	width: 90%;
	margin: auto;
	margin-top: 10px;
}
.stepped-modal-action-container {
	margin-top: 20px;
	padding: 10px 0px;
	padding-bottom: 15px;
	/* position: sticky; */
	bottom: 0px;
	z-index: 21;
	background-color: transparent;
	/* background-color: rgba(250, 250, 250, 0.3); */
	backdrop-filter: blur(3px);
}
.stepped-modal .modal-select-main-btn-container {
	bottom: 0px;
	background-color: transparent;
	position: relative;
	/* margin: auto; */
	/* width: 100%;
	left: 0px;
	padding: 0px;
	margin-left: 0px; */
}
.stepped-modal .modal-select-main-btn-container .btn {
	font-size: 13.33px;
	font-weight: 500;
}
/* .stepped-modal-overlay-container {
	position: absolute;
	top: -50px;
	background-color: red;
	height: 100%;
	width: 100%;
} */
.stepped-modal .modal-select-overlay {
	/* top: -100px; */
	position: absolute;
	top: 0px;
	height: 100%;
	bottom: 0px;
	/* height: auto; */
	z-index: 112;
}
