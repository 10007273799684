.script-setup {
	width: 100%;
	border-radius: 15px;
	background-color: var(--bg);
}

.script-setup .disco-select {
	width: 350px !important;
	margin: 5px 0 30px 0;
}

.script-setup ol {
	margin: auto;
	margin-top: 10px;
	width: 95%;
}

.script-setup li {
	margin-top: 18px;
	font-size: 1.29rem;
	font-weight: 400;
}

.script-setup .badge-btn {
	display: block !important;
	margin: auto;
	margin-top: 10px;
}

.script-setup img,
.script-setup .copy-block {
	width: 100%;
	margin-top: 10px;
	border-radius: 6px;
}
