.base-image-modal {
	max-width: 700px !important;
	max-height: 90% !important;
}
.image-input .thumbnail {
	width: 200px;
	height: 200px;
}

.base-image-modal .loader {
	margin-top: 20px;
}
