.cross-sell-module-wrapper {
	width: 100%;
}
.new-cross-sell-module .modal-list-inp-container-parent {
	margin-bottom: 20px;
}
.new-cross-sell-module .select {
	width: 100%;
}
.new-cross-sell-module .select .inp {
	margin-top: 10px;
}
.new-cross-sell-module .products-table-container {
	margin-top: -20px;
}
.new-cross-sell-module .tab {
	text-transform: none;
}

.cross-sell-module-toggler-header {
	display: flex;
	flex-direction: row-reverse;
	width: 100%;
	height: 40px;
}

.cross-sell-module-header-publish {
	--bg: transparent;
}

.cross-sell-module-header-preview-btn,
.cross-sell-module-header-publish-btn {
	padding-right: var(--disco-spacing-sm);
}

.cross-sell-module-header-preview .disco-select-options {
	width: 240px;
	left: -135px;
	top: 45px;
}

.cross-sell-module-header-publish .disco-select-icon {
	color: var(--disco-text-white);
}

.cross-sell-module-header-publish .disco-select-icon:hover {
	background-color: var(--disco-blue-mid);
}

.cross-sell-module-header-publish .disco-select-options {
	width: 324px;
	left: -225px;
	top: 45px;
}

.cross-sell-module-header-checkbox {
	border-bottom: var(--border-bottom);
	border-radius: 0;
}

.cross-sell-module-header-checkbox:nth-child(2) {
	border-bottom: none;
}

.cross-sell-module-header-publish .disco-select-options-wrapper,
.cross-sell-module-header-publish .disco-select-options {
	max-height: unset;
}

.cross-sell-module-banner {
	width: 100%;
}
.cross-sell-module-banner.new-edit-image > img {
	width: 100%;
	max-width: 1400px;
	aspect-ratio: 16 / 9;
	border: var(--disco-stroke-md) solid var(--disco-text-stroke);
	border-radius: var(--disco-curve-lg);
	box-shadow: var(--disco-shadow-md);
	object-fit: cover;
	height: unset;
}

.cross-sell-module-short-desc .disco-text-inp-container-textarea {
	--height: 72px;
}
