.not-found-page {
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  background-image: var(--theme-gradient);
  text-align: center;
}

.not-found-page h1 {
  position: absolute;
  font-size: 26rem;
  /* color: var(--bg-light); */
  font-weight: 700;
  opacity: 0.07;
  top: calc(50% - 27rem);
}

.not-found-page h2,
.not-found-page p,
.not-found-page .badge-btn {
  position: relative;
  color: var(--bg-light);
  font-size: 4rem;
}

.not-found-page p {
  font-size: 1.4rem;
  max-width: 600px;
  margin-top: 10px;
}

.not-found-page .badge-btn {
  font-size: 0.9rem;
  margin-top: 40px;
}

.not-found-page .badge-btn:hover {
  background-color: var(--bg-light) !important;
  color: var(--theme) !important;
}

.not-found-page span {
  font-size: 3.5rem;
}
