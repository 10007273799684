.register-view .central-view-background {
	width: 80%;
	top: 10vw;
}

.register-view .central-view-hero {
	width: 50%;
	max-width: 800px;
}

.register-view .central-view-heading {
	text-transform: capitalize;
}

.register-view-hero > main {
	overflow: hidden !important;
}

.register-view .h-form {
	margin: 15px 0;
}

.register-view-tos .checkbox {
	margin-right: 15px;
}

.register-view a {
	color: var(--theme);
	font-weight: 400;
}

.register-view-tos a {
	margin: 0 4px;
}

.link-email-modal-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@media screen and (max-width: 600px) {
	.register-view .central-view-hero {
		width: 100%;
	}

	.register-view .tab-switcher-background {
		width: 100%;
	}

	.register-view .tab-switcher-tab {
		width: 50%;
		justify-content: center;
	}
}

.register-view .base-form {
	width: 90%;
}

.register-view .registration-form-fields {
	display: grid;
	grid-template-columns: repeat(2, minmax(0, 1fr));
	row-gap: 24px;
	column-gap: 32px;
}

.register-view .registration-form-fields .error-text {
	font-size: 1rem;
	color: var(--red-dark);
	font-weight: 300;
}

.register-view .registration-form-fields .has-error .disco-inp-container {
	border: 1px solid var(--red-dark);
}

.register-view .registration-form-fields .password-tooltip ul {
	margin-left: 1rem;
}

.register-view .registration-form-fields .password-tooltip li {
	color: var(--text);
	font-size: small;
}

.col-span-2 {
	grid-column: span 2 / span 2;
}

.register-view .register-view-tos {
	display: flex;
	justify-content: center;
}

.register-view .register-view-tos a {
	font-weight: 500;
}

.register-view .register-view-tos .checkbox-element {
	display: flex;
	align-items: center;
}

.register-view-footer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.register-view-footer .loader {
	margin-top: 12px;
}

.register-view-footer .disco-btn {
	margin-top: 0px !important;
}

.register-view-demo {
	margin: 20px 0 12px 0;
	font-size: 1.1rem;
	transition: opacity 0.3s cubic-bezier(0, 1.05, 0.58, 1.69),
		transform 0.3s cubic-bezier(0, 1.05, 0.58, 1.69);
	transform: scale(1);
}

.register-view-demo span {
	font-weight: 500;
	color: var(--theme-2);
	cursor: pointer;
}

.register-view-demo-disabled {
	pointer-events: none;
	opacity: 0.5;
}

.register-view-login {
	font-weight: 400;
	font-size: 1.1rem;
	color: var(--text-light-2);
}

.register-view-login a {
	color: var(--theme);
	font-weight: 500;
}

.platforms-view-platform-selected {
	border-color: var(--theme);
}

.platforms-view-platform .disco-radio-element-content {
	flex-grow: 1;
}

.platforms-view-platform .disco-radio-element-content .another-platform-icon {
	margin-right: 1rem;
	padding: 5px;
	background-color: var(--theme-light);
	border-radius: 4px;
	display: flex;
	color: var(--theme);
}

.platforms-view-platform p {
	display: flex;
	align-items: center;
}

.platforms-view-platform .disco-text-label {
	height: 50px;
}

.platforms-view-platform img {
	width: 25%;
	min-width: 100px;
}

.platforms-view-btn-panel {
	display: flex;
	flex-grow: 1;
	justify-content: right;
}

.platforms-view-external-btn {
	display: flex;
	vertical-align: middle;
}

.platforms-view-external-btn svg {
	width: 8%;
	margin-left: 10px;
}

.registration-form {
	width: 100%;
}

.registration-form .disco-text-inp {
	min-width: auto;
}


.registration-form .forgot-password-link {
	font-size: 12;
	flex-grow: 1;
	text-align: right;
}

.span-as-anchor {
	cursor: pointer;
	color: var(--theme-dark);
}

@media screen and (max-width: 600px) {
	.platforms-view-platforms {
		padding: 0;
	}

	.platforms-view-platform {
		height: 50px;
	}

	.platforms-view-platform img {
		width: 35%;
	}

	.platforms-view-external-btn {
		padding: 5px 20px;
	}

	.platforms-view-external-btn span {
		display: none;
	}

	.platforms-view-external-btn svg {
		width: 100%;
		margin-left: 0;
		padding: 0 20px;
	}
}
