.overlay-preview-container {
	position: relative;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	width: 100%;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
}
.overlay-preview-container-internal {
	margin-bottom: 20px;
}
.overlay-preview-container-internal > img {
	width: 100% !important;
}
.overlay-preview {
	position: relative;
	height: 300px;
	margin: 0 10px;
}

.overlay-preview > img {
	pointer-events: none;
	position: relative;
	height: 100%;
}

.overlay-preview-body {
	position: absolute;
	height: calc(100% - 20px);
	width: 98%;
	border-top-right-radius: 15px;
	border-top-left-radius: 15px;
	top: 10px;
	left: 0;
	right: 0;
	margin: auto;
	background-color: var(--bg-light);
}

.overlay-preview-content {
	position: absolute;
	width: 80%;
	height: 85%;
	background-color: var(--bg-light);
	left: 0;
	right: 0;
	bottom: 15px;
	border-radius: 20px;
	margin: auto;
	box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.08);
	display: flex;
	align-items: center;
	justify-content: center;
}

.overlay-preview-content img {
	height: 80px;
	width: 80px;
	object-fit: contain;
	border-radius: 50%;
	box-shadow: 0px 0px 6px 1.5px rgba(0, 0, 0, 0.09);
}
