.module-rule {
	display: flex;
	flex-direction: column;
	background-color: var(--bg);
	border-radius: 12px;
}
.module-rule-edit {
	padding: 8px;
}

.module-rule .select-container {
	width: 100%;
	margin-top: 7px;
}
.module-rule .disco-select-container {
	width: 100%;
}
.module-rule .select-container .select {
	width: 100%;
}
.module-rule .inp {
	height: 42px;
	margin-top: 7px;
}
.module-rule-row {
	display: flex;
	margin-top: 10px;
	justify-content: space-around;
	align-items: flex-start;
	flex-grow: 2 1 1;
}

.module-rule-row-checkbox {
	width: 120px;
	margin-left: 10px;
	flex: 0 0 auto;
	height: 50px;
	display: flex;
	align-items: center;
}

.module-rule-row-checkbox .checkbox {
	margin-right: 5px;
}

.module-rule-row-large {
	margin-right: 10px;
}
.module-rule-row-vertical {
	flex-direction: column;
}
.module-rule-remove {
	border: 0px !important;
	display: flex;
	font-size: 1.1rem !important;
	border-radius: 10px !important;
	padding: 10px 20px;
	margin: auto;
	margin-top: 10px;
	line-height: 15px;
	color: var(--red);
	align-items: center;
}
.module-rule-remove svg {
	margin-right: 8px;
	font-size: 1rem;
}

.module-rule-text {
	text-transform: uppercase;
	color: var(--text-light);
	font-weight: 600;
}
.module-rule-static {
	font-weight: 600;
	margin-right: auto;
	background-color: var(--bg-light);
	width: 100%;
	border-radius: 8px;
	font-size: 1.1rem;
	/* padding: 10px 12px; */
	padding: 20px 10px;
	color: var(--text);
}
.module-rule-static-inline {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.module-rule-static-inline .mini-badge:nth-of-type(1) {
	margin-left: 8px;
}
.module-rule-conditional {
	justify-content: flex-start;
}

.module-rule-conditional > * {
	flex: none !important;
}
.module-rule-conditional .module-rule-text {
	width: auto;
}
.module-rule-conditional .module-rule-conditional-complete {
	padding-left: 10px;
}
.module-rule-product-selector {
	margin-top: 10px;
}
.module-rule-product-selector .inp {
	height: 40px;
	margin-top: 10px;
}

.module-rule-row-inp-large {
	width: 100%;
	max-width: initial;
}
