.tos-update-modal-card {
	width: 690px;
}
.tos-update-modal-card header {
	display: flex;
	align-items: center;
	padding: 30px 0px;
	padding-bottom: 20px;
	justify-content: center;
}
.tos-update-modal-card header img {
	width: 40px;
	margin-right: 16px;
}
.tos-update-modal-card header h2 {
	font-size: 2.1rem;
	font-weight: 600;
	line-height: 32px;
}
.tos-update-modal-card > main {
	padding: 20px 60px;
}
.tos-update-modal-card-text {
	font-size: 1.4rem;
	color: var(--text-light);
	line-height: 30px;
}
.tos-update-modal-card-text > span {
	color: var(--theme-3);
	cursor: pointer;
}
