.audiences-category-badges {
	flex-wrap: wrap;
	width: 100%;
}
.audiences-category-badge {
	width: fit-content;
	--curve: var(--disco-curve-sm);
	border: none;
	outline: var(--border);
}
.audiences-category-badge-selected {
	--border-color: var(--disco-theme-mid);
	--border-size: var(--disco-stroke-lg);
}
.audiences-category-badge-disabled {
	opacity: var(--disco-disabled-opacity);
}
