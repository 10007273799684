.alert-bar {
	--alert-bar-bg: radial-gradient(
		50% 675.96% at 50% 50%,
		#5792ff 0%,
		#ff00ff 0.01%,
		#be1cff 0.02%,
		#793aff 56.25%,
		#484fff 73.96%,
		#5792ff 100%
	);
	--alert-bar-bg-translucent: radial-gradient(
		50% 675.96% at 50% 50%,
		rgba(87, 146, 255, 0.86) 0%,
		rgba(255, 0, 255, 0.86) 0.01%,
		rgba(191, 28, 255, 0.86) 0.02%,
		rgba(120, 58, 255, 0.86) 56.25%,
		rgba(72, 78, 255, 0.86) 73.96%,
		rgba(87, 146, 255, 0.86) 100%
	);
	--alert-bar-box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.2);
	--alert-bar-signature-grad: linear-gradient(
		269.42deg,
		#34f6f2 17.08%,
		#faff00 90.04%
	);
	width: 100vw;
	height: var(--alert-bar-height);
	display: flex;
	flex-direction: row;
	margin-top: -2px;
	align-items: center;
	justify-content: center;
	background: var(--alert-bar-bg);
	/* background: var(--alert-bar-bg-translucent); */
	color: #ffffff;
	backdrop-filter: blur(8px);

	text-shadow: -1px 1.5px 0.5px rgba(0, 0, 0, 0.1);

	box-shadow: var(--alert-bar-box-shadow);
}
