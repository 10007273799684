.floating-modal-overlay {
	background-color: var(--theme-light-2);
}

.floating-modal {
	background-color: var(--bg-light);
	padding: 0 !important;
}

.floating-modal .modal-header {
	border-bottom: 2px solid var(--text-light-5);
	padding: 10px 20px !important;
	margin: 0;
}

.floating-modal .modal-header h2 {
	font-weight: 500;
	color: var(--text);
	font-size: 1.15rem;
	text-transform: capitalize;
	letter-spacing: normal;
}

.sidebar-modal {
	max-height: initial;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
