.register-feature-step-action {
	display: flex;
	width: 100%;
	overflow: hidden;
	margin: 20px 0 30px 0;
}

.register-feature-step-action .btn {
	width: 50%;
	white-space: nowrap;
	flex: 1 1 auto;
	margin: 0 8px;
}

.register-feature-step-action-login span {
	color: var(--text-light);
}
