.alert-bar-indicator {
	height: 16px;
	width: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0px 2px;
}
.alert-bar-module,
.alert-bar-module-section {
	display: flex;
	align-items: center;
	justify-content: center;
}
.alert-bar-module-section {
	margin: 0px 20px;
}
.alert-bar-button {
	height: 24px;
	text-shadow: none !important;
}

.alert-bar-gradient-text {
	background: linear-gradient(269.42deg, #34f6f2 17.08%, #faff00 90.04%);
	background-size: 100%;
	background-clip: text;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	-webkit-text-fill-color: transparent;
	-moz-text-fill-color: transparent;
}
