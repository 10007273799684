.reset-confirmation-step .btn {
	margin-left: 0px;
	margin-right: auto;
	width: 100%;
}

/* .reset-confirmation-step .onboarding-step-main-header {
	width: 65%;
} */

.reset-confirmation-step .guided-view-content {
	width: 45% !important;
}
