.shops-selector {
	width: 100%;
}
.shops-selector-shop {
	width: 100%;
	padding: 15px 10px;
	background-color: var(--bg-light);
	border-radius: 8px;
	margin: 10px 0 10px 0;
	cursor: pointer;
	font-weight: 600;
	font-size: 1.1rem;
	box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.06);
	transition: box-shadow 0.2s;
}

.shops-selector-shop:hover {
	box-shadow: 0px 8px 12px 5px rgba(0, 0, 0, 0.1);
}

.shops-selector .loader,
.shops-selector .form-status {
	margin: 15px auto;
}
