.variants-modal {
	box-shadow: 0px 5px 20px 5px rgba(0, 0, 0, 0.1);
	background-color: var(--disco-bg-default);
	width: 60% !important;
	max-width: initial;
}

.variants-modal .modal-content {
	overflow: auto;
	max-height: 60vh;
	margin: 0 !important;
}

.variants-modal .modal-header {
	background-color: var(--disco-bg-light);
}

.variants-table {
	margin-top: calc(0px - var(--disco-spacing-sm));
	margin-bottom: var(--disco-spacing-sm);
	background-color: transparent;
}

.variants-modal .variants-table {
	width: 95%;
	margin: 0 auto;
}

.variants-table-selector {
	background-color: var(--disco-bg-light);
	margin-top: var(--disco-spacing-md);
}

.variants-table-selected {
	background-color: var(--theme-light-3) !important;
}

.variants-table .disco-table-header-item .disco-text-thick {
	font-size: 1rem !important;
}

.variants-table .disco-remote-inp-container {
	width: 350px;
}

.variants-table-img {
	height: 75px;
	width: 75px;
	position: relative;
	display: inline-block;
	overflow: hidden;
	border-radius: var(--disco-curve-sm);
	cursor: pointer;
}

.variants-table-img img {
	object-fit: cover;
	height: 100%;
	width: 100%;
}

tr:hover .variants-table-img .variants-table-img-overlay {
	opacity: 1;
}

.variants-table-img-overlay {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(3px);
	opacity: 0;
	transition: opacity 0.2s;
	text-align: center;
}

.variants-modal-action {
	display: flex;
}

.variants-modal .disco-modal-header {
	border-bottom: 2px solid var(--text-stroke-stroke, rgba(1, 2, 4, 0.1));
	background-color: var(--disco-bg-light);
}

.variants-table-checkbox {
	background-color: var(--bg-light);
	border-color: rgba(0, 0, 0, 0.25);
	margin: 0 0 0 auto;
}

.variants-table-right-align {
	text-align: right;
}

.variants-modal-action > .disco-btn {
	margin: 0 var(--disco-spacing-mc);
}

.variants-table-none {
	display: inline-block;
	border-radius: var(--disco-curve-sm);
	background-color: var(--disco-text-stroke);
	color: rgba(0, 0, 0, 0.6);
	text-align: left;
	width: 90%;
}

.variants-table-none a {
	color: var(--disco-blue-mid);
}

.variants-table-active-header {
	width: 68px;
}

.variants-table-active-badge {
	width: 55px;
}

.variants-table .table-header-item-active-header {
	width: 68px;
}

/* .variants-table .table-header-item-name {
	width: 190px !important;
} */
