.shopify-card {
	margin-top: 70px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.shopify-card .form-status .loader {
	display: block;
	margin: 10px auto 10px auto;
}

.shopify-card .form-status-theme {
	font-size: 1.2rem;
}

.shopify-logo {
	font-size: 5rem;
}

.shopify-card h2 {
	font-weight: 700;
	font-size: 1.5rem;
}

.shopify-card p {
	width: 90%;
	margin-top: 15px;
	font-size: 1.1rem;
}

.shopify-card p span {
	color: var(--theme);
}

.shopify-card .inp {
	width: 90%;
	margin-top: 25px;
}

.shopify-card .btn,
.shopify-card .loader {
	margin-top: 15px;
}
