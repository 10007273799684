.lead-offers-klaviyo-config-status-label .disco-status-label-detail {
	width: 100%;
}
.lead-offers-klaviyo-config-manager-info-dropdown .disco-select-selected {
	min-width: 20px;
	border-radius: var(--disco-curve-tn);
}
.lead-offers-klaviyo-config-manager-info-dropdown .disco-select-selected:hover {
	background-color: var(--disco-bg-mid);
}
.lead-offers-klaviyo-config-manager-info-dropdown .disco-select-options {
	--top: 35px;
	right: 0px;
	left: unset;
	min-width: 105px;
}
