.emails-step {
	display: flex;
	width: 96%;
	padding: 30px;
	border-radius: 20px;
	background-color: var(--bg-light);
	box-shadow: 0px 0px 20px 4px rgba(0, 0, 0, 0.08);
}

.emails-step > main {
	width: 100% !important;
	padding-right: 20px;
}

.emails-step > aside {
	width: 55%;
	border-radius: 12px;
	border: 1px solid var(--text-light-5);
	padding: 16px;
	flex: 0 0 auto;
	max-width: 600px;
}

.emails-step > main > h3 {
	font-size: 2.1rem;
	font-weight: 300;
	color: var(--text-light-3);
	margin: 15px 0;
}

.emails-step > main > h1 {
	font-size: 3.5rem;
	color: var(--text);
	letter-spacing: -1px;
	margin-bottom: 20px;
}

.emails-step-action {
	margin-top: 25px;
	display: flex;
	justify-content: stretch;
}

.emails-step-action > .btn {
	width: 100%;
}

.email-step-final {
	height: 90%;
}

.emails-step-final-container {
	width: 100%;
	height: 100%;
	position: relative;
}

.emails-step-final-container-bg {
	width: 100%;
	height: 100%;
	position: absolute;
	object-fit: cover;
	border-radius: 10px;
	z-index: 0;
}
