.edit-select-list .badge-btn b {
	font-size: 1.4rem;
	color: var(--text);
	display: inline-block;
	margin-top: -10px;
	margin-left: 4px;
	position: relative;
	top: 5px;
	opacity: 0.6;
}
