.product-selector {
	width: 100%;
	position: relative;
	padding: 10px;
	border-radius: 10px;
	background-color: var(--bg-light);
	margin: 12px auto;
}

.product-selector .loader {
	margin: 7px auto;
}

.product-selector .labeled-inp {
	margin-top: 0px;
}

.product-selector-guided-tooltip {
	height: 80px;
	display: flex;
	align-items: center;
	width: 310px;
	padding: 10px;
}
.product-selector-guided-tooltip h2 {
	font-weight: 600;
	font-size: 1.2rem;
	color: var(--text-light);
	letter-spacing: 0;
}
.product-selector-guided-tooltip img {
	height: 100%;
	border-radius: 9px;
	object-fit: cover;
	margin-right: 10px;
}
