.policy-migration-flow-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.policy-migration-flow-footer-actions {
	display: flex;
	align-items: center;
}

.policy-migration-flow-footer-actions button {
	margin: 0 8px;
}

.policy-migration-flow-footer > section {
	font-size: 1.3rem;
	font-weight: 600;
	margin-bottom: 30px;
}
.policy-migration-flow-footer > section span {
	color: var(--theme-2);
	cursor: pointer;
}
.policy-migration-flow-footer > section > .checkbox {
	margin-right: 10px;
	position: relative;
	top: 6px;
}
.policy-migration-flow-footer > .btn {
	display: flex;
	align-items: center;
	justify-content: center;
}
.policy-migration-flow-footer > .btn svg {
	margin-left: 8px;
}
