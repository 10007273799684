.module-preview-content-child {
	background-color: var(--bg-light);
	z-index: 100;
	border-radius: 10px;
	position: absolute;
	width: 100%;
	top: 60px;
	max-height: 600px;
	overflow: visible;
}
.module-preview-content-child-inline {
	position: initial;
	max-height: none !important;
	z-index: initial;
}

.module-preview-content-child header {
	height: 48px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid var(--text-light-4);
	padding: 30px;
}
.module-preview-content-child-header-open {
	cursor: pointer;
	color: var(--text-light-3);
}

.module-preview-content-child-header-open span {
	margin-left: 4px;
	font-size: 1.2rem;
	position: relative;
	top: 2px;
}

.module-preview-content-child header > h3 {
	font-weight: 500;
	font-size: 1.35rem;
	margin-right: 5px;
	color: var(--text-light);
}

.module-preview-content-child-filters {
	background-color: var(--bg-dark);
	padding: 20px 0px;
	display: flex;
	justify-content: flex-start;
}

.module-preview-content-child-filters .radio-inp-container {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 120px;
	width: 28%;
	padding: 20px 5px 0 5px;
	overflow: auto;
}

.module-preview-content-child-filters-extended .radio-inp-container {
	height: 175px;
	padding: 20px 5px 45px 5px;
	overflow: unset;
}
.module-preview-content-child-filters .radio-inp-container:nth-of-type(2) {
	width: 42%;
}
.module-preview-content-child-filters
	.radio-inp-container:nth-of-type(2)
	.radio-inp-option:last-of-type {
	width: 50%;
}

.module-preview-content-child-filters .radio-inp-option {
	margin: 12.5px 12px 0 0;
	font-size: 1.2rem;
}

.module-preview-content-child-filters .radio-inp-container h3,
.module-preview-content-child-filters .date-inp-container h3 {
	position: absolute;
	width: 100%;
	top: 0;
	color: var(--text-light-3);
	border-bottom: 1px solid var(--text-light-3);
	font-weight: 400;
	flex: auto 0 0;
}
.module-preview-content-child-render {
	padding: 20px 0px;
}
.module-preview-content-child-render .form-status {
	width: 96%;
	margin: 0px auto;
}
.module-preview-content-child-render .loader {
	margin: 0px auto;
}
.module-preview-content-child-fiter-custom-date
	.radio-inp-option-content-children {
	position: absolute;
	bottom: 0px;
	right: 0px;
	left: auto;
}
.module-preview-content-child-fiter-custom-date
	.radio-inp-option-content-children
	.react-daterange-picker {
	width: 100%;
}

.module-preview-content-child-fiter-custom-date
	.radio-inp-option-content-children
	.react-daterange-picker__range-divider {
	display: inline-block;
	margin: auto !important;
	margin-left: -8px !important;
	margin-right: 8px !important;
}
