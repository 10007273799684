.onboarding-step {
	width: 100%;
	--onboarding-aside-width: 45%;
	min-height: 100vh;
	background-image: var(--onboarding-gradient);
	overflow: hidden;
}

.onboarding-step-right {
	--onboarding-aside-width: 50%;
}
.onboarding-step-aside {
	position: absolute;
	width: calc(var(--onboarding-aside-width) + 20px);
	left: 0;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.onboarding-step-main
/* ,.modal-overlay-onboarding  */ {
	display: flex;
	flex-direction: column;
	padding: 32px;
	position: absolute;
	width: var(--onboarding-aside-width);
	width: calc(100vw - var(--onboarding-aside-width) - 6px);
	left: calc(var(--onboarding-aside-width) - 6px);
	height: 98%;
	top: 1%;
	background-color: var(--bg-light);
	border-radius: 25px;
	overflow: auto;
}

.onboarding-step-main-bright {
	background-color: var(--bg);
}

.onboarding-step-right .onboarding-step-main {
	right: initial;
	left: 1vh;
	border-radius: 15px;
}

.onboarding-step-right .onboarding-step-aside {
	right: 0;
	left: initial;
	overflow: hidden;
}

.onboarding-step-aside-hero {
	position: absolute;
	left: -15%;
	right: 0;
	margin: auto;
	overflow: hidden;
	bottom: 40%;
	transform: translateY(50%);
	z-index: 2;
}

.onboarding-step-main-header {
	width: 100%;
	display: flex;
	align-items: center;
	flex: 0 0 auto;
}

.onboarding-step-main-header .progress-bar {
	margin-top: 0;
}

.onboarding-step-main-content {
	height: 100%;
	overflow: auto;
}

.onboarding-step-main-no-pad .onboarding-step-main-content {
	padding-bottom: 0;
}

.onboarding-step-main-content-padded {
	padding: 30px 0;
}

.onboarding-step-main-bright .onboarding-step-main-content {
	background-color: var(--bg-light);
	border: none;
	box-shadow: 0px 10px 30px 3px rgba(0, 0, 0, 0.1);
}

.onboarding-step-main-hero {
	width: 100%;
	height: auto;
	margin-bottom: 10px;
}

.onboarding-step-main-footer {
	flex: 0 0 auto;
	margin-top: 15px;
	display: flex;
	justify-content: end;
	align-items: center;
	align-content: flex-end;
	background-color: rgba(255, 255, 255, 0.8);
	z-index: 16;
	width: 100%;
}

.onboarding-step-main-footer > section {
	display: flex;
	align-items: center;
	margin-left: 10px;
}

.onboarding-step-main-footer .btn,
.onboarding-step-main-footer-next-bar {
	margin: 0;
	flex: 0 0 auto;
	margin-right: 10px;
}

.onboarding-step-main-footer-reinstall {
	width: 100%;
}

.onboarding-step-main-footer > .form-status {
	/* width: 100%; */
	margin: 10px 0 10px 0;
}

.onboarding-step-main-footer .disco-btn-secondary {
	box-shadow: none;
}

.onboarding-step-aside-logo {
	width: 80px;
	margin-top: 60px;
}

.onboarding-step-aside > h1 {
	position: relative;
	font-size: 8rem;
	margin-top: 15px;
	font-weight: 800;
	color: var(--bg-light);
	letter-spacing: -5px;
	text-align: center;
}

.onboarding-step-aside-tooltip {
	top: 6%;
	width: 330px;
	font-size: 1.6rem;
	padding: 28px;
	font-weight: 700;
	display: flex;
	align-items: center;
}

.onboarding-step-aside-tooltip-img {
	width: 80%;
}

.onboarding-step-aside-tooltip > img {
	flex: 0 0 auto;
	height: 80px;
	margin-right: 20px;
}

.onboarding-step-main-hero-required {
	margin-top: 6px;
	font-size: 1.2rem;
	color: var(--text);
}

.onboarding-step-main-hero-required span {
	color: var(--theme);
	display: inline-block;
	position: relative;
	font-size: 1.4rem;
	top: 4px;
}

.onboarding-step-main .grid-form {
	margin-top: -15px;
}

.onboarding-step-main .labeled-inp,
.onboarding-step-main .select {
	width: 100%;
	border-radius: 12px;
}

.onboarding-step-main .label {
	margin-top: 20px;
}
.onboarding-step-main .modal-list-inp-container-parent-card .label {
	margin-top: 0px;
}

/* .onboarding-step-main .labeled-inp:nth-of-type(1) {
	margin-top: 0;
} */

.onboarding-step-main .labeled-inp:nth-of-type(1) .label {
	margin-top: 0;
}

.onboarding-step-main .labeled-inp .inp {
	height: 50px;
}

.onboarding-step-main textarea.inp {
	height: 86px !important;
}

.calculator-info {
	display: flex;
	width: 100%;
	background-color: var(--bg-dark);
	align-items: center;
	justify-content: center;
	padding: 18px;
	border-radius: 15px;
	margin-top: 20px;
}

.calculator-info-minimal {
	border-radius: 0;
	background-color: var(--bg);
	border-top: 1px solid var(--text-light-5);
	padding: 30px;
}

.calculator-info img {
	height: 70px;
	flex: auto 0 0;
}

.calculator-info main {
	width: 100%;
	flex: auto 1 1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 20px;
}
.calculator-info main div {
	display: flex;
	width: 100%;
	align-items: baseline;
	justify-content: flex-start;
}

.calculator-info main h3 {
	font-weight: 300;
	font-size: 1.4rem;
	color: var(--text-light-3);
	width: 100%;
	line-height: 28px;
}

.calculator-info main h2 {
	color: var(--text);
	font-size: 1.55rem;
	font-weight: 600;
	width: 90%;
}
.calculator-info main h2.has-subtext {
	width: fit-content;
}
.calculator-info main h4 {
	font-size: 1.45rem;
	color: var(--text-light);
	font-weight: 400;
	margin-left: 5px;
}

.calculator-info-minimal main h4 {
	color: var(--text-light-3);
	font-size: 1.6rem;
	font-weight: 300;
}

.onboarding-step-main-hero a {
	color: var(--theme) !important;
}

.onboarding-step-logout {
	margin-right: 20px;
	flex-shrink: 0;
	color: var(--theme-4);
}

.calculator-sub-text-inline {
	font-size: 1.45rem;
	color: var(--text-light);
	font-weight: 400;
}

.onboarding-step .modal-inline {
	max-width: initial !important;
	margin-top: 20px;
	width: 100%;
}

.onboarding-step .modal-select-container {
	width: 100% !important;
}

.onboarding-step-footer-extra {
	text-align: left;
	font-size: 1.35rem;
	color: var(--text-light-4);
	text-align: right;
	margin-left: 12px;
}
