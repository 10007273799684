.utm-param-group {
	width: 100%;
	background-color: var(--bg-dark);
	border-radius: 12px;
}

.utm-param-group-params-container {
	flex-wrap: wrap;
}

.utm-param-group-controls {
	gap: var(--disco-spacing-rg);
}

.utm-param-group-select {
	width: 248px;
}

.utm-param-group-select .disco-select-option {
	height: var(--disco-spacing-lg);
}

.utm-param-group-select .disco-select-option-dummy {
	height: var(--disco-spacing-md);
}

.utm-param-group-input {
	flex-grow: 1;
}

.utm-param-group-input .disco-text-inp {
	min-width: 0px;
}

.utm-param-group-btn {
	white-space: nowrap;
}

.utm-param-group > h3 {
	color: var(--text-light);
	font-size: 1.1rem;
}
