.alert-bar-payment-module-orders {
	display: flex;
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 40px;
	height: 26px;
	padding: 3px 10px 4px 5px;
	align-items: center;
	color: #fff;
}

.alert-bar-payment-module-orders .completion-bar {
	margin: 2px 10px 0px 3px;
	--completion-bar-progress-bg: var(--alert-bar-signature-grad);
	height: 5px;
}
