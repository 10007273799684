.emails-preview {
	position: relative;
	width: 100%;
	height: 100%;
	background-color: var(--bg);
	border-radius: 10px;
}
.emails-preview-background {
	position: absolute;
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 12px;
}
.emails-preview-main {
	display: flex;
	flex-direction: column;
	/* justify-content: center; */
	align-items: center;
	padding-bottom: 20px;
}
.emails-preview-main .tab-switcher-container {
	margin: 20px auto 0 auto;
}

.emails-preview-main .tab-switcher-tab {
	padding: 8px 16px;
}
.emails-preview-main .tab-switcher-background {
	border: none;
	color: var(--theme-dark);
}

.emails-preview-main .tab-switcher-tab-active {
	color: var(--text-light);
}

.emails-preview-video {
	width: 90%;
	margin-bottom: 20px;
	background-color: var(--text);
	border-radius: 10px;
}

.emails-preview-main .large-list,
.email-preview-image {
	width: 90%;
}

.email-preview-image {
	border-radius: 10px;
	border: 1px solid var(--text-light-5);
}

.emails-preview-main .large-list-item a {
	color: var(--theme);
}
