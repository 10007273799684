.facebook-ad-account-selector {
	width: 100%;
}
.facebook-ad-account-selector .disco-select {
	width: 100%;
	margin-bottom: var(--disco-spacing-sm);
}
.facebook-ad-account-selector .facebook-disconnect-btn {
	width: 100%;
}
.facebook-ad-account-selector > .disco-status-label {
	margin-top: var(--disco-spacing-sm);
}
.facebook-ad-account-configurator {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.facebook-ad-account-configurator .disco-mini-badge {
	margin-right: 0px;
}
.facebook-ad-account-configurator-modal {
	min-height: 300px;
}
.facebook-ad-account-configurator-modal .modal-content {
	padding: var(--disco-spacing-md);
	padding-top: var(--disco-spacing-sm);
}
.facebook-ad-account-configurator-modal .disco-select {
	width: 100%;
	margin-top: var(--disco-spacing-tn);
	margin-bottom: var(--disco-spacing-md);
}

.facebook-ad-account-configurator-modal
	.modal-content
	> .disco-status-label:not(.disco-status-label-success) {
	margin-top: 0px;
}
.facebook-ad-account-configurator-modal-action {
	border-bottom: 2px solid var(--disco-bg-mid);
}
.facebook-ad-account-configurator-modal-action-body {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
}
.facebook-ad-account-configurator-modal-action-description {
	width: 80%;
}
.facebook-audience-requestor-warning .slider {
	filter: grayscale(1);
}
.facebook-audience-requestor > .disco-loader {
	margin: var(--disco-spacing-sm) auto;
}
.facebook-audience-requestor-slider-status {
	display: flex;
	align-items: center;
}
.facebook-audience-requestor-slider-status .disco-status-label {
	margin-top: 0px;
	--form-status-color: var(--disco-bg-light);
	border: 1px solid var(--disco-blue-default);
	border-radius: var(--disco-curve-md);
}
.facebook-audience-requestor-slider-status-warning .disco-status-label {
	border: 2px solid var(--disco-yellow-mid);
	color: var(--disco-text-dark);
}
.facebook-audience-status-cards {
	max-height: 90vh;
	overflow: auto;
}
.facebook-audience-status-card {
	background: var(--tooltip-gradient);
	border-radius: var(--disco-curve-md);
}
.facebook-audience-status-card-header {
	color: rgba(250, 250, 250, 0.9);
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.facebook-audience-status-card-header aside {
	display: flex;
}
.facebook-audience-status-card-body {
	background: var(--disco-bg-default);
	border-radius: var(--disco-curve-md);
	max-height: 170px;
	overflow: auto;
}
.facebook-audience-campaign {
	display: flex;
	background-color: var(--disco-bg-mid);
	border-radius: var(--disco-curve-md);
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.facebook-audience-campaign h3 {
	word-wrap: break-word;
	width: calc(100% - 130px);
}
