.products-container-set-view {
	background-color: var(--disco-bg-light) !important;
	width: 97%;
	padding: 23px;
	margin: auto;
	border-radius: 10px;
	margin-top: 12px;
	height: 120px;
}
.products-container-config-container {
	width: 97%;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
}
.products-container-config-item {
	width: 49%;
}

.products-container-edit-mode-toggle {
	margin: 25px auto 10px auto;
	width: 96%;
}

.products-container-add {
	display: flex;
	width: 96%;
	justify-content: flex-start;
}

.products-container-save-order {
	position: relative;
	height: 40px;
	left: 20px;
}

.products-table-container {
	max-height: 560px;
	display: flex;
	justify-content: center;
	align-items: center;
	overflow: auto;
	width: 100%;
}

.products-table-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0px;
	top: 0px;
	background-color: rgba(0, 0, 0, 0.2);
	backdrop-filter: blur(10px);
	display: flex;
	z-index: 99;
	align-items: flex-start;
	justify-content: center;
}
.products-table-overlay-content {
	background-color: var(--disco-bg-default);
	border-radius: var(--disco-curve-md);
	width: 480px;
	text-align: center;
}
.products-table-overlay-line {
	width: 40px;
	height: 5px;
	border-radius: var(--disco-curve-tn);
	background-color: var(--disco-blue-mid);
}
.products-table {
	user-select: none;
}

.products-table-number span {
	background-color: #f3f7ff;
	border-radius: var(--disco-curve-tn);
}

.products-table th .question-tooltip {
	color: var(--text) !important;
	right: -150px;
}
.products-table th .disco-tooltip-activator {
	display: inline-block;
	position: relative;
	top: 4px;
	margin-left: 5px;
}
.products-table th .disco-tooltip-activator svg {
	width: 16px;
	height: 16px;
}
.products-table th .disco-base-tooltip {
	text-transform: none;
	text-align: left;
	font-size: var(--disco-fs-label);
}
.products-table-crosssell {
	min-width: 125px !important;
	position: relative;
}
.products-table-upsell {
	min-width: 100px !important;
	position: relative;
}

.products-table-upsell-email {
	min-width: 140px !important;
	position: relative;
	padding-bottom: 5px !important;
}

.product-table-row-cont {
	width: 100%;
}

.products-container-review {
	color: var(--text);
	font-weight: 400;
	font-size: 1rem;
	text-align: center;
}

.products-container-review b {
	display: inline-block;
	color: var(--blue);
	font-weight: 600;
	margin-bottom: 3px;
}

.products-container-inputs {
	width: 100%;
	display: flex;
	justify-content: center;
}
.products-container-input {
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: var(--disco-bg-light);
	border-radius: var(--disco-curve-md);
	box-shadow: 0px 20px 17px 6px rgba(0, 0, 0, 0.05);
	width: 300px;
}

.products-container-input img {
	width: 80px;
}

.products-container-input p {
	line-height: var(--disco-fh-lg-body);
	text-align: center;
}

.products-container-sync {
	display: flex;
	border-radius: var(--disco-curve-md);
	background-color: var(--theme-light);
	align-items: center;
	justify-content: center;
	width: 96%;
}

.products-container-sync h3 {
	flex: 1;
	width: 100%;
	color: var(--disco-blue-mid);
}

.products-container-bulk-btns {
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.products-container-bulk-btns .disco-btn {
	width: 100%;
	height: 45px;
}
