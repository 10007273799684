.variants-match-variants {
	width: 100%;
}

.variants-match-modal-intro-head {
	font-size: 1.7rem;
	color: var(--text-light);
	letter-spacing: -1px;
	margin-top: 10px;
}

.variants-match-modal-intro-text {
	font-size: 1.4rem;
	margin-top: 10px;
	color: var(--text-light);
}

.variants-match-modal .lib-table {
	/* background-color: transparent; */
	margin-top: 20px;
	overflow: hidden;
}

.variants-match-modal .lib-table .product-badge {
	box-shadow: none;
	background-color: transparent !important;
	cursor: initial;
}

.variants-match-modal .lib-table th {
	font-weight: 600;
}
