.list-view-entry-action {
	width: 100%;
	margin-top: 14px;
	display: flex;
	justify-content: center;
}
.list-view-entry-action .btn {
	flex: 1 1 auto;
	margin: 0 6px;
}
.list-view-entry-action-secondary {
	margin-top: -6px;
}
.list-view-entry-action-secondary .btn {
	margin: 0px 2px;
}
.list-view-entry-delete,
.list-view-entry-delete-secondary {
	border-color: var(--red) !important;
	color: var(--red) !important;
}
.list-view-entry-delete-secondary {
	height: 43px;
	border-radius: 10px !important;
}
.list-view-entry-delete:hover,
.list-view-entry-delete-secondary:hover {
	background-color: var(--red) !important;
	color: var(--bg-light) !important;
}

.list-view-entry-action-custom {
	display: flex;
	margin: 14px auto;
	margin-top: 20px;
}

.list-view-entry-action-custom .btn {
	width: 49%;
	margin: 0px auto;
	height: 45px;
}
