.audiences-banner {
	background-image: url("../../img/audiences/banner.jpg");
	background-size: cover;
	background-color: var(--disco-text-default);
	color: var(--disco-bg-light);
	overflow: hidden;
	border-radius: var(--disco-curve-lg);
}

.audiences-banner-text {
	width: 40%;
}

.audiences-banner-btn {
	justify-content: center;
	width: 150px;
	height: 32px;
}

.audiences-banner > footer {
	background-image: var(--dark-bg-grad);
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	height: 140px;
}

.audiences-banner-footer-feature {
	display: flex;
	align-items: center;
}

.audiences-banner-footer-feature-sep {
	flex: 0 0 auto;
	height: 100%;
}

.audiences-banner-footer-feature img {
	width: 60px;
	object-fit: cover;
}

.audiences-banner-footer-feature p {
	opacity: 0.9;
}
