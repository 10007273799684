.team-module-invite {
	background-color: var(--theme-light);
	display: flex;
	padding: 18px;
	border-radius: 18px;
	margin-top: 20px;
	align-items: center;
	transition: background-color 0.2s;
}

.team-module-invite-success {
	background-color: var(--light-green);
}

.team-module-invite .inp-container {
	width: 100%;
	min-width: 0 !important;
}

.team-module-invite .inp-container .inp {
	width: 100%;
	min-width: 0;
}

.team-module-invite-action {
	flex: 0 0 140px;
	margin-left: 7px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.team-module-invite .filled-btn-text {
	width: 100%;
}
.team-module-invite .filled-btn {
	width: 120px;
	transition: background-color 0.2s;
}

.team-module-dropdown .disco-select-options {
	width: 200px;
	left: -100px;
}
