.emails-module-content {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	height: 100%;
}

.emails-module-header {
	width: 96%;
	margin: -20px auto 30px auto;
}

.emails-module-header .tabs {
	top: 0;
}
.emails-module-header .tab {
	text-transform: none !important;
}
