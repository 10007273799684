.modal-select-loader {
	margin: auto;
}
.modal-select-h-line {
	width: 95%;
	background-color: var(--text-light-4);
	opacity: 0.3;
	margin: 10px auto;
	height: 2px;
}
.modal-select-container {
	/* width: 550px; */
	height: 550px;
	background-color: var(--bg-light);
	box-shadow: 0px 5px 30px rgba(3, 25, 38, 0.15);
	border-radius: 12px;
	display: flex;
	position: relative;
	flex-direction: column;
}
.modal-select-header {
	box-shadow: 0px 5px 30px rgba(3, 25, 38, 0.15);
	padding: 20px;
	padding-left: 30px;
	width: 100%;
	border-radius: 12px 12px 0px 0px;
	background-color: var(--bg-light);
	z-index: 1;
}
.modal-select-header-basic {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.modal-select-closer {
	background-color: var(--text-light-4);
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	width: 20px;
	height: 20px;
	border-radius: 100px;
	color: #fff;
}
.modal-select-title {
	font-weight: 700;
	font-size: 2rem !important;
}
.modal-select-utils {
	display: flex;
	margin-top: 10px;
}
.modal-select-utils .inp-container {
	margin-right: 15px;
}
.modal-select-utils .select-container {
	width: 280px;
}
.modal-select-utils .select-container .select {
	padding: 9px 4px;
}
.modal-select-utils .select-container .select .select-selected {
	font-weight: 400;
	display: flex;
	align-items: center;
}
.modal-select-utils .select-container .select .select-selected svg {
	font-size: 1.6rem;
}
.modal-select-utils .select-container .select .select-selected span {
	font-weight: 600;
	margin: 0px 2px;
	margin-right: 5px;
}
.modal-select-content {
	display: flex;
	height: 100%;
	overflow: auto;
	flex-grow: 1;
	background-color: var(--bg-dark-2);
}
.modal-select-content .mini-badge > b {
	font-weight: 500;
	margin-right: 3px;
}
.modal-select-aside {
	background-color: var(--bg-dark-2);
	overflow: auto;
	min-width: 35%;
	padding: 20px;
	padding-top: 0px;
	height: inherit;
	border-bottom-left-radius: 12px;
}
.modal-select-main {
	padding: 20px;
	height: 100%;
	overflow: auto;
	position: relative;
	background-color: var(--bg-light);
	width: 100%;
	border-bottom-right-radius: 12px;
}
.modal-select-aside-title {
	color: var(--text-light-3);
	font-size: 1.06rem;
	/* position: sticky; */
	top: 0px;
	background-color: rgba(229, 229, 229, 0.598);
	padding: 20px 0px 10px 0px;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
}
.modal-select-aside-empty {
	color: var(--text-light-3);
	font-size: 1.06rem;
	text-align: center;
	font-weight: 500;
	margin-top: 20px;
}
.modal-select-main-title {
	font-weight: 600;
	font-size: 1.06rem;
}
.modal-select-main-content {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
	max-height: 190px;
	overflow: auto;
	justify-content: flex-start;
}
.modal-select-main-content-empty {
	padding: 5px 0px;
	font-weight: 600;
	color: var(--text-light);
}
.modal-select-main-footer {
	color: var(--text-light-3);
	line-height: 16px;
	font-weight: 300;
}
.modal-select-main-btn-container {
	display: flex;
	justify-content: flex-start;
	position: absolute;
	bottom: 20px;
	width: 90%;
	left: 5%;
	background-color: rgba(250, 250, 250, 0.56);
}
.modal-select-main-btn-loader {
	margin-bottom: 0px;
}
.modal-select-main-btn-next {
	margin-left: auto;
}
.modal-select-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(240, 240, 240, 0.2);
	z-index: 2;
	border-radius: 12px;
	backdrop-filter: blur(5px);
	-webkit-backdrop-filter: blur(5px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	text-align: center;
}

.modal-select-overlay-body {
	margin-top: 20px;
}
.modal-select-overlay-btn-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-top: 25px;
	width: 300px;
}
.finalize-modal-text {
	color: var(--text-light-3);
	margin-bottom: 20px;
}
.finalize-modal-rows {
	max-height: 240px;
	overflow: auto;
}
.finalize-modal-row {
	padding: 3px;
}
.finalize-modal-row-content {
	display: flex;
	flex-wrap: wrap;
	margin-top: 4px;
}
.finalize-modal-row-empty {
	margin-top: 4px;
	color: var(--text-light-3);
}
