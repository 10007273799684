.audiences-sizes {
	display: flex;
	justify-content: flex-start;
	align-items: stretch;
	flex-wrap: wrap;
}
.audiences-size {
	cursor: pointer;
	display: flex;
	justify-content: stretch;
	align-items: center;
	border: 1.5px solid var(--text-light-5);
	border-radius: var(--disco-curve-md);
	box-shadow: 0px 0px 8xp 1px rgba(0, 0, 0, 0.07);
	width: 300px;
	transition: border-color 0.2s;
	min-width: 300px;
}

.audiences-size-selected {
	border-color: var(--theme);
}
