#picker-root {
	z-index: 26;
	position: absolute;
	top: 0;
	left: 0;
}
.icon-picker {
	position: absolute;
	background-color: var(--bg-light);
	border: 2px solid var(--theme);
	border-radius: 10px;
	padding: 14px 18px;
	width: 205px;
	z-index: 12;
}

.icon-picker header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.icon-picker header h2 {
	font-size: 1rem;
	font-weight: 600;
	color: var(--text-light);
}

.icon-picker-close {
	font-size: 1.3rem;
	cursor: pointer;
}

.icon-picker-container {
	display: flex;
	flex-wrap: wrap;
	margin-top: 5px;
}

.icon-picker-icon {
	height: 35px;
	height: 35px;
	object-fit: contain;
	margin: 3px;
	padding: 5px;
	border-radius: 6px;
	transition: background-color 0.2s;
	cursor: pointer;
}

.icon-picker-icon-active {
	border: 2.5px solid var(--theme);
}

.icon-picker-icon:hover {
	background-color: var(--bg-dark);
}

.icon-picker-container .btn {
	width: 100%;
	margin-top: 8px;
}
