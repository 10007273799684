.login-page-shop {
	width: 100%;
	padding: 16px;
	border-radius: 6px;
	margin: 10px 0;
	background-color: var(--bg-dark);
	font-weight: 600;
	font-size: 1.1rem;
	color: var(--text-light);
	cursor: pointer;
}

.login-page-shop-loader {
	display: block;
	margin: 20px auto 10px auto;
}
