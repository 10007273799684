.platforms-step-platforms {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 100%;
}

.platforms-step-platform {
	background-color: var(--bg-light);
	border-radius: 7px;
	width: calc(50% - 8px) !important;
	flex: 0 0 auto;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 15px;
	border: 2px solid var(--text-light-5);
	height: 90px;
	margin: 8px 0;
	align-items: center;
	cursor: pointer;
	transition: border-color 0.2s;
}

.platforms-step-platform-selected {
	border-color: var(--theme);
}

.platforms-step-platform-shopify {
	width: 100% !important;
}

.platforms-step-platform-shopify .disco-inp-container {
	width: 100%;
	max-width: unset;
	margin-left: 20px;
}

.platforms-step-platform-shopify img {
	width: 22% !important;
}

.platforms-step-platform img {
	width: 52%;
	margin-left: 30px;
}

.platforms-step .form-status {
	width: 100%;
	margin-top: 15px;
}

.platforms-step-platform-done {
	font-size: 2.5rem;
	color: var(--green-2);
	margin-top: 3px;
}
