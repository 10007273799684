.product-modal {
	max-width: 650px;
	width: 90%;
}

.product-modal .disco-base-form {
	width: 100% !important;
	margin-top: calc(0px - var(--disco-spacing-rg));
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.product-modal .disco-label {
	width: 100%;
	margin-top: var(--disco-spacing-rg);
}

.product-modal .disco-base-form .disco-labeled-inp {
	width: 100% !important;
}

.product-modal .disco-image-input {
	margin-top: var(--disco-spacing-sm);
	width: 100% !important;
}
