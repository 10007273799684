.payment-plan-card {
	background-color: var(--bg-light);
	border-radius: 10px;
	border: 0.5px solid rgba(0, 0, 0, 0.12);
	/* min-height: 260px; */
	width: 300px;
	padding: 30px;
	text-align: center;
	position: relative;
	transition: box-shadow 0.12s ease-out;
	box-shadow: 0px 0px 16px rgba(3, 25, 38, 0.05),
		0px 20px 24px rgba(3, 25, 38, 0.15);
}
.payment-plan-card-addon {
	margin-top: 15px;
	width: 100%;
	padding: 15px 20px;
	text-align: left;
	border: 1px solid var(--text);
	box-shadow: 0px 0px 30px 10px rgba(0, 0, 0, 0.08);
}
.payment-plan-card-addon-row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.payment-plan-card-addon-row h2 {
	margin-right: 10px;
	font-size: 1.4rem !important;
	margin-top: -3px;
	font-weight: 600;
}
.payment-plan-card-no-shopify {
	min-height: 200px;
}
/* .payment-plan-card:hover {
} */
.payment-plan-card h2 {
	font-weight: 600;
	font-size: 1.8rem;
}
.payment-plan-card-features {
	/* margin: 20px 0px 20px 0px; */
	margin: 20px 0px 10px 0px;
}

.payment-plan-card-feature {
	margin-bottom: 10px;
}
.payment-plan-card-feature-striked {
	color: var(--text-light-3);
	text-decoration: line-through;
}
.payment-plan-card-price {
	display: flex;
	align-items: center;
	/* margin-top: 20px; */
	justify-content: space-evenly;
}
.payment-plan-card-price-subtext {
	font-size: 2.6rem;
	font-weight: 300;
	color: var(--text-light-3);
}
.payment-plan-price {
	font-weight: 700;
	font-size: 3rem;
}
.payment-plan-price-small {
	font-size: 2rem;
	font-weight: 500;
}
.payment-plan-price-light {
	color: var(--text-light-3);
}
.payment-plan-card-price-subtext-theme {
	color: var(--theme);
}
.payment-plan-price sub {
	font-weight: 400;
	font-size: 1rem;
	margin-left: -8px;
	position: relative;
	top: -6px;
}
.payment-plan-price-small sub {
	margin-left: -5px;
}
.payment-plan-card-children {
	display: flex;
	justify-content: center;
	/* margin-top: 40px; */
	margin-top: 16px;
}
/* .free-payment-plan-card {
	border: 2.4px solid var(--text-light-4);
} */
/* .basic-payment-plan-card {
	border: 2.4px solid rgba(0, 0, 0, 1);
}
.pro-payment-plan-card {
	border: 2.4px solid #000;
} */
/* .pro-payment-plan-card .filled-btn {
	background: #000;
} */
.pro-payment-plan-card-callout {
	color: var(--text-light-3);
}
