.emails-page .performance-analysis-module {
	height: 90%;
	width: 96% !important;
	margin: 0 auto;
}

.emails-page .performance-analysis-module iframe {
	position: relative;
	height: 100%;
}
.emails-advanced-settings-tab {
	margin-right: 2px !important;
}
