.mobile-onboarding-view .central-view-hero {
	text-align: center;
	width: 100%;
	overflow: hidden !important;
}

.mobile-onboarding-view-header-img {
	height: 164px;
	margin-top: 13px;
}

.mobile-onboarding-view .central-view-hero > footer > .btn {
	flex: 1;
}

.mobile-onboarding-view .bordered-card-heading {
	width: 80%;
}
.mobile-onboarding-view-footer-column {
	display: flex;
	flex-direction: column;
}
