.login-view .central-view-hero {
	width: 360px;
	height: 440px;
}

.login-view .central-view-background {
	height: 95%;
}

.login-view .bordered-card {
	overflow: hidden !important;
}

.login-view .base-form {
	width: 100% !important;
}

.login-view .labeled-inp {
	width: 100%;
	margin-top: 10px;
}

.login-view .inp {
	border-color: var(--text-light-5);
}

.login-view .labeled-inp .label {
	text-transform: capitalize;
	font-weight: 400;
}

.login-view .base-form-head {
	font-weight: 300 !important;
	color: var(--text-light-3) !important;
	font-size: 2rem !important;
}

.login-view .loader {
	margin-top: 15px;
}

.login-view-link {
	font-weight: 400;
	font-size: 1.28rem;
	color: var(--theme);
	margin-top: 20px;
}

.login-view-link-register {
	color: var(--text-light-2);
}

.login-view-link-register a {
	color: var(--theme);
}

@media screen and (max-width: 600px) {
	.login-view .central-view-hero {
		width: 100%;
		height: unset;
		min-height: 0;
	}

	.login-view-link {
		margin-bottom: 28px;
	}

	.login-view-login-button {
		width: 100%;
	}

	.login-view .tab-switcher-background {
		width: 100%;
	}

	.login-view .tab-switcher-tab {
		width: 50%;
		justify-content: center;
	}
}
