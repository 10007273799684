.processing-page .page-content {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.processing-page .page-content h2 {
	margin-top: 30px;
}
