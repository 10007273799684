.emails-modal-select-input {
	margin-bottom: 16px;
}
.emails-products-preview-container {
	height: 100%;
}
.emails-products-preview-container > h1 {
	padding: 0px 20px 10px 20px;
	font-size: 2rem;
}
.emails-products-preview {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	overflow: auto;
	flex-wrap: wrap;
	max-height: 600px;
	height: 100%;
}
.emails-products-preview .loader {
	margin: auto;
}
.emails-products-preview .generic-product-card {
	margin-bottom: 20px;
}
.emails-products-preview .inline-helper-tooltip {
	margin-top: 0px;
}
