@keyframes loader-spin {
	0% {
		transform: rotate(0deg);
	}
	50% {
		transform: rotate(180deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
@keyframes drop-down-pulse {
	0% {
		background-color: rgba(170, 205, 255, 0);
		transform: scale(1);
	}
	50% {
		background-color: rgba(170, 205, 255, 0.4);
		transform: scale(1.01);
	}
	100% {
		background-color: rgba(170, 205, 255, 0);
		transform: scale(1);
	}
}

* {
	padding: 0;
	margin: 0;
	font-family: var(--font);
	box-sizing: border-box;
}

:root {
	font-size: 9pt;
	line-height: unset;
	--red: #ff4f67;
	--red-dark: #f62e2e;
	--light-red: #ffe9e9;
	--light-red-2: #ffb8b8;

	--blue-green-gradient: linear-gradient(90deg, #35f4f2, #5792ff);
	--btn-gradient: linear-gradient(180deg, #5792ff 0%, #185ad5 181.25%);
	--btn-disco-gradient: linear-gradient(
		89.99deg,
		#5792ff -1.69%,
		#7d38ff 98.99%
	);

	--gray-gradient: linear-gradient(180deg, #ababab 0%, #7a7a7a 100%);

	--theme: #5793ff;
	--theme-2: #275aff;
	--theme-3: #3a73ff;
	--theme-4: #5792ff;

	--theme-dark: #0b69ff;
	--theme-dark-2: #2e7cf6;
	--theme-dark-3: #2e3b62;
	--theme-bright: #50daff;
	--theme-light: #0b69ff11;
	--theme-light-2: #0b69ff28;
	--theme-light-3: rgba(87, 147, 255, 0.5);
	--theme-light-4: rgba(87, 147, 255, 0.7);

	--dark-bg-grad: linear-gradient(
		90.13deg,
		#19202e 7.02%,
		rgba(25, 32, 46, 0.58) 106.56%
	);

	--theme-snow: #dae3f1;

	--bright-pink: #ff00ff;

	--card-red-gradient: linear-gradient(
			170.27deg,
			rgba(255, 255, 255, 0) 4.29%,
			rgba(255, 255, 255, 0.7) 92.68%
		),
		linear-gradient(251.43deg, #fff2d0 -2.99%, #ffcce7 109.58%);

	--card-theme-gradient: linear-gradient(
			153.62deg,
			rgba(255, 255, 255, 0) 9.59%,
			rgba(255, 255, 255, 0.7) 83.42%
		),
		linear-gradient(251.29deg, #f8e9ff -2.99%, #7dcade 221.39%);

	--tooltip-gradient: linear-gradient(
		140deg,
		var(--theme-2) 0.3%,
		var(--bright-pink) 130%
	);

	--flows-gradient: radial-gradient(
			circle farthest-side at 0% 0%,
			rgba(117, 166, 255, 0.76),
			rgba(52, 246, 242, 0.34) 50%,
			hsla(0, 0%, 100%, 0)
		),
		-webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, 0.11)), to(hsla(0, 0%, 100%, 0.11))),
		radial-gradient(
			circle farthest-corner at 50% 70%,
			hsla(0, 0%, 100%, 0.69),
			hsla(0, 0%, 100%, 0)
		),
		radial-gradient(
			circle farthest-side at 0% 25%,
			rgba(255, 113, 241, 0.32),
			hsla(0, 0%, 100%, 0.06)
		),
		radial-gradient(
			circle farthest-side at 100% 0%,
			rgba(255, 189, 246, 0.49),
			rgba(255, 236, 170, 0.87) 52%,
			#fff
		);
	/* #33b6d6 */

	--bubblegum: linear-gradient(-45deg, #fce0ff, #b5edff);

	--blue: #527aff;
	--green: #1bd6ae;
	--green-2: #26ab61;
	--green-3: #67be24;
	--light-green: #d7eee3;
	--light-green-2: #b3e3c7;
	--light-green-3: rgba(0, 181, 177, 0.12);

	--dark-green: #09bb94;
	--dark-green-2: #2dca73;
	--shopify-green: #95bf47;
	--yellow: #ffd95c;
	--mustard: #eaa000;
	--yellow-2: #fff9ee;
	--dark-yellow: #ffbd2b;
	--dark-yellow-2: #e16b02;

	--yellow-light: rgba(255, 196, 87, 0.1);

	--pink: #bb4bff;
	--pink-2: #f859ff;
	--pink-3: #a973ff;
	--light-yellow: #faeec6;
	--light-grey: #e6e6e6;

	--purple-gradient: linear-gradient(90deg, var(--pink), var(--pink-2));

	--text-blue: #171f46;

	--slack-bg: #fff;
	--slack-text: #ecb22e;
	--facebook-theme: #1877f2;

	/*
	radial-gradient(561.92% 181.12% at 0% 0%, #5792FF 0%, rgba(87, 146, 255, 0) 100%), radial-gradient(349.84% 566.61% at 101.04% 0%, #5792FF 0%, #5792FF 0%, rgba(52, 246, 242, 0.81) 45.97%, rgba(255, 255, 255, 0) 100%), radial-gradient(253.26% 95% at -2.08% 100%, rgba(255, 145, 240, 0.56) 0%, rgba(255, 225, 119, 0.6) 46.87%, rgba(255, 255, 255, 0) 100%);
	*/
	--theme-gradient: radial-gradient(
			603.91% 100% at 0% 0%,
			#5792ff 0%,
			rgba(87, 146, 255, 0) 100%
		),
		radial-gradient(
			412.08% 100% at 50% 0%,
			#5792ff 0%,
			#5792ff 28.96%,
			rgba(52, 246, 242, 0.81) 49.38%,
			rgba(255, 255, 255, 0) 100%
		),
		radial-gradient(
			253.26% 95% at -2.08% 100%,
			rgba(255, 145, 240, 0.7) 0%,
			rgba(255, 225, 119, 0.7) 46.87%,
			rgba(255, 255, 255, 0) 100%
		);

	--theme-gradient-2: radial-gradient(
			603.91% 100% at 0% 0%,
			#654fff 0%,
			#5792ff 16.67%,
			rgba(87, 146, 255, 0) 79.69%
		),
		radial-gradient(
			412.08% 100% at 50% 0%,
			#7d38ff 0%,
			#5792ff 28.96%,
			rgba(52, 246, 242, 0.81) 49.38%,
			rgba(255, 255, 255, 0) 100%
		),
		radial-gradient(
			253.26% 95% at -2.08% 100%,
			rgba(255, 145, 240, 0.56) 0%,
			rgba(255, 225, 119, 0.6) 46.87%,
			rgba(255, 255, 255, 0) 100%
		);

	--disco-gradient: linear-gradient(
		254.32deg,
		#25ffff -12.21%,
		#5792ff 18.66%,
		#7d38ff 59.57%,
		#100721 135.99%
	);

	--onboarding-gradient: linear-gradient(
		252.85deg,
		#004aba -3.92%,
		#0062f4 8.28%,
		#5792ff 37.82%,
		#7d38ff 92.16%,
		#3800a3 119.38%
	);

	--theme-gradient-progress: linear-gradient(
		to right,
		var(--theme-bright),
		var(--theme)
	);

	--theme-gradient-dark: linear-gradient(
		60deg,
		rgb(243, 189, 241) 5%,
		rgb(245, 205, 143) 10%,
		rgb(87, 147, 255) 15%
	);

	--filled-btn-grad: linear-gradient(180deg, #5792ff 0%, #185ad5 181.25%);
	/* --theme-gradient-dark: linear-gradient(50deg, #000000, #111, var(--theme)); */

	--bg: #f8f8f8;
	--bg-light: #fff;
	--bg-light-2: #fcfcfc;
	--bg-dark: #eee;
	--bg-dark-2: #e5e5e5;

	--dark-bg: #111;

	--text: #010204;
	--letter-space: -0.24px;
	--text-light: #414143;
	--text-light-2: #576171;
	--text-light-3: #7e858e;
	--text-light-4: #aaaaaa;
	--text-light-5: #d7dee9;

	--side-bar-width: 260px;
	--page-content-width: calc(100vw - var(--side-bar-width) - 20px);

	--easing: cubic-bezier(0.68, 0.28, 0.82, 0.64);
	--easing-slick: cubic-bezier(0.02, 0.98, 0.65, 1.15);
	--font: Inter;

	/*	Subscription module */
	--subscription-table-data-width: 822px;
	--subscription-trial-bill-status-color: #ffe3f5;
	--subscription-upcoming-bill-status-color: #d4ecff;
	--subscription-paid-bill-status-color: #d4ffdb;

	--universal-preview-phone-width: 320px;
	--universal-preview-widget-curve: 20px;
	--universal-preview-widget-spacing: 26px;
}

a {
	text-decoration: none;
	color: inherit;
}

body {
	height: 100vh !important;
	width: 100vw !important;
	background: var(--disco-gradient) !important;
	background-attachment: fixed !important;
}

.loader {
	position: relative;
	display: block;
	height: 40px;
	width: 40px;
}

.loader-small {
	transform: scale(0.75);
}

.loader-small-2 {
	width: 28px;
	height: 28px;
}

.loader-center {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}
.loader-middle {
	margin-left: auto;
	margin-right: auto;
}

.loader-light .loader-circle {
	border-color: var(--bg-light);
	border-top-color: transparent;
}
.loader-dark .loader-circle {
	border-color: var(--text);
	border-top-color: transparent;
}

.loader-circle {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	height: 80%;
	width: 80%;
	border: 2px solid var(--theme);
	border-radius: 100%;
	border-top-color: transparent;
	animation: loader-spin 1s infinite var(--easing);
}

.core-info-area {
	width: 100%;
	padding: 20px;
	background-color: rgba(31, 109, 255, 0.751);
	color: #fff;
	text-align: center;
	border-radius: 10px;
	font-size: 1.1rem;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.core-info-area > span {
	font-size: 3rem;
}
.core-info-area-children {
	margin-top: 6px;
}

.page {
	--bod-banner-height: 0px;
	--alert-bar-height: 0px;
	--alert-bar-spacing: 8px;
	display: flex;
	min-height: 100vh;
	width: 100vw;
}
.page-show-alert {
	--alert-bar-height: 40px;
}
.page-show-bod-banner {
	--bod-banner-height: 54px;
}

/* PAGE HEADER */
.page-header {
	margin-top: -15px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: var(--disco-stroke-lg) solid var(--disco-text-stroke);
	height: fit-content;
}
.page-header-half {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.page-header-half .tabs {
	margin-left: var(--disco-spacing-lg);
	height: calc(100% + var(--disco-stroke-lg));
	position: relative;
	top: var(--disco-stroke-lg);
}
/* ---------------------------------- */

.page-header-syncing {
	display: flex;
	padding: 0 20px 0 12px;
	border-radius: 8px;
	background-color: var(--theme-light);
	color: var(--theme);
	align-items: center;
	justify-content: center;
	height: 38px;
	font-size: 1.1rem;
	position: relative;
	margin-bottom: 15px;
	margin-right: 10px;
}

.page-onboarding .page-header-syncing {
	margin-bottom: 4px;
}

.page-header-syncing .loader {
	margin: 0;
}

.page-header-half > .btn {
	margin: 0 3px 15px 3px;
	padding-left: 10px;
	padding-right: 10px;
}
.page-header-minimal {
	background-color: transparent;
	border: none;
}

.page-warn {
	width: 100%;
	background-color: var(--bg-light);
	padding: 8px;
	text-align: center;
	margin-top: 15px;
	border: 2px solid var(--dark-yellow-2);
	color: var(--dark-yellow-2);
	border-radius: 10px;
	font-size: 1.3rem;
}

.page-warn span {
	display: inline-block;
	vertical-align: middle;
	font-size: 1.6rem;
	margin: 10px;
}

.page-wrap {
	height: 100vh;
	width: 100vw;
	position: absolute;
	z-index: 0;
	left: 0;
}

.banner-container {
	width: 100vw;
	position: fixed;
	z-index: 11;
	top: 0px;
	left: 0px;
}

.auth-page {
	position: relative;
	background-color: var(--theme-4);
}

.auth-page-cover {
	position: fixed;
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	background-color: #000;
}

.page-content {
	display: flex;
	width: var(--page-content-width);
	background-color: var(--disco-bg-default);
	margin: calc(
			var(--bod-banner-height) + var(--alert-bar-height) +
				var(--alert-bar-spacing)
		)
		0 0 0;
	border-radius: var(--disco-curve-lg) var(--disco-curve-lg) 0px 0px;
	position: relative;
	--padding-top: 50px;
	left: var(--side-bar-width);
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	box-shadow: var(--disco-shadow-lg);
	z-index: 10;
	min-height: calc(
		100vh - 8px - var(--bod-banner-height) - var(--alert-bar-height) -
			var(--alert-bar-spacing)
	);
}
.embedded-preview-page {
	width: 98%;
	margin: auto;
}
.embedded-preview-page-body {
	margin-top: 10px;
	display: flex;
	width: 100%;
}
.embedded-preview-page-child {
	flex-grow: 1;
	width: 100%;
}
.universal-preview-container {
	background: linear-gradient(180deg, #010204 0%, #4f535a 100%);
	box-shadow: inset 0.568182px 0px 0px rgba(0, 0, 0, 0.3),
		inset 2px 0px 0px rgba(0, 0, 0, 0.1),
		inset 8px 1px 16px rgba(0, 0, 0, 0.25);
	border-radius: 16px;
	display: flex;
	width: var(--universal-preview-phone-width);
	height: fit-content;
	flex-direction: column;
	align-items: center;
	position: sticky;
	top: calc(
		var(--bod-banner-height) + var(--alert-bar-height) +
			var(--alert-bar-spacing)
	);
	padding: 20px;
	margin-left: 16px;
}
.universal-preview-container .tab-switcher-container {
	margin: auto;
	width: fit-content;
	margin-top: 15px;
}
.universal-preview-container .tab-switcher-switch {
	top: 0px;
	height: calc(100% - 1px);
}

.universal-preview-header {
	color: #fff;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 10px;
}
.universal-preview-header h3 {
	font-size: 1.2rem;
	font-weight: 700;
	width: 200px;
}
.universal-preview-header-utils {
	font-size: 1.4rem;
	position: relative;
	top: 2px;
	display: flex;
	align-items: center;
}
.universal-preview-header-utils span {
	margin-left: 8px;
	opacity: 0.5;
	cursor: pointer;
}
.universal-preview-util-btn {
	background: linear-gradient(
		180deg,
		rgba(38, 43, 54, 0.64) 0%,
		#262b36 100%
	);
	padding: 10px 20px;
	border-radius: 100px;
	font-size: 1rem;
	cursor: pointer !important;
	color: #fff;
	font-weight: 500;
	transition: background 0.12s ease-out, color 0.12s ease-out;
	text-align: center;
}
.universal-preview-util-btn span {
	position: relative;
	margin-left: 5px;
	top: 2px;
}
.universal-preview-header-util-btn {
	margin-left: 16px;
}
.universal-preview-util-btn-active,
.universal-preview-util-btn-animated:hover {
	background: rgba(250, 250, 250, 0.9);
	color: var(--text);
}
.universal-preview-body {
	margin-top: 14px;
	/* background-color: blue; */
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.universal-preview-phone-container {
	min-width: 300px;
	width: 100%;
	overflow: hidden;
	max-width: var(--universal-preview-phone-width);
}
.universal-preview-phone {
	--universal-preview-phone-height-spacing: 0px;
	width: 100%;
	padding-top: calc(200% - var(--universal-preview-phone-height-spacing));
	top: calc(var(--universal-preview-phone-height-spacing) / 2);
	position: relative;
	display: flex;
	justify-content: center;
}
.universal-preview-phone-img {
	z-index: 2;
}
.universal-preview-widget-wrapper {
	position: absolute;
	top: 10px;
	height: calc(140% - 10px);
	width: calc(140% - 10px);
	left: 15px;
}
/* .universal-preview-widget-wrapper::before {
	content: "";
	width: 260px;
	left: 10px;
	height: 20px;
	background-color: white;
	display: block;
	position: absolute;
	top: -10px;
	z-index: 1;
	border-radius: 10px;
} */
.universal-preview-widget-wrapper .widget-disabled-status {
	width: calc(73% - var(--universal-preview-widget-spacing));
}
.universal-preview-widget {
	border-radius: var(--universal-preview-widget-curve);
	width: calc(100% - var(--universal-preview-widget-spacing));
	margin: auto;
	height: 100% !important;
	background-color: var(--bg-light);
	position: absolute;
	min-height: 0px !important;
	transform: scale(0.7);
	-moz-transform: scale(0.7);
	-webkit-transform: scale(0.7);
	-o-transform: scale(0.7);
	-ms-transform: scale(0.7);
	transform-origin: top left;
	-moz-transform-origin: top left;
	-webkit-transform-origin: top left;
	-o-transform-origin: top left;
	-ms-transform-origin: top left;
}
/* ------------------------------------------------------ */
.universal-preview-phone-container img {
	position: absolute;
	top: 0px;
	width: 100%;
	pointer-events: none;
	height: auto;
}
.universal-preview-phone > .universal-preview-content-img {
	width: calc(100% - 30px);
	height: calc(100% - 10px);
	padding-top: 30px;
	padding-bottom: 20px;
	margin-top: 10px;
	background-color: white;
	border-radius: 38px;
}
.universal-preview-dot-switcher {
	margin-top: 20px;
	padding-bottom: 2px;
}
.universal-preview-dot-switcher .universal-preview-util-btn {
	width: fit-content;
	display: flex;
	z-index: 10;
	position: relative;
	margin: auto;
	margin-top: 20px;
}
.universal-preview-switcher-heading {
	color: #fff;
	margin-bottom: 12px;
	font-weight: 500;
	display: flex;
	align-items: center;
	font-size: 1.15rem;
}
.universal-preview-switcher-heading .tab-switcher-container {
	margin-left: auto;
}
.universal-preview-switcher-heading > img,
.universal-preview-switcher-heading > span {
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	margin-right: 6px;
	width: 18px;
	height: 18px;
}
.progress-view {
	position: relative;
	width: 100%;
	height: 14px;
	border-radius: 7px;
	background-color: var(--bg-dark-2);
	overflow: hidden;
}

.progress-view-bar {
	background-color: var(--theme);
	position: absolute;
	top: 0;
	height: 100%;
	border-radius: 7px;
	transition: width 0.2s ease-out;
}

.header {
	display: flex;
	justify-content: space-between;
	position: absolute;
	flex-direction: row;
	height: 100px;
	width: calc(100% - 2 * var(--disco-spacing-lg));
	left: var(--disco-spacing-lg);
	align-items: center;
	top: 0;
	z-index: 100;
}

.header-item {
	position: relative;
	display: flex;
	align-items: center;
	border-radius: 40px;
	background-color: var(--disco-bg-light);
	box-shadow: var(--disco-shadow-sm);
	cursor: pointer;
	transition: box-shadow 0.2s;
}

.header-item-right {
	background-color: inherit;
	box-shadow: none;
}

.header-item:hover {
	box-shadow: 0px 8px 15px 8px rgba(0, 0, 0, 0.08);
}

.header-item-right .header-item-details {
	text-align: right;
}

.header-item-open {
	margin-right: 15px;
}

.header-item-right .header-item-open {
	margin: 0 10px 0 7px;
}

.header-item img {
	height: 46px;
	width: 46px;
	border-radius: 100%;
	object-fit: contain;
}

.header-refer {
	margin-right: 30px;
	position: relative;
}

.header-refer-tooltip {
	right: 0px !important;
	top: 45px;
}

.header-item .drop-down {
	top: 70px;
	left: 0;
	min-width: 150px;
}

.header-item-right .drop-down {
	left: initial;
	right: 0;
}

.header-brand-option-manage span {
	margin-right: 15px !important;
}

.header-brand-option-icon {
	height: 48px;
	width: 48px;
	position: relative;
	top: 2px;
}

.header-brand-option-manage-icon {
	font-size: 1.8rem;
}

.header-brand .drop-down-option {
	font-size: 1.15rem;
	font-weight: 600;
}

.drop-down {
	position: absolute;
	background-color: var(--bg-light);
	width: 100%;
	padding: 6px;
	border-radius: 10px;
	box-shadow: 0px 8px 15px 8px rgba(0, 0, 0, 0.08);
}

.drop-down .loader {
	margin: auto;
	margin: 5px auto;
}

.publish-btn {
	position: relative;
	z-index: 10;
}

.publish-btn-success {
	background-color: var(--dark-green-2) !important;
}

.publish-btn-success-icon {
	font-size: 1.8rem;
	margin-right: 3px;
	position: relative;
	top: 1px;
	display: inline-block;
	vertical-align: middle;
}

.publish-btn-success-down {
	font-size: 1.5rem;
	margin: 0 0 0 6px;
	opacity: 0.7;
}

.publish-drop-down {
	width: 350px;
	right: 0;
	top: 120%;
}

.publish-drop-down-row {
	width: 100%;
	display: flex;
	padding: 15px 13px;
}

.publish-drop-down-row:nth-child(1) {
	border-bottom: 1.5px solid var(--text-light-5);
}

.publish-drop-down-row > main {
	width: 100%;
	color: var(--text);
	text-align: left;
}

.publish-drop-down-row > main h3 {
	font-size: 1.2rem;
	font-weight: 500;
}

.publish-drop-down-row > main p {
	font-weight: 400;
	color: var(--text-light-3);
	margin-top: 7px;
}

.publish-drop-down-row > .checkbox {
	flex: 0 0 auto;
	margin-right: 10px;
	transform: scale(0.9);
}

.publish-drop-down-row
	> .checkbox
	input[type="checkbox"]:disabled
	~ .checkbox-checkmark {
	cursor: default;
	background-color: var(--text);
}

.publish-drop-down-close {
	margin-right: 7px;
}

.preview-btn {
	position: relative;
	margin-right: 12px;
}
.preview-btn-drop-down {
	z-index: 1;
	/* left: -80px; */
	left: 0px;
	width: 220px;
	top: 120%;
}
.preview-btn-drop-down > .drop-down-option {
	font-weight: 600;
}
.drop-down-option {
	color: var(--text-light);
	font-weight: 500;
	font-size: 1rem;
	white-space: nowrap;
	padding: 8px;
	border-radius: 6px;
	margin: 3px 0;
	transition: background-color 0.2s;
	display: flex;
	align-items: center;
}

.drop-down-option-radio {
	margin-right: 8px;
}

.drop-down-option-pulse {
	animation: drop-down-pulse 0.7s ease-in infinite;
}

.drop-down-option:hover {
	background-color: var(--bg-dark);
}

.drop-down-option span {
	font-size: 1.6rem;
	display: inline-block;
	margin-top: -10px;
	position: relative;
	top: 6px;
	color: var(--theme);
	margin-right: 6px;
}
.drop-down-option span img {
	width: 20px !important;
	padding: 0px !important;
	margin-left: 0px !important;
	height: 20px !important;
}

.custom-select-label {
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize !important;
	margin-top: 10px !important;
}

.custom-select-label .checkbox {
	display: inline-block;
	vertical-align: middle;
	margin-left: 8px;
	position: relative;
	bottom: 1px;
}

/* custom facebook styles */
.drop-down-option-facebook {
	background-color: var(--facebook-theme);
	color: #fff;
}

.drop-down-option-facebook:hover {
	color: var(--text-light);
}
.drop-down-option-facebook .dropdown-facebook-icon-dark img {
	display: none;
}
.drop-down-option-facebook .dropdown-facebook-icon-light img {
	display: block;
	margin-left: -5px !important;
}

.drop-down-option-facebook:hover .dropdown-facebook-icon-dark img {
	display: block;
}
.drop-down-option-facebook:hover .dropdown-facebook-icon-light img {
	display: none;
}

.inp,
.list-inp-container,
.toggle-inp-container {
	background-color: var(--bg-light);
	border: 0.1px solid rgba(0, 0, 0, 0.1);

	box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.06);
	border-radius: 12px;
	transition: opacity 0.2s, border-color 0.2s;
	height: 36px;
	min-width: 280px;
	display: block;
	padding: 20px;
	resize: none;
	outline: none;
}
.toggle-inp-container-disabled {
	pointer-events: none !important;
	cursor: default !important;
}
.inp-container {
	position: relative;
}
.inp-after-placeholder {
	position: absolute;
	min-width: 40px;
	color: var(--text-light-3);
	top: 1.1px;
	right: 1.1px;
	bottom: 1.1px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.inp-container-placeholder-left .inp-after-placeholder {
	left: 1.1px;
	right: auto;
}
.inp-container-placeholder-left .inp {
	padding-left: 50px;
}

.minimal-inp-after-placeholder-left .inp {
	padding-left: 30px;
}

.inp-unit {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 40px;
	background-color: var(--bg-dark);
	position: relative;
	font-size: 1.1rem;
	border-radius: 6px 12px 12px 6px;
}

.inp-unit-minimal {
	background-color: transparent;
}

.inp-container-placeholder-left .inp-after-placeholder .inp-unit {
	border-radius: 12px 6px 6px 12px;
}
.inp-counter {
	position: absolute;
	bottom: 12px;
	right: 12px;
	font-size: 1rem;
	font-weight: 500;
	color: var(--text-light-3);
	background-color: var(--bg-dark);
	padding: 6px 10px;
	border-radius: 15px;
}

.inp-counter span {
	color: var(--text);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

textarea.inp {
	height: 180px !important;
}

.inp:disabled {
	opacity: 0.8;
}

.inp:not(.inp:disabled):hover {
	border-color: var(--text-light-3);
}

.inp:not(.inp:disabled):focus {
	border-color: var(--theme);
}

.remote-inp {
	min-width: initial;
	width: auto;
	padding: 0px;
	border: none;
	border-radius: 0px;
	background-color: transparent;
	box-shadow: none;
	font-weight: 500;
	width: 400px;
	height: 26px;
}

.remote-inp-container .loader {
	flex: 0 0 auto;
}

.remote-inp-tooltip {
	width: 100% !important;
	padding: 8px !important;
	border-radius: 8px !important;
	bottom: 94%;
	left: -5px;
}

.remote-inp-tooltip .tooltip-head {
	font-size: 1rem;
	font-weight: 400;
	color: var(--text-light-3);
}

.remote-inp-tooltip p {
	color: var(--text);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.remote-inp-container-hidden {
	opacity: 0 !important;
}

.remote-inp-container {
	position: relative;
	display: flex;
	align-items: center;
	border: 2px solid transparent;
	transition: border-color 0.2s, background-color 0.2s;
	padding: 2px 4px;
	border-radius: 6px;
	background-color: transparent;
}

.remote-inp-container span {
	width: auto;
	min-width: initial;
	margin-left: 8px;
	margin-right: 5px;
	position: relative;
	top: 1px;
	color: var(--theme);
	opacity: 0;
	font-size: 1.25rem;
	transition: opacity 0.2s;
}
.remote-inp-container:hover,
.remote-inp-container-active,
.remote-inp-container-focus {
	border-color: var(--theme);
	background-color: var(--bg-light);
	/* opacity: 1; */
}
.remote-inp-container:hover span,
.remote-inp-container-active span,
.remote-inp-container-focus span {
	opacity: 1;
}

.action-inp-container {
	background-color: var(--bg-dark);
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 6px 8px;
	border-radius: 10px;
	margin-top: 8px;
	border: 1px solid var(--text-light-5);
}

.action-inp-container .inp {
	border: none;
	box-shadow: none;
	background-color: transparent;
	min-width: 0;
	padding: 0;
	width: 100%;
	margin-right: 6px;
}

.action-inp-container .btn {
	flex: 0 0 auto;
}
.module-navigation-error {
	margin: 8px auto;
}
.module-navigation-error-fit {
	width: fit-content !important;
}
.module-navigation-error-padded {
	margin: 8px 10px;
}
.unit-inp {
	display: flex;
	height: 40px;
	background-color: var(--bg-light);
	box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	margin: 10px 0 30px 0;
}

.unit-inp .select {
	height: 100%;
	padding: 7px;
	width: 135px;
	border: none;
	box-shadow: none;
	background-color: var(--bg);
	border-radius: 10px 0 0 10px;
}

.unit-inp .select .select-selected {
	padding: 0 8px;
	font-weight: 300;
}

.unit-inp input {
	border: none;
	width: 100%;
	outline: none;
	padding: 0 10px;
	background-color: transparent;
}

.unit-inp .select .select-icon {
	top: 6.5px;
	color: var(--text-light-3);
}

.shopify-dot {
	font-size: 1.15rem;
	margin-right: 4px;
	color: var(--shopify-green);
	display: inline-flex;
	align-items: center;
}

.shopify-dot-yellow {
	color: var(--dark-yellow-2);
}

.shopify-dot-yellow .dot {
	background-color: var(--dark-yellow-2);
}

.dot {
	display: inline-block;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	vertical-align: middle;
	background-color: var(--yellow);
	position: relative;
	top: 1.5px;
	margin-left: 1px;
}

.dot-green {
	background-color: var(--shopify-green);
}

.product-badge {
	display: inline-flex;
	vertical-align: top;
	margin: 8px;
	padding: 8px;
	border-radius: 10px;
	cursor: pointer;
	align-items: center;
	background-color: var(--bg-light);
	box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.07);
	border: 2px solid transparent;
	transition: border-color 0.2s, background-color 0.2s;
}

.product-badge:hover {
	background-color: var(--bg-dark);
}

.product-badge img {
	height: 44px;
	width: 44px;
	object-fit: cover;
	border-radius: 8px;
}

.product-badge h3 {
	font-size: 1.256rem;
	font-weight: 500;
	display: inline;
	padding: 0 12px;
}

.product-badge-active {
	border-color: var(--theme);
}

.lib-table {
	background-color: var(--bg-light);
	border-radius: 12px;
	/* overflow: hidden; */
	width: 100%;
	border-collapse: collapse;
}

.lib-table > th {
	background-color: var(--bg-light) !important;
	font-size: 1.1rem;
	font-weight: 500;
	outline: none;
	height: 60px;
}

.lib-table > thead th {
	text-transform: capitalize;
	padding: 10px;
	color: var(--text);
	letter-spacing: normal;
	text-align: left;
}

.lib-table-display-header {
	display: flex;
	align-items: center;
}

.lib-table-search-inp {
	display: inline-block;
	box-shadow: none;
	padding: 10px;
	min-width: 0;
}

.lib-table-right {
	text-align: right !important;
}

.lib-table .sr-only {
	display: none;
}

.lib-table-thin {
	width: 155px;
}

.lib-table-thin-2 {
	width: 110px;
}
.lib-table-thin-3 {
	width: 70px;
}

.lib-table-row {
	background-color: var(--bg-light);
	transition: background-color 0.2s;
	cursor: pointer;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.lib-table-sort-icon {
	display: inline-block;
	vertical-align: middle;
	color: var(--text-light-4);
	font-size: 0.9rem;
	position: relative;
	top: 1px;
	margin-right: 3px;
	transition: transform 0.2s;
}

.lib-table-sort {
	color: var(--theme) !important;
}
.lib-table-sort .lib-table-sort-icon {
	color: var(--theme);
}
.lib-table-row > td {
	padding: 16px 20px !important;
}

.lib-table-sort-desc .lib-table-sort-icon {
	transform: rotate(180deg) translateY(3.5px);
}

.lib-table-row-red {
	background-color: var(--light-red);
}

.lib-table-row-green {
	background-color: var(--light-green) !important;
}

.lib-table-row:hover {
	background-color: var(--bg) !important;
}

.lib-table-row-selected {
	background-color: var(--theme-light);
}

.pagination {
	display: flex;
	height: 60px;
	align-items: center;
	justify-content: flex-end;
	padding: 0 20px;
	width: 100%;
}

.pagination-page {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px solid var(--text-light-5);
	margin: 0 4px;
	color: var(--text-light-3);
	transition: border 0.2s, color 0.2s, background-color 0.2s;
	flex: 0 0 auto;
	cursor: pointer;
}

.pagination-page:not(.pagination-page-btn, .pagination-page-active):hover {
	background-color: var(--text-light-5);
}

.pagination-page-active {
	border-color: var(--theme);
	color: var(--theme);
}

.pagination-page-btn {
	color: var(--theme);
}

.pagination-page-btn span {
	font-size: 1.8rem;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: 3.5px;
}

.list-inp-container {
	height: 180px;
	padding: 10px !important;
}
.modal-list-inp-container {
	height: fit-content;
	max-height: 180px;
	overflow: auto;
	flex-wrap: wrap;
	align-items: flex-start;
	justify-content: flex-start;
	display: flex;
	margin-top: 10px;
	width: 100%;
}

.modal-list-inp-container-onboarding {
	padding: 7px !important;
	border-radius: 8px;
	box-shadow: none;
	border: 1px solid var(--disco-text-light);
}

.modal-list-inp-container-parent {
	position: relative;
	margin-top: 10px;
	width: 100%;
}
.new-modal-list-inp-container-parent > .disco-label {
	--margin-bottom: var(--disco-spacing-tn);
	align-items: center;
}
.new-modal-list-inp-container-parent .disco-inp-container {
	row-gap: var(--disco-spacing-tn);
}
.new-modal-list-inp-tag {
	margin-right: var(--disco-spacing-tn);
}
.new-modal-list-inp-tag > .disco-tag-content {
	display: flex;
	align-items: center;
}
.modal-list-inp-container-parent-actions {
	margin-top: 20px;
	font-size: 1.17rem;
}
.modal-list-inp-container-parent-actions > span {
	color: var(--theme-3);
	margin-left: 2px;
	cursor: pointer;
	font-weight: 600;
}
.modal-list-inp-container-parent-card {
	background: var(--bg-light);
	border: 0.5px solid rgba(0, 0, 0, 0.12);
	box-shadow: 0px 1px 2px rgba(3, 25, 38, 0.05),
		0px 0px 20px rgba(3, 25, 38, 0.05);
	border-radius: 12px;
	padding: 22px;
}
.modal-list-inp-container-parent-card .label {
	position: relative;
	color: var(--text);
	font-size: 1.16rem;
	width: 100%;
	text-transform: none;
}
.modal-list-inp-container-parent-card .label .base-tooltip {
	font-size: 1rem;
}
.modal-list-inp-container-parent-card .label > span:not(.label-required) {
	color: var(--highlight);
}
.modal-list-inp-container-parent-card .label-required {
	color: var(--theme-2);
}
.modal-list-inp-container-parent-card .modal-list-inp-container {
	border: 0px;
	box-shadow: none;
	padding: 3px 0px !important;
}
.modal-list-inp-container-parent-card .label .btn {
	margin-left: auto;
	position: absolute;
	right: 6px;
	top: -8px;
	height: 26px;
	font-size: 0.9rem !important;
}
.modal-list-inp-empty-text {
	color: var(--text-light-3);
	padding: 4px;
	font-weight: 500;
}
.modal-list-inp-container-parent-card .modal-list-inp-empty-text {
	font-size: 1.15rem;
	padding: 4px 0px;
}
.modal-list-inp-container-parent .mini-badge > b {
	font-weight: 600;
	margin-right: 3px;
}
.list-inp-icon-badge {
	display: flex;
	background-color: var(--theme) !important;
	color: var(--bg-light) !important;
	align-items: center;
	justify-content: center;
}
.list-inp-icon-badge span {
	position: relative;
	top: 1px;
	margin-right: 4px;
}
.list-inp-mini-badge {
	text-transform: capitalize;
}
.toggle-inp-container {
	padding: 0px 20px !important;
	display: flex;
	align-items: center;
	height: 43px !important;
}
.toggle-inp-loader {
	margin-top: 0px !important;
	margin: 0px !important;
	transform: scale(0.7);
}
.toggle-inp-feedback-text {
	display: flex;
	align-items: center;
}

/*
.toggle-inp-container:not(.toggle-inp-container:hover) .toggle-inp {
	margin: 0px !important;
	border-color: var(--text-light-4);
}

.toggle-inp-container:not(.toggle-inp-container:hover)
	.toggle-inp
	.toggle-handle {
	background-color: var(--text-light-4) !important;
}

.toggle-inp-container-activated .toggle-inp {
	margin: 0px !important;
	border-color: var(--green);
	background-color: var(--green);
}

.toggle-inp-container-activated:not(.toggle-inp-container-activated:hover)
	.toggle-inp
	.toggle-handle,
.toggle-inp-container-activated .toggle-inp .toggle-handle {
	background-color: var(--bg) !important;
}
 */
.toggle-inp-container span {
	margin-left: 16px;
	color: var(--text-light) !important;
}

.radio-inp-container {
	width: 100%;
}
.radio-inp-option {
	margin-top: 12px;
	color: var(--text);
	font-weight: 400;
	display: flex;
	align-items: flex-start;
	text-transform: none;
	font-size: 1.1rem;
	cursor: pointer;
}
.radio-inp-option.mobile {
	margin-top: 22px;
	color: var(--text-light-3);
}
.radio-inp-option-circle {
	width: 16px;
	min-width: 16px;
	height: 16px;
	min-height: 16px;
	border-radius: 100px;
	margin-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid var(--theme);
}
.radio-inp-option-filler {
	width: 8px;
	height: 8px;
	border-radius: 100px;
	background-color: var(--theme);
}
.radio-inp-option-content {
	width: 100%;
}
.radio-inp-option-content-children {
	margin-top: 10px;
	margin-left: 3px;
	width: 100%;
}

/* CUSTOM THEMING OF RADIO INPUT CONTAINER */
.radio-inp-container-distinct-options .radio-inp-option {
	background-color: var(--bg-light);
	padding: 7px 14px;
	border-radius: 8px;
	display: flex;
	border: 0.5px solid var(--bg-dark);
}
.radio-inp-container-distinct-options .radio-inp-option-circle-selected {
	background-color: var(--theme-2);
	border-color: transparent !important;
}
.radio-inp-container-distinct-options .radio-inp-option-circle {
	border-color: var(--text-light-3);
	margin-top: 2px;
}
.radio-inp-container-distinct-options .radio-inp-option-filler {
	background-color: var(--bg);
}
/* ---------- */
.label {
	display: block;
	color: var(--text-light-3);
	text-transform: uppercase;
	font-weight: 500;
	text-align: left;
	width: 100%;
}
.label-draft-badge {
	background-color: var(--dark-yellow);
	border-radius: 100px;
	width: 8px;
	height: 8px;
	margin-bottom: 1px;
	display: inline-block;
	margin-right: 8px;
}
.label-required {
	margin-right: 3px;
	font-size: 1.1rem;
	position: relative;
	top: 1px;
	font-weight: 500;
	color: var(--theme);
}

.label-center {
	margin-top: 10px;
	text-align: center;
	line-height: 18px;
}

.labeled-inp-tooltip-icon {
	margin-left: 5px;
	position: relative;
	top: 4px;
	font-size: 1.4rem !important;
}
.labeled-inp {
	position: relative;
	margin-top: 7px;
}
.labeled-inp .inp,
.labeled-inp .list-inp-container,
.labeled-inp .toggle-inp-container {
	min-width: initial;
	max-width: initial;
	width: 100%;
	margin-top: 10px;
	border: 1.5px solid var(--text-light-4);
}

.labeled-inp .inp:not(.inp:disabled):focus,
.labeled-inp .inp:not(.inp:disabled):hover,
.labeled-inp .toggle-inp-container:not(.toggle-inp-container:disabled):focus,
.labeled-inp .toggle-inp-container:not(.toggle-inp-container:disabled):hover {
	border-color: var(--theme);
}

.list-inp-container-mini-badge {
	display: flex;
	flex-wrap: wrap;
}

/* INSANELY DIFFERENT FROM GUIDED VIEW TOOLTIP */

.base-tooltip {
	background-color: var(--bg-light);
	border: 1px solid var(--theme);
	box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
	border-radius: 12px;
	width: 400px;
	max-height: 400px;
	overflow: auto;
	z-index: 2000;
	padding: 20px;
}
.base-tooltip-review {
	border: 1.4px solid var(--dark-yellow);
}
.base-tooltip-dark {
	position: absolute;
	padding: 32px;
	background: rgba(30, 30, 30, 0.9);
	border: 0.7px solid rgba(255, 255, 255, 0.26) !important;
	color: #fff;
	box-shadow: 0px 25px 30px rgba(0, 0, 0, 0.25);
	backdrop-filter: blur(5px);
}

.guided-tooltip {
	position: fixed;
}

.target-tooltip {
	position: fixed;
	top: 0;
	left: 0;
}

.target-tooltip-closed {
	pointer-events: none;
}

.grid-form {
	display: grid !important;
	grid-template-columns: 1fr 1fr;
	column-gap: 20px;
	align-items: start;
}

.grid-form > * {
	margin-top: 17px !important;
}

.grid-form .label {
	margin-top: 0 !important;
}

.grid-form .select-options {
	max-height: 200px;
}

.grid-form-tooltip {
	/* margin-left: 20px; */
}

.grid-form-tooltip-error {
	border-color: var(--red);
}

.grid-form-tooltip h3 {
	font-weight: 600;
	margin-bottom: 10px;
}

.grid-form-tooltip p {
	font-size: 1.1rem;
}

.grid-form-tooltip ul {
	margin-left: 20px;
	line-height: 18px;
}

/*  */
.guided-view {
	display: flex;
}

.guided-view-content,
.guided-view-tooltip-cont {
	position: relative;
	width: 50%;
	flex: 0 0 auto;
}
.guided-view-wo-tooltip .guided-view-content {
	width: 100%;
}
.guided-view-tooltip-cont {
	width: 45%;
	left: 5%;
	position: relative;
}

.guided-tooltip-indicator {
	font-size: 1.3rem;
	margin-right: 3px;
	display: inline-block;
	vertical-align: middle;
}

.guided-tooltip-indicator-red {
	color: var(--red);
}

.guided-tooltip-indicator-green {
	color: var(--green);
}

.guided-view-tooltip {
	position: absolute;
	top: 0;
	background-color: var(--bg-light);
	padding: 15px;
	border-radius: 15px;
	border: 2px solid var(--theme);
	box-shadow: 0px 0px 16px 2px rgba(0, 0, 0, 0.08);
	transition: background-color 0.2s, color 0.2s;
}

.guided-view-tooltip-error {
	border-color: var(--red);
	background-color: var(--light-red);
}

.guided-view-tooltip h2 {
	font-size: 1rem;
	font-weight: 600;
}

.guided-view-tooltip-error h2 {
	color: rgba(0, 0, 0, 0.7);
}

.guided-view-tooltip li {
	margin-left: 10px;
}

.guided-view-tooltip p {
	margin-top: 3px;
	color: var(--text-light);
	line-height: 18px;
}

.inp:disabled {
	opacity: 0.8;
}

.labeled-inp {
	position: relative;
	margin-top: 7px;
}

.labeled-inp-edit {
	display: inline-flex;
	align-items: center;
	margin: 8px 0;
	text-transform: initial;
	font-size: 1.2rem;
	margin-left: 10px;
}

.labeled-inp-edit .checkbox {
	margin: 0 4px 0 6px;
}

.labeled-inp .inp {
	min-width: initial;
	max-width: initial;
	width: 100%;
	border-radius: 12px;
	transition: border-color 0.2s;
	margin-top: 8px;
	border: 1.5px solid var(--text-light-5);
}

.labeled-inp .inp:not(.inp:disabled):focus,
.labeled-inp .inp:not(.inp:disabled):hover {
	border-color: var(--theme);
}

/* .labeled-inp .inp:focus,
.labeled-inp .inp:hover {
	border-color: var(--theme);
} */

/* .labeled-inp label {
	color: var(--text-light-3);
	text-transform: uppercase;
	font-weight: 500;
} */

.badge-image-inp {
	display: flex;
	padding: 5.5px;
	background-color: var(--bg-light);
	border-radius: 10px;
	border: 1px solid var(--text-light-5);
	align-items: center;
	margin: 10px 0;

	overflow: hidden;
}

.badge-image-inp-open {
	margin: 0 0 0 10px !important;
	color: var(--text-light) !important;
	font-weight: 600;
	width: 70px;
	padding: 4px 11px;
	border-color: var(--text-light-4);
	background-color: var(--bg);
}

.badge-image-inp-hero {
	display: flex;
	align-items: center;
	flex: 1 1 auto;
	width: 100%;
	max-width: calc(100% - 80px);
}

.badge-image-inp-preview {
	width: 40px;
	height: 40px;
	object-fit: cover;
	border-radius: 5px;
	margin-right: 10px;
	flex: 0 0 auto;
}

.badge-image-inp-name {
	flex: 1 1 auto;
	height: 30px;
	background-color: var(--theme-light);
	padding: 0 15px;
	margin: 0 !important;
	text-overflow: ellipsis;
	overflow: hidden;
}

.select-label-container {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
}
.select-tooltip-icon {
	margin-left: 5px;
	font-size: 1.4rem !important;
}
.select-tooltip {
	margin-top: 18px;
}

.select {
	position: relative;
	padding: 14px 0;
	width: 300px;
	background-color: var(--bg-light);
	border: 0.1px solid var(--text-light-5);
	box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.06);
	border-radius: 12px;
	transition: opacity 0.2s, border-color 0.2s;
	font-weight: 500;
	z-index: 100;
	outline: none;
	cursor: pointer;
}

.select-minimal {
	padding: 0;
	background-color: transparent;
	box-shadow: none;
	border: none;
	margin: 0 5px;
}

.select-minimal .select-selected {
	padding: 0;
}

.select-minimal .select-icon {
	top: -1px;
}

.select-minimal .select-icon:hover {
	background-color: transparent;
}

.select-minimal .select-option {
	font-size: 1rem;
	line-height: 32px;
	height: 32px;
	padding: 0 8px;
}
/* .multi-select {
	padding: 10px 0;
} */

.select-error {
	margin: 6px 0 10px 0 !important;
}

.select-label {
	margin-bottom: 5px;
	width: fit-content;
}

.select .loader {
	margin: 10px auto;
}

.select:focus,
.select:hover {
	border-color: var(--theme);
}

.select-icon {
	position: absolute;
	display: block;
	top: 12px;
	right: 10px;
	font-size: 1.5rem;
	color: var(--text);
	padding: 4px;
	z-index: 2;
	display: flex;
	align-content: center;
	justify-content: center;
	border-radius: 50%;
	transition: background-color 0.2s;
}

.select-icon:hover {
	background-color: var(--bg-dark-2);
}

.select-icon-no-style {
	position: relative;
	top: 0;
	right: 0;
	display: inline;
}

.select-selected {
	position: relative;
	padding: 0 14px;
	border-radius: 12px;
	height: 100%;
	width: 92%;
	line-height: 22px;
	background-color: inherit;
}

.multi-select .select-selected {
	padding: 0 10px;
	height: 30px;
	line-height: 30px;
	overflow: auto;
}

.mini-badge-select-selected {
	display: flex;
	flex-wrap: wrap;
	height: fit-content;
}

.mini-badge-select-selected .mini-badge {
	margin: 0 3px;
	height: 30px !important;
	line-height: 20px;
}

.select-selected .loader {
	display: inline-block;
	vertical-align: middle;
	margin: -5px 0 0 0 !important;
}

.select-selected .badge-btn-list-item {
	margin: 4px !important;
}

.select-empty .select-selected {
	color: var(--text-light-3);
}

.select-options {
	position: absolute;
	border-radius: 10px;
	width: calc(100% + 2px);
	top: -1px;
	max-height: 250px;
	min-height: 100%;
	left: -1px;
	background-color: var(--bg-light);
	overflow: auto;
	box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.07);
	border: 0.5px solid var(--theme);
	z-index: 1;
}
.select-options-mobile {
	max-height: 55vh;
}
.select-options-mobile .modal-sheet-header {
	padding-bottom: 10px;
}
.select-options-wrapper {
	overflow: auto;
	max-height: calc(55vh - 100px);
}
.select-options-expandable {
	display: flex;
}
.select-options-expandable > div {
	width: 50%;
	max-height: 395px;
	overflow: auto;
}
.select-options-expandable-area {
	left: 50%;
	width: 50%;
	height: 395px;
	border-left: 0.8px solid var(--theme);
	overflow: auto;
	padding: 26px;
	padding-top: 20px;
	background-color: var(--bg-dark);
}
.select-options-expandable-independant-area {
	padding: 0px !important;
}
.select-options-expandable-independant-area .modal-preview-group {
	margin: 0px !important;
	height: 395px !important;
	border-radius: 0px;
}
.select-options-expandable-independant-area .modal-preview-group h2 {
	font-weight: 500;
	font-size: 1rem;
}

.select-options-expandable-area-body {
	margin-top: 10px;
}

.select-no-options {
	min-height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	font-weight: 600;
	width: 60%;
	text-align: center;
	margin: auto;
	color: var(--text-light-3);
}

.select-option {
	height: 37px;
	line-height: 38px;
	padding: 0px 12px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	transition: background-color 0.3s, color 0.3s;
}
.select-options-mobile .select-option {
	padding: 0px;
	margin: 0px 8px;
}

.select-option-multiple {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.select-option-multiple .checkbox {
	margin-left: 4px;
}

.select-option-highlighted {
	background-color: #e7f0ff;
	color: var(--theme-2) !important;
}

.select-option-group-icon {
	color: var(--dark-green);
	font-size: 1.4rem;
	margin: -10px 6px 0 0;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: 7px;
}

.select-option-group {
	padding: 8px;
	border-radius: 10px !important;
	background-color: var(--bg);
	width: 95% !important;
	margin: 12px auto;
	box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.07);
}

.select-option-group .collapsable-head {
	padding: 5px;
}

.select-option-group > h2 {
	font-weight: 600;
	color: var(--text-light-3);
	text-transform: uppercase;
	font-size: 0.9rem;
	letter-spacing: 1px;
	padding: 10px 6px;
}

.select-icon-red {
	color: var(--red) !important;
}

.select-option span {
	color: var(--dark-green);
	font-size: 1.6rem;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: 2px;
}

.select-option:hover {
	background-color: var(--bg-dark);
}

.select-padded {
	width: auto;
	min-width: 0;
	padding: 8px 0;
}

.select-padded .select-options {
	padding: 4px;
	border: none;
}

.select-padded .select-option {
	border-radius: 5px;
}

.select-padded .select-option:hover {
	background-color: var(--theme-light);
	color: var(--theme);
}

.select-padded .select-icon {
	top: 7px;
	z-index: 1;
}

.select-no-style {
	background-color: transparent;
	border: none;
	box-shadow: none;
	width: fit-content;
	padding: 0;
}

.select-no-style .select-selected {
	width: unset;
	padding: 0;
}

.dropdown-select-gradient {
	padding: 12px 8px;
	border: none;
	background-image: var(--btn-gradient);
}

.dropdown-select-gradient .select-icon {
	color: var(--bg-light);
	top: 10px;
}

.dropdown-select-gradient .select-icon:hover {
	background-color: transparent;
}

.dropdown-select-gradient .select-selected {
	color: var(--bg-light) !important;
	background-color: transparent;
}

.toggle {
	position: relative;
	width: 52px;
	height: 28px;
	line-height: 21px;
	display: flex;
	border: 2px solid transparent;
	border-radius: 50px;
	padding: 2px;
	cursor: pointer;
	background-color: var(--text-light-3);
}

.toggle-traffic-light {
	width: 40px;
	height: 24px;
	background-color: var(--light-red-2);
	border: 1px solid var(--bg-light);
	outline: 1.5px solid var(--light-red-2);
}

.toggle[data-toggle="true"] {
	border-color: var(--theme);
	background-color: var(--theme);
}

.toggle[data-toggle="true"] .toggle-handle {
	background-color: var(--bg-light);
}

.toggle-traffic-light.toggle[data-toggle="true"] {
	background-color: var(--light-green-2);
	border: 1px solid var(--bg-light);
	outline: 1.5px solid var(--green);
}

.toggle-traffic-light.toggle[data-toggle="true"] .toggle-handle {
	background-color: var(--green);
	color: var(--bg-light);
}

.toggle-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	background-color: var(--bg-light);
	border-radius: 100%;
	transition: background-color 0.2s;
}

.toggle-traffic-light .toggle-handle {
	height: 17px;
	width: 16px;
	background-color: var(--light-red);
	color: var(--red);
	text-align: center;
	font-size: 1.1rem;
}

.toggle-dummy {
	background-color: var(--text-light-5) !important;
	border-color: transparent !important;
	font-size: 0.85rem;
	text-transform: uppercase;
	font-weight: 600;
	padding-left: 5px;
	/* transform: scale(0.86); */
	transform-origin: 100% 50%;
}

.toggle-dummy .toggle-handle {
	background-color: var(--text-light-4) !important;
	text-align: center;
	font-size: 1.15rem;
	color: var(--text-light-5);
}

.large-list {
	background-color: var(--bg-light);
	padding: 10px;
	border-radius: 10px;
}

.large-list-item {
	display: flex;
	padding: 20px 25px 20px 20px;
	border-radius: 8px;
	background-color: var(--bg);
	box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.12);
	font-size: 1.8rem;
	align-items: center;
	font-weight: 700;
	margin: 10px 0;
}

.large-list-item > main {
	line-height: 25px;
}

.large-list-item > aside {
	height: 42px;
	width: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	flex: 0 0 auto;
	margin-right: 15px;
	background-color: var(--theme);
	color: var(--bg-light);
}

.large-list-item-icon {
	font-size: 2.6rem;
	position: relative;
	top: 5px;
}

.copy-block {
	position: relative;
	width: 100%;
}

.copy-block textarea {
	width: 100%;
	border: 1px solid var(--text-light-4);
	background-color: var(--bg-dark);
	border-radius: 10px;
	padding: 15px 60px 15px 15px;
	outline: none;
	resize: none;
	font-family: monospace;
	overflow: hidden;
}

.copy-block-open textarea {
	overflow: auto;
}

.copy-block-shadow {
	position: absolute;
	height: 40px;
	width: 100%;
	bottom: 0;
	left: 0;
	background-image: linear-gradient(
		180deg,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 0.6),
		rgba(255, 255, 255, 0.7)
	);
}

.copy-block-toggle {
	position: absolute;
	height: 30px;
	width: 30px;
	background-color: var(--bg-light);
	color: var(--theme);
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid var(--text-light-4);
	border-radius: 50%;
	font-size: 1.2rem;
	left: calc(50% - 15px);
	bottom: -12px;
	cursor: pointer;
}

.copy-block-toggle span {
	position: relative;
	top: 2px;
}

.copy-block-copy {
	position: absolute;
	right: 10px;
	top: 10px;
	padding: 5px 9px;
	background-image: var(--btn-gradient);
	color: var(--bg-light);
	box-shadow: 0px 0px 2px 0.5px rgba(0, 0, 0, 0.08);
	border-radius: 6px;
	cursor: pointer;
}

.accordion {
	--padding: 20px;
	--curve: 10px;
	padding: var(--padding);
	border: 1px solid var(--text-light-4);
	border-radius: var(--curve);
	box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.06);
	position: relative;
}
.accordion-banner {
	width: calc(100% + 2 * var(--padding));
	border-radius: var(--curve) var(--curve) 0 0;
	--offset: calc(-1 * var(--padding));
	margin: var(--offset) auto 20px var(--offset);
	padding: var(--padding);
}
.accordion-dashed {
	border-style: dashed;
}

.accordion > header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.accordion > header > main {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.4rem;
	font-weight: 600;
}

.accordion > header > main > img {
	height: 28px;
	margin-right: 8px;
}

.accordion-toggle {
	font-size: 1.4rem;
	color: var(--theme);
	cursor: pointer;
}

.accordion-toggle-btn {
	height: 40px !important;
}

.accordion-toggle-btn > span {
	margin-right: 2px;
	font-size: 1.2rem;
	position: relative;
	top: 2px;
}

.accordion-toggle-btn-disabled {
	opacity: 0.7;
}

.stepped-accordion {
	box-shadow: none;
	background-color: var(--disco-bg-light);
}

.stepped-accordion > header > main > img {
	height: 35px;
}

.stepped-accordion > main {
	position: relative;
	border-top: 1px solid var(--text-light-5);
	margin-top: 20px;
}

.stepped-accordion-line {
	position: absolute;
	height: calc(100% - 45px);
	left: 5px;
	width: 1.5px;
	background-color: var(--disco-text-light);
	flex: 0 0 auto;
}
.stepped-accordion-line-eraser {
	height: 100%;
	background-color: var(--disco-bg-light);
	width: 5px;
	position: absolute;
	left: -24px;
}
.stepped-accordion-steps {
	padding-left: 40px;
	position: relative;
}

.stepped-accordion-step {
	position: relative;
}

.stepped-accordion-step:last-of-type {
	margin-bottom: 10px;
}

.stepped-accordion-step-index {
	position: absolute;
	left: -35px;
	height: 22px;
	width: 22px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: var(--disco-text-light);
	color: var(--disco-bg-light);
	border-radius: 50%;
	outline: 8px solid var(--disco-bg-light);
}

.btn {
	display: block;
	background-color: var(--theme-dark);
	color: var(--bg);
	border: none;
	font-weight: 600;
	padding: 0 20px;
	border-radius: 8px;
	font-size: 0.9rem;
	outline: none;
	height: 36px;
	cursor: pointer;
	transition: opacity 0.2s, background-color 0.2s, border-color 0.2s;
}

.btn-rounded {
	border-radius: 40px !important;
}

.btn-gradient {
	background-image: var(--btn-gradient);
}

.btn-disco-gradient {
	background-image: var(--btn-disco-gradient);
}

.btn-gray {
	background-color: var(--text-light-3);
}

.btn-gray-stroke {
	background-color: var(--bg);
	border: 1px solid var(--text-light-5);
	color: var(--text-light-3);
	font-weight: 500;
	font-size: 1.1rem;
}

.btn-gray-gradient {
	background-image: var(--gray-gradient);
}

.btn-small {
	height: 32px;
	font-weight: 400;
	font-size: 1.1rem !important;
	background-color: var(--theme-3);
}

.btn-light {
	background-color: var(--bg-light);
	color: var(--theme);
	border: 1px solid var(--text-light-5);
	text-transform: initial;
	border-radius: 8px;
	font-size: 1.05rem;
}

.btn-skinny {
	font-size: 1.2rem;
	font-weight: 400;
}

.btn-dark {
	background-color: var(--text);
}

.btn:disabled {
	opacity: 0.7;
}

.btn-dark:disabled {
	background-color: var(--bg-dark-2);
	color: var(--text-light);
}

.btn-large {
	padding: 0 25px;
	height: 40px;
	font-size: 1.1rem;
}

.btn-x-large {
	height: 52px;
	font-size: 1.5rem;
}
.btn-with-icon {
	display: flex;
	align-items: center;
}
.btn-with-icon svg {
	font-size: 1.4rem;
	margin: 0px 5px;
}

.btn-shadow {
	box-shadow: 0px 3px 4px rgba(1, 2, 4, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.badge {
	height: 24px;
	padding: 0 10px;
	border-radius: 6px;
	font-weight: 500;
	font-size: 0.9rem;
	white-space: nowrap;
}

.badge-view-only {
	cursor: default;
}

.badge-light {
	background-color: var(--bg-light);
	color: var(--text);
	border: 1.5px solid var(--text-light-5);
}

.badge-light-theme {
	color: var(--theme);
}

.badge-large {
	height: 28px;
	line-height: 28px;
	font-weight: 400;
	font-size: 1.2rem;
	padding: 0 15px;
}

.badge > span {
	font-size: 1.4rem;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: 1.5px;
	margin-right: 2px;
}

.badge-large > span {
	font-size: 1.7rem;
	top: 2px;
	margin-left: -10px;
	margin-right: 6px;
}

.badge-subtle {
	background-color: var(--theme-light-2);
	color: var(--theme-dark-2);
}

.badge-subtle-light {
	background-color: var(--bg-dark);
	color: var(--text-light-3);
}

.icon-btn {
	white-space: nowrap;
}
.icon-btn-dark-text {
	color: var(--text-light);
}

.icon-btn span {
	font-size: 1.4rem;
	display: inline-block;
	vertical-align: middle;
	position: relative;
	top: 6.2px;
	margin: -10px 4px 0 -4px;
}

.icon-btn-rounded {
	border-radius: 30px;
}

.icon-btn-rounded span {
	color: var(--text-light-4);
	margin-right: 0px;
	margin-left: -12px;
	font-size: 2rem;
	top: 7px;
}

.icon-btn-empty span {
	margin: -10px 0px 0 0px;
}

.remote-btn .loader {
	margin-top: -3px;
}
.remote-btn-filled-loading {
	padding: 0px 14px !important;
}

.remote-btn-enabled {
	color: var(--text-light);
	padding-right: 32px;
}

.remote-btn-enabled span {
	position: relative;
	display: inline-block;
	color: var(--green);
	font-size: 1.8rem;
	top: 7px;
	margin: -16px 6px 0px 0;
}

.tag-btn {
	position: relative;
	height: 26px;
	text-transform: capitalize;
	font-weight: 500;
	border-radius: 13px;
	padding: 0 10px;
	background-color: transparent;
	transition: opacity 0.2s;
	border: 2px solid var(--theme);
	color: var(--theme);
}

.tag-btn-empty {
	padding: 0;
	width: 26px;
}

.tag-btn-empty .tag-btn-icon {
	margin: -4px 0 0 0;
}

.tag-btn-icon {
	display: inline-block;
	font-size: 1.15rem;
	position: relative;
	top: 4px;
	margin: -6px 3px 0 3px;
}

.tag-btn-red {
	border: 2px solid var(--red);
	color: var(--red);
}
.tag-btn-dark {
	border: 2px solid var(--text-light);
	color: var(--text-light);
}
.tag-btn-green {
	border: 2px solid var(--dark-green);
	color: var(--dark-green);
}
.tag-btn-light {
	border: 2px solid var(--bg-light);
	color: var(--bg-light);
}
.tag-btn:disabled {
	opacity: 0.5;
}

.slack-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	border-color: var(--slack-bg) !important;
	color: var(--slack-bg) !important;
	width: 200px;
	font-weight: 500;
	height: 36px !important;
	margin-right: 10px;
	padding: 0 !important;
}

.slack-btn:hover {
	background-color: var(--slack-bg) !important;
	color: var(--text) !important;
}

.slack-btn img {
	height: 18px;
	margin-right: 5px;
	margin-left: 5px;
}

.slack-btn-filled {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: auto;
	background-color: var(--text);
	opacity: 0.86;
	width: 100%;
	height: 48px;
	margin-bottom: 40px;
	font-size: 1rem;
	font-weight: 500;
}

.slack-btn-filled img {
	height: 20px;
	margin-right: 10px;
}

.badge-btn {
	border-radius: 30px;
	background-color: transparent;
	height: 40px;
	padding: 0 20px;
	border: 2px solid var(--theme);
	color: var(--theme);
	transition: background-color 0.25s, color 0.2s, border-color 0.2s,
		opacity 0.2s;
}

.badge-btn:hover {
	background-color: var(--theme);
	color: var(--bg-light);
}

.badge-btn-light {
	color: var(--bg);
	border-color: var(--bg);
}

.badge-btn-dark {
	color: var(--text);
	border-color: var(--text);
}

.badge-btn-dark:hover {
	background-color: var(--text);
	color: var(--bg-light);
}

.badge-btn-small {
	display: inline-block !important;
	padding-top: 1px;
	height: 32px;
	font-size: 0.85rem;
	font-weight: 500;
	margin: 5px;
}

.badge-btn-disabled {
	opacity: 0.66;
}

.badge-btn-disabled:hover {
	background-color: transparent !important;
	color: var(--theme) !important;
}

.badge-btn-list-item {
	text-transform: capitalize;
	font-weight: 500;
	color: var(--text);
	padding: 7px 10px 6px 10px !important;
}

.base-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.base-form-head {
	font-size: 2rem;
	margin: 10px 0;
}

.base-form .inp {
	margin-top: 10px;
}

.base-form .btn {
	margin-top: 25px;
}

.color-inp {
	position: relative;
}

.color-inp .labeled-inp {
	position: relative;
}

.color-inp .inp input {
	text-transform: uppercase;
}

.color-inp-color {
	position: absolute;
}

.color-inp-preview {
	position: absolute;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	border: 1px solid var(--text-light-5);
	top: calc(50% - 2px);
	right: 20px;
}

.card {
	display: flex;
	width: 90%;
	max-width: 650px;
	padding: 25px;
	border-radius: 30px;
	background-color: var(--bg-light);
	box-shadow: 0px 0px 20px 2px rgba(0, 0, 0, 0.02);
}

.full-card {
	background-color: var(--bg-light);
	width: 100%;
	border: 1.5px solid var(--text-light-5);
	border-radius: 10px;
	padding: 15px;
}
.full-card video {
	width: 100%;
	border-radius: 12px;
}

.floating-card {
	padding: 38px 30px;
	border: 0.5px solid #c4cede;
	box-shadow: 0px 28px 40px rgba(41, 43, 52, 0.16), inset 0px -2px 0px #ffffff;
	border-radius: 12px;
}

.credits-view {
	width: 100%;
	margin-left: 6px;
	padding: 7px;
	border-radius: 6px;
	font-weight: 500;
	background-color: rgba(255, 255, 255, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.2);
	box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
	cursor: pointer;
}

.credits-view-blue {
	background-color: var(--theme);
}

.credits-view-value {
	width: 100%;
	border-radius: 4px;
	padding: 6px;
	margin-top: 5px;
	color: var(--bg-light);
	font-weight: 500;
	background-color: rgba(255, 255, 255, 0.15);
}

.credits-view .btn {
	margin-top: 18px;
	width: 100% !important;
}

.credits-view h3 {
	font-size: 1rem;
	color: var(--bg-light);
	margin-top: 3px;
	font-weight: 600;
	padding: 0 2px;
}

.form-status {
	--form-status-color: var(--red);

	margin-top: 18px;
	background-color: var(--form-status-color);
	color: var(--bg);
	padding: 10px;
	border-radius: 6px;
	width: 100%;
	font-weight: 500;
	font-size: 1rem;
	text-align: center;
}

.form-status-success {
	--form-status-color: var(--green);
}

.form-status-theme {
	--form-status-color: var(--theme);
}
.form-status-yellow {
	--form-status-color: var(--light-yellow);
	color: var(--text);
}

.form-status-outlined {
	background-color: transparent;
	border: 2px solid var(--form-status-color);
	color: var(--text);
}

.checkbox {
	position: relative;
	display: inline-block;
	height: 21px;
	width: 21px;
	border-radius: 4px;
	border: 2px solid var(--text-light-4);
	background-color: var(--bg-light);
	cursor: pointer;
	transition: border-color 0.2s;
}

.checkbox:hover {
	border-color: var(--text-light-3);
}

.checkbox input[type="checkbox"] {
	position: absolute;
	height: 0;
	width: 0;
	opacity: 0;
}

.checkbox input[type="checkbox"] ~ .checkbox-checkmark {
	position: absolute;
	top: -2px;
	left: -2px;
	height: calc(100% + 4px);
	width: calc(100% + 4px);
	background-color: var(--theme-2);
	color: var(--bg-light);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1.2rem;
	border-radius: 4px;
	transform: scale(0);
	transition: transform 0.2s var(--easing-slick);
}

.checkbox input[type="checkbox"]:checked ~ .checkbox-checkmark {
	transform: scale(1);
}

.file-input {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 25px;
	border-radius: 10px;
	background-color: var(--bg);
}

.file-input input[type="file"] {
	opacity: 0;
	position: absolute;
	cursor: pointer;
}

.file-input-selection {
	font-size: 1rem;
	font-weight: 300;
	color: var(--text-light);
}

.file-input-selection span {
	font-weight: 500;
	color: var(--theme);
	text-transform: initial;
}

.file-input label {
	margin: auto;
	margin-top: 10px;
	color: var(--theme);

	font-weight: 600;

	cursor: pointer;
}

.tooltip {
	display: inline-block;
	font-size: 1.6rem;
	color: var(--theme);
	cursor: help;
}

.tooltip-minimal {
	color: var(--text-light-4) !important;
	font-size: 1.4rem;
}

.tooltip-minimal + .tooltip-content {
	left: 5%;
	width: 90%;
}

.tooltip-content {
	position: absolute;
	z-index: 100;
	background-color: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(10px);
	font-size: 1.05rem;
	font-weight: 400;
	color: var(--text);
	display: inline-table;
	flex-direction: column;
	width: 400px;
	padding: 18px;
	text-transform: none !important;
	letter-spacing: 0 !important;
	border-radius: 20px;
	box-shadow: 0px 0px 18px 2px rgba(0, 0, 0, 0.06);
}

.tooltip-content h2 {
	font-size: 1.15rem;
	margin-bottom: 4px;
	font-weight: 600;
	color: var(--text-light);
}

.independant-tooltip-content {
	width: 90%;
	left: 5%;
	margin-top: -4px;
}

.image-input {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	border-radius: 10px;
	background-color: var(--bg);
	padding-bottom: 20px;
}

.image-input .thumbnail {
	height: 100px;
	width: 140px;
	margin-top: 0px;
	background-color: var(--bg-dark-2);
	border-radius: 6px;
	overflow: hidden;
}

.image-input .thumbnail img {
	display: block;
	height: 100%;
	width: 100%;
	object-fit: contain;
}

.image-input-desc {
	width: 96%;
	background-color: var(--bg-dark);
	padding: 15px;
	margin-top: 20px;
	border-radius: 9px;
	font-size: 1.2rem;
	line-height: 20px;
	color: var(--text-light);
	text-align: center;
}

.image-input-desc span {
	font-weight: 600;
}

.flex-br {
	width: 100%;
	height: 0;
}

.widget .form-status {
	position: relative;
	width: 80%;
	margin: auto;
	top: 100px;
}

/* UNIVERSAL PREVIEW CODE */

.edit-image {
	position: relative;
	margin: 12px auto;
	width: 180px !important;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.edit-image img {
	cursor: pointer;
	border-radius: 4px;
	width: 100%;
	height: 180px;
	border-radius: 100%;
	object-fit: contain;
}
.edit-image-review {
	margin-top: 5px;
	padding: 5px 10px;
	border-radius: 100px;
	border: 1px solid var(--theme);
	color: var(--theme);
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}
.edit-image-review:hover {
	color: #fff;
	background-color: var(--theme);
}
.edit-image-review span {
	margin-left: 4px;
}
.edit-image-preview {
	justify-content: center;
	align-items: center;
	top: 0px;
	background-color: transparent;
	backdrop-filter: blur(0px);
	box-shadow: none;
	padding: 0px !important;
	border-radius: 1000px !important;
}
.edit-image-preview img {
	width: 180px;
	height: 180px;
	margin: auto;
}
.edit-image-open {
	padding: 8px;
	border-radius: 10px;
	font-size: 1.1rem;
	color: var(--text-light-3);
	transition: background-color 0.2s;
	cursor: pointer;
	margin-top: 10px;
}

.edit-image-open:hover {
	background-color: var(--bg-dark);
}

.edit-image-open span {
	display: inline-block;
	vertical-align: middle;
	margin-right: 6px;
}

.edit-mode-toggle {
	background-color: var(--yellow) !important;
	color: rgba(0, 0, 0, 0.6) !important;
}

.edit-mode-toggle .badge-btn {
	margin-top: 10px !important;
	height: 30px !important;
	color: rgba(0, 0, 0, 0.4) !important;
	border-color: rgba(0, 0, 0, 0.4) !important;
}

.edit-mode-toggle .badge-btn:hover {
	background-color: rgba(0, 0, 0, 0.4) !important;
	border-color: rgba(0, 0, 0, 0) !important;
	color: var(--bg-light) !important;
}

.edit-mode-toggle h2 {
	margin-top: 4px;
	font-size: 1rem;
}

.edit-mode-toggle p {
	margin-top: 5px;
	font-size: 0.85rem;
}

.tabs {
	width: auto;
	display: flex;
}

.tab {
	--border-color: transparent;
	transition: border-color 0.2s, color 0.2s;
	text-transform: capitalize;
	cursor: pointer;
	margin-right: var(--disco-spacing-rg);
	border-bottom: var(--disco-stroke-lg) solid var(--border-color);
}

.tab:hover {
	--border-color: var(--disco-theme-default);
}

.tab-active {
	--border-color: var(--disco-theme-default);
	--color: var(--disco-theme-default);
}

.collapsable {
	position: relative;
	width: 100%;
	padding: 8px;
	padding-bottom: 12px;
	margin-top: 15px;
	border-bottom: 2px solid var(--text-light-5);
	cursor: pointer;
	display: flex;
	flex-direction: column;
}

.collapsable-left {
	border-bottom: none;
	padding-bottom: 10px;
}
.collapsable-toggle,
.collapsable-grabber {
	position: absolute;
	top: 6px;
	font-size: 1.6rem;
	right: 35px;
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--text-light-3);
}

.collapsable-grabber {
	right: 10px;
}

.collapsable-toggle-left {
	right: initial;
	left: 8px;
	top: 3px;
	padding: 0 5px;
	border-radius: 5px;
	background-color: var(--bg-light);
	border: 1px solid var(--text-light-5);
	transition: color 0.2s;
}

.collapsable-open .collapsable-toggle-left {
	color: var(--theme);
}

.collapsable-head {
	font-size: 1.1rem;
	color: var(--text-light-3);
	width: calc(100% - 70px);
	font-weight: 500;
}
.collapsable-head-left {
	position: relative;
	left: 40px;
}

.collapsable-open .collapsable-head {
	color: var(--text);
}

.core-section-heading {
	width: 100%;
	text-align-last: left;
	margin: 20px 0 5px 0;
	border-bottom: 1px solid var(--theme);
	padding-bottom: 10px;
	text-transform: uppercase;
	color: var(--theme);
	font-size: 1rem;
	letter-spacing: 2px;
	font-weight: 600;
	display: flex;
}

.core-section-heading-icon {
	margin: 2px 8px 0px 0px;
}

.core-section-heading .tooltip {
	position: relative;
	top: -3px;
	margin-left: 6px;
}

.core-section-heading .tooltip-content {
	color: var(--text);
	text-transform: initial;
	letter-spacing: initial;
	font-size: 1.12rem;
}

.status-toggle {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: flex-start;
	width: 100% !important;
}

.status-toggle-main {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	width: 90px;
	padding-right: 15px;
	position: relative;
}
.status-toggle-text {
	width: calc(100% - 90px);
}

.status-toggle-text h3 {
	font-weight: 500;
	font-size: 1.1rem;
}

.status-toggle-text p {
	font-size: 1.1rem;
	margin-top: 4px;
}

.status-toggle .form-status {
	width: 100% !important;
}

.status-toggle .toggle {
	position: relative;
}

.status-toggle h4 {
	font-weight: 500;
	font-size: 1.1rem;
	color: var(--text);
}

.status-toggle .loader {
	display: block;
	position: absolute;
	top: -6px;
	right: 0;
}

.subscription-features {
	margin: auto;
	border: 2px solid var(--theme);
	border-radius: 20px;
	padding: 30px 40px;
	background-color: var(--bg-light);
}

.subscription-features h4 {
	text-transform: uppercase;
	font-size: 1rem;
	font-weight: 600;
	color: var(--theme);
}

.subscription-feature-slabs {
	display: flex;
	justify-content: space-between;
	/* margin-top: 8px; */
	flex-direction: column;
}
.subscription-feature-slab {
	background-color: var(--bg);
	padding: 20px 15px;
	margin-top: 8px;
	border-radius: 10px;
}

.subscription-features-price {
	display: flex;
	flex-direction: row;
	margin-top: 8px;
	align-items: flex-end;
}
.subscription-feature-line {
	width: 30px;
	background-color: var(--theme);
	width: 30px;
	height: 4px;
	border-radius: 2px;
	margin-top: 6px;
}
.subscription-feature-price-highlight {
	text-transform: uppercase;
	font-size: 1.8rem;
	font-weight: 700;
	color: var(--text);
}

.subscription-feature-price-highlight sup {
	text-transform: lowercase;
	font-weight: 700;
	font-size: 1rem;
}
.subscription-features h5 {
	color: var(--text-light);
	margin-top: 20px;
	font-size: 1.02rem;
}
.subscription-separator {
	font-size: 1.8rem;
	margin-top: 10px;
	color: var(--theme);
	text-align: center;
}

.subscription-features-features {
	margin-top: 14px;
	font-size: 1.2rem;
	list-style: none;
}

.subscription-features li {
	display: block !important;
}

.subscription-features p {
	margin-top: 20px;
	font-size: 1.1rem;
}

.subscription-features-features-icon {
	font-size: 1.6rem;
	margin-right: 8px;
	position: relative;
	top: 8px;
	color: var(--green);
}

.subscription-features-features > div {
	margin-top: 3px;
}

.product {
	height: 140px;
	margin-top: 12px;
	width: 100%;
	padding: 10px;
	border-radius: 8px;
	background-color: var(--bg-dark);
	align-items: center;
	display: flex;
}

.product img {
	height: 100%;
	width: 120px;
	object-fit: cover;
	border-radius: 6px;
}

.product-info {
	margin-left: 20px;
	height: auto;
}

.product-info h4 {
	font-size: 1.45rem;
	color: var(--text);
}
.product-info h5 {
	font-size: 1.2rem;
	color: var(--text-light);
	font-weight: 400;
	margin-top: 5px;
}

.product-small {
	height: 80px;
	background-color: var(--bg);
}
.product-small img {
	width: 60px;
	border-radius: 12px;
}
.product-small .product-info h5 {
	font-size: 1.1rem;
	font-weight: 500;
}
.tab-switcher-background {
	background-color: var(--bg-dark);
	margin-top: 12px;
	border-color: var(--bg-dark-2);
	position: relative;
	display: flex;
	width: fit-content;
	border: 1.5px solid var(--text-light-4);
	border-radius: 12px;
}
.tab-switcher-tab {
	padding: 14px 20px;
	z-index: 1;
	font-weight: 500;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.tab-switcher-switch {
	position: absolute;
	background-color: var(--bg-light);
	z-index: 1;
	top: -2px;
	border: 1.5px solid var(--text-light-4);
	border-radius: 12px;
	height: calc(100% + 3px);
	transition: 0.2s ease-in-out;
}
.tab-switcher-tab-loading {
	padding-top: 0px !important;
	padding-bottom: 0px !important;
}
/* curved tab switcher */
.tab-switcher-container-curved .tab-switcher-background {
	border-radius: 100px;
	margin-top: 0px;
	margin-bottom: 20px;
}
.tab-switcher-container-curved .tab-switcher-switch {
	border-radius: 100px;
}
/* dark tab switcher */
.tab-switcher-container-dark .tab-switcher-background {
	background: linear-gradient(
		180deg,
		rgba(38, 43, 54, 0.64) 0%,
		#262b36 100%
	);
	border: 0px;
	box-shadow: inset 0px 0.5px 1px rgba(0, 0, 0, 0.25);
	color: #fff;
}
.tab-switcher-container-dark .tab-switcher-switch {
	border: 0px;
}
.tab-switcher-container-dark .tab-switcher-tab-active {
	color: var(--text);
}
/* small tab switcher */
.tab-switcher-container-small .tab-switcher-tab {
	padding: 7px 14px;
}
.tab-switcher-container-small .tab-switcher-background {
	margin-bottom: 0px;
	border: none;
}

.tab-switcher-container-small .tab-switcher-tab {
	color: var(--text-light);
}

.tab-switcher-container-small .tab-switcher-switch {
	border: 1px solid var(--text-light-5);
}

.facebook-auth-btn {
	background-color: var(--facebook-theme) !important;
	color: #fff !important;
	padding: 20px 20px !important;
	width: 250px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.1rem;
}

.facebook-auth-btn:disabled {
	opacity: 0.5;
}

.facebook-auth-btn img {
	width: 26px !important;
	height: 26px !important;
	margin-right: 20px;
}

.core-table {
	position: relative;
	width: 97%;
	border-collapse: collapse;
	margin: auto;
	margin-top: 20px;
	background-color: #fff;
	overflow: hidden;
	border-radius: 10px;
	padding: 10px;
	text-align: center;
}
.core-table > thead > tr {
	height: 60px;
	font-size: 0.85rem;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.core-table > thead > tr > th {
	font-weight: 600 !important;
	color: var(--blue);
	border-bottom: 1px solid var(--blue);
}

.core-table > tbody > tr > td {
	padding: 10px;
}
.react-daterange-picker__wrapper,
.react-date-picker__wrapper {
	background-color: var(--bg-dark);
	padding: 5px;
	border-radius: 12px;
	border: 1.5px solid var(--text-light-4);
	transition: border-color 0.2s;
}

.widget-module-badge {
	padding: 3px 7px 2px 7px;
	text-transform: uppercase;
	border: 1px solid var(--text-light-3);
	color: var(--text-light-3);
	font-weight: 600;
	font-size: 0.8rem;
	border-radius: 6px;
	white-space: nowrap;
	text-align: center;
	margin: 4px 0px 4px 8px;
	background-color: transparent;
}
.widget-module-badge-inactive {
	opacity: 0.6;
}
.widget-module-badge-rounded {
	border-radius: 100px;
}
.widget-module-badge-filled {
	color: #fff;
	border: 0px;
	background-color: var(--text-light-3);
}
.widget-module-badge-success {
	background-color: var(--dark-green-2);
}
.widget-module-badge-error {
	background-color: var(--red);
}
.widget-module-badge-draft {
	background-color: var(--dark-yellow);
	color: #fff;
}
.widget-module-badge-theme {
	background-color: var(--theme-dark);
}

.checkbox-list-input {
	margin-top: 20px;
}

.checkbox-list-input-row {
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	padding: 6px 10px;
	margin-top: 6px;
	box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.08);
	border-radius: 7px;
}

.checkbox-list-input-row .checkbox {
	margin-right: 10px;
}

.mini-badge {
	background-color: var(--bg-light);
	border: 0.5px solid rgba(0, 0, 0, 0.1);
	width: fit-content;
	height: fit-content;
	padding: 4px 8px;
	margin: 4px 10px 5px 0px;
	border-radius: 6px;
	box-shadow: 0px 1px 2px rgba(3, 25, 38, 0.05),
		0px 0px 19.7232px rgba(3, 25, 38, 0.05);
	cursor: pointer;
	display: flex;
}
.mini-badge-small {
	padding: 2px 6px;
}
.mini-badge-theme {
	color: var(--theme);
}

.mini-badge-gradient {
	color: var(--bg-light);
	background: var(--btn-gradient);
}

.mini-badge-disco-gradient {
	background: var(--btn-disco-gradient);
	color: var(--bg-light);
}

.mini-badge-red {
	color: var(--red);
}
.mini-badge-yellow {
	color: var(--dark-yellow-2);
	border-color: var(--dark-yellow-2);
}
.mini-badge-icon {
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 12px;
	width: 12px;
	margin-right: 4px;
	margin-top: 2px;
}

.mini-badge-gradient .mini-badge-icon {
	color: var(--bg-light) !important;
	background-color: rgba(255, 255, 255, 0.25) !important;
}

.mini-badge-icon-add {
	background-color: rgba(87, 146, 255, 0.3);
}

.mini-badge-icon-minus {
	background-color: rgba(255, 79, 103, 0.3);
}
.mini-badge-icon-cross,
.mini-badge-icon-add-after {
	background-color: rgba(126, 133, 142, 0.3);
	color: var(--text-light);
	margin-right: 0px;
	margin-left: 6px;
}
.progress-bar {
	overflow: auto;
	display: flex;
	margin-top: 10px;
}

.progress-bar-wide {
	width: 100%;
}

.progress-bar-title {
	background-color: var(--theme-dark);
	color: #fff;
	display: flex;
	align-items: center;
	padding: 0px 15px;
	font-size: 1.1rem;
	font-weight: 500;
	margin-right: -5px;
	z-index: 2;
}

.progress-bar-title-dark {
	background-color: var(--theme-dark-3);
}

.progress-bar-element {
	flex-grow: 1;
	background-color: var(--bg-dark);
	width: fit-content;
	height: 38px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-left: 20px;
	padding-right: 10px;
	margin: 0px 1px;
	font-size: 0.98rem;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.progress-bar-wide .progress-bar-element {
	font-size: 1.1rem;
}

.progress-bar-element-initial {
	border-radius: 8px 0px 0px 8px;
}
.progress-bar-element-initial:before {
	border-left: 0px;
}

.progress-bar-element-final {
	border-radius: 0px 8px 8px 0px;
}
.progress-bar-element-final:after {
	border-left: 0px;
}
.progress-bar-element-active {
	background-color: var(--theme);
	color: #fff;
}
.progress-bar-element-active:after {
	border-left-color: var(--theme);
}
.progress-bar-rounded .progress-bar-title {
	border-radius: 30px 4px 4px 30px;
}
.progress-bar-rounded .progress-bar-element:last-of-type {
	padding-right: 20px;
	border-radius: 0px 30px 30px 0px;
}

.progress-bar-element-done-icon {
	font-size: 1.4rem;
	margin-right: 5px;
	position: relative;
	top: 2px;
	color: var(--green-2);
}

.translucent-btn {
	--translucent-btn-bg: rgba(11, 105, 255, 0.1);
	background: var(--translucent-btn-bg);
	color: var(--theme-dark);
	border-radius: 100px;
	font-weight: 400;
	border: 0.5px solid #ffffff;
}
.translucent-btn-light {
	color: var(--text-light-3);
	--translucent-btn-bg: rgba(222, 227, 235, 0.24);
}
.translucent-btn-white {
	--translucent-btn-bg: var(--bg-light);
	color: var(--text-light-3);
}

.filled-btn {
	padding: 10px 14px;
	border-radius: 8px;
	/* background: var(--filled-btn-grad); */
	background: var(--btn-gradient);
	box-shadow: 0px 2px 2px rgba(111, 150, 227, 0.12);
	border: 0px;
	width: fit-content;
	color: #fff;
	outline: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}
.filled-btn-icon {
	display: flex;
	margin-left: 8px;
}
.filled-btn-icon-left {
	margin-right: 5px;
	margin-left: 0px;
}
.filled-btn-light {
	background: #efeff3;
	color: var(--text-light-3);
}

.filled-btn-green {
	background: var(--green-2) !important;
}

.new-review-tooltip-activator {
	background-color: var(--disco-yellow-mid);
	border-radius: var(--disco-curve-sm);
	color: var(--disco-text-dark);
}
.review-indicator {
	align-items: flex-start;
	position: absolute;
	justify-content: center;
	display: inline-flex;
	align-items: center;
	text-transform: initial;
	font-weight: 400;
	font-size: 1rem;
	background-color: var(--yellow);
	color: rgb(0, 0, 0, 0.7);
	border-radius: 6px;
	margin-left: 10px;
	top: -7px;
	z-index: 10;
	cursor: help;
}
.review-indicator-with-secondary {
	top: -4px;
	margin-left: 16px;
}

.review-indicator span {
	display: flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	font-size: 1.45rem;
	height: 18px;
	width: 18px;
	margin: 4px;
	margin-top: 5px;
	position: relative;
}

.review-indicator-content {
	padding: 10px;
	overflow: hidden;
	margin-left: -10px;
	min-width: 250px;
}

.review-indicator-content div {
	display: inline !important;
	text-transform: lowercase;
}

/* .review-indicator-large-open { */
/* border-radius: 12px; */
/* } */
.review-indicator-large .review-indicator-content {
	margin-left: -4px;
}
.review-indicator-large .review-indicator-content > h3 {
	margin-bottom: 4px;
}
.review-indicator-large .review-indicator-content > div {
	text-transform: none;
}
.review-indicator-content-large {
	padding: 14px 20px;
}
.review-indicator-content-large {
	color: var(--text);
	text-transform: none;
}
.review-indicator-content-large > h3 {
	margin-bottom: 6px;
}
.review-indicator-content-large > div {
	color: var(--text-light);
}

.inline-helper-tooltip {
	padding: 20px;
	border: 2px solid var(--dark-yellow-2);
	margin-top: 20px;
	background-color: var(--yellow-2);
}
.inline-helper-tooltip-hide-border {
	border: 0px !important;
}
.inline-helper-tooltip-rounded {
	border-radius: 10px;
}
.inline-helper-tooltip-success {
	border-color: var(--dark-green);
	background-color: rgba(27, 214, 173, 0.3);
}
.inline-helper-tooltip-red {
	background-color: #ffe8e8;
	border-color: var(--red);
}
.inline-helper-tooltip-light-red {
	background: #fff3f6;
	border-color: #f7ced1;
}
.inline-helper-tooltip > h2 {
	font-size: 1.1rem;
	font-weight: 600;
	margin-bottom: 15px;
}
.inline-helper-tooltip-children {
	color: var(--text-light-3);
	font-size: 1.1rem;
	line-height: 19px;
}
.inline-helper-tooltip-red .inline-helper-tooltip-children {
	color: var(--text);
}
.inline-helper-tooltip-success .inline-helper-tooltip-children {
	color: rgba(0, 0, 0, 0.6);
}
.inline-product-tile {
	width: 100%;
	height: 80px;
	display: flex;
	margin-bottom: 20px;
	padding: 10px;
	padding-left: 12px;
	border-radius: 12px;
	background-color: var(--bg-light);
	align-items: center;
}
.inline-product-tile-img {
	width: 60px;
	height: 60px;
	object-fit: cover;
	border-radius: 12px;
}
.inline-product-tile-details {
	margin-left: 20px;
	height: 80px;
	display: flex;
	align-items: center;
}
.completion-bar {
	--completion-bar-progress-bg: var(--theme);
	height: 10px;
	min-width: 100px;
	border-radius: 100px;
	width: auto;
	overflow: hidden;
	background-color: rgba(250, 250, 250, 0.6);
}
.completion-bar-progress {
	height: 100%;
	background: var(--completion-bar-progress-bg);
	border-radius: 100px;
}
.vertical-seperator {
	width: 2px;
	height: 260px;
	background-color: rgba(0, 0, 0, 0.1);
}
.dot-switcher-container {
	display: flex;
	justify-content: center;
}
.dot {
	height: 8px;
	cursor: pointer;
	width: 8px;
	border-radius: 10px;
	background-color: var(--bg-light);
	transition: opacity 0.12s ease-out;
}

.dot-switcher-dot {
	opacity: 0.33;
	margin: 0px 6px;
}

.dot-switcher-dot:hover {
	opacity: 0.6;
}
.dot-switcher-container-vertical {
	flex-direction: column;
}
.dot-switcher-container-vertical .dot-switcher-dot {
	margin: 6px 0px;
}
.dot-switcher-dot-active {
	opacity: 1;
}

.slider {
	height: 6px;
	background-color: var(--bg-dark-2);
	border-radius: 3px;
	margin: 20px auto;
}
.slider-track-0 {
	background-color: var(--theme-dark);
	height: inherit;
	border-radius: 3px;
}
.slider-thumb {
	outline: none;
	width: 38px;
	border-radius: 15px;
	cursor: pointer;
	background-color: var(--theme-dark);
	height: 32px;
	margin-top: -13px;
	display: flex;
	align-items: center;
	color: #fff;
	justify-content: center;
	font-size: 2.5rem;
}
.slider-thumb span {
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.slider-thumb span:nth-of-type(1) {
	margin-right: -10px;
}
.copy-paste-block {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.copy-paste-block .inp-container {
	width: 100% !important;
}

.copy-paste-block .inp-container .inp {
	min-height: 300px;
	width: 100%;
}

.copy-paste-block-action {
	margin-top: 30px;
	display: flex;
}

.copy-paste-block-action .filled-btn {
	margin: 0px 5px;
}

.titled-card {
	background-color: var(--bg-dark);
	border-radius: 8px;
	overflow: hidden;
	margin: 20px 0 15px 0 !important;
}

.titled-card-light {
	background-color: var(--bg);
	border: 1px solid var(--text-light-5);
}

.titled-card header {
	padding: 12px 15px;
	background-color: var(--bg-dark-2);
	font-size: 1.1rem;
	font-weight: 500;
}

.titled-card-light header {
	background-color: var(--bg-light);
}

.titled-card header span {
	color: var(--theme);
	margin-right: 7px;
	font-size: 1.3rem;
	position: relative;
	top: 1px;
	display: inline-block;
	vertical-align: middle;
}

.titled-card-light header > span {
	color: var(--pink);
}

.titled-card > main {
	padding: 15px;
}

.titled-card-light > main {
	font-size: 1.15rem;
	font-weight: 300;
	line-height: 20px;
	padding: 10px;
}

.generic-product-card {
	background-color: var(--bg);
	width: 240px;
	margin: 5px;
	padding: 10px;
	padding-bottom: 15px;
	border-radius: 20px;
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.generic-product-card > img {
	width: 100%;
	border-radius: 15px;
}
.generic-product-card > h3 {
	margin: 10px 5px;
	font-weight: 500;
}

.column-view {
	width: 98%;
}
.column-view .page-header .tabs {
	padding-bottom: 3px;
}
.column-view-content {
	--main-width: 50%;
	--gutter-width: 40px;
	--main-padding: 20px;
	width: 100%;
	height: auto;
	display: grid;
	grid-template-columns: calc(var(--main-width) - var(--main-padding)) calc(
			100% - var(--main-width) - var(--gutter-width) + var(--main-padding)
		);
	column-gap: 40px;
	margin-top: 30px;
	padding-bottom: 20px;
	position: relative;
}
.column-card {
	box-shadow: 0px 5px 30px rgba(3, 25, 38, 0.15);
	border-radius: 12px;
	background-color: var(--bg-light);
	padding: 40px 30px;
	height: fit-content;
	display: flex;
	justify-content: space-between;
}
.column-card-split > section {
	width: 49%;
}
.column-card-tagline,
.column-card-description {
	font-size: 2.3rem;
	color: #666c75;
	font-weight: normal;
	line-height: 40px;
}
.column-card-tagline {
	margin-bottom: 12px;
}
.column-card-heading {
	font-weight: 800;
	font-size: 4rem;
	line-height: 56px;
	margin-bottom: 12px;
}
.column-card-description {
	font-size: 2rem;
}

.column-card-children {
	margin-top: 10px;
}
.column-view-action {
	margin-top: 30px;
	display: flex;
}
.column-view-action .filled-btn {
	margin-right: 12px;
}

.column-view-aside {
	border-radius: 12px;
	border: 1px solid var(--text-light-5);
	padding: 16px;
	overflow: hidden;
	position: relative;
}

.column-view-aside-content {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 12px;
	overflow: hidden;
}

.column-view-aside-banner {
	position: absolute;
	height: 34px;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 1;
	/* background-image: var(--purple-gradient); */
	background: linear-gradient(
			0deg,
			rgba(143, 38, 238, 0.7),
			rgba(143, 38, 238, 0.7)
		),
		linear-gradient(269.45deg, #8f26ee 10.43%, #ff0090 145.5%);
	border: 0.5px solid rgba(1, 2, 4, 0.24);
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.12);
	color: var(--bg-light);
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.column-view-aside-banner-theme {
	background: var(--theme);
}

.column-view-aside-banner-icon {
	font-size: 1.2rem;
	position: relative;
	top: 2px;
	margin-right: 3px;
}

.column-view-aside-banner > span {
	opacity: 0.8;
	margin: 0 3px;
}

.csv-generator-container {
	margin: auto;
	padding: 10px 20px;
	margin-bottom: 20px;
	display: flex;
	position: relative;
	border-radius: 12px;
	width: 96%;
	justify-content: flex-end;
	background-color: var(--bg);
}

.csv-generator-container h3 {
	font-weight: 600;
	line-height: 40px;
	margin-right: auto;
}

.gradient-card {
	position: relative;
	border-radius: 16px;
	padding: 30px;
}

.gradient-card::after {
	content: "";
	position: absolute;
	height: calc(100% - 10px);
	width: calc(100% - 10px);
	background: var(--card-theme-gradient);
	border-radius: 12px;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	z-index: 1;
}
.gradient-card-red::after {
	background: var(--card-red-gradient);
}

.gradient-card-heading {
	font-size: 2.3rem;
	letter-spacing: -1px;
}

.gradient-card > * {
	position: relative;
	z-index: 2;
}

.gradient-card-subheading {
	color: rgba(0, 0, 0, 0.5);
	margin-top: 8px;
	font-size: 1.4rem;
	font-weight: 500;
	margin-bottom: 25px;
}

.gradient-card-subheading b {
	color: var(--text);
	font-weight: 600;
}

.square-img-tile {
	position: relative;
	border-radius: 10px;
	overflow: hidden;
	border: 0.5px solid rgba(0, 0, 0, 0.12);
}

.square-img-tile-circular {
	border-radius: 50%;
}

.square-img-tile-border-less {
	border: none;
}
.square-img-tile-sharp {
	border-radius: 0;
}

.square-img-tile::after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.square-img-tile > img {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center;
}

.callout-banner {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	height: 110px;
	background-color: var(--bg-light);
	box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.08);
	border-radius: 10px;
	padding: 0;
}

.callout-banner-hero {
	padding: 15px;
}

.callout-banner-hero h3 {
	font-size: 1.6rem;
}

.callout-banner-hero-content {
	margin-top: 10px;
	font-size: 1.2rem;
	color: var(--text-light-3);
}

.callout-banner-hero-content a {
	color: var(--theme-2);
	font-weight: 600;
}

.callout-banner img {
	flex: 0 0 auto;
	height: 100%;
	margin-right: 10px;
}
.action-message {
	display: flex;
	align-items: center;
	font-size: 1.2rem;
	font-weight: 400;
	padding: 16px;
	border-radius: 10px;
	color: var(--text-light);
	background-color: var(--bg-light);
	border: 1px solid var(--text-light-5);
}

.action-message-theme {
	background-color: var(--theme-light);
	border-color: var(--theme);
}

.action-message-text {
	width: 100%;
}

.action-message-action {
	display: flex;
	flex: 0 0 auto;
	align-items: center;
	margin-left: 10px;
}

.action-message-action .btn,
.action-message-action .disco-btn {
	margin: 0 4px;
	box-shadow: none;
}

.large-heading {
	font-size: 3.7rem;
	font-weight: 900;
	letter-spacing: -1.5px;
	color: var(--text);
	margin-bottom: 15px;
}
.large-text {
	margin-bottom: 12px;
	font-size: 1.8rem;
	color: var(--text-light-3);
	line-height: 32px;
	margin-top: 12px;
	font-weight: 300;
}

.large-text-compact {
	font-size: 1.9rem;
}

.large-text-x-compact {
	font-size: 1.7rem;
	line-height: 28px;
}

.large-text > b {
	font-weight: 500;
	color: var(--text);
}

.features-list {
	margin: var(--disco-spacing-sm) 0 var(--disco-spacing-lg) 0;
}

.features-list-feature {
	display: flex;
	align-items: center;
	font-weight: 400;
	font-size: var(--disco-fs-subtitle);
	margin-top: var(--disco-spacing-rg);
	color: var(--disco-text-dark);
}

.features-list-bold .features-list-feature,
.features-list-feature b {
	font-weight: 600;
	color: var(--disco-text-default);
}

.features-list-feature img {
	height: 32px;
	margin: 0 var(--disco-spacing-rg);
}

.features-list-small .features-list-feature {
	font-size: var(--disco-fs-lg-body);
	margin-top: var(--disco-spacing-sm);
}

.features-list-small .features-list-feature img {
	height: 24px;
	margin: 0 var(--disco-spacing-tn);
}

.bordered-card {
	position: relative;
	z-index: 0 !important;
	border-radius: 20px;
	background-color: var(--bg);
	position: relative;
	box-shadow: 0px 40px 40px 6px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
}

.bordered-card-centered > main,
.bordered-card-centered .bordered-card-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.bordered-card-split > main {
	display: flex;
	padding: 0 !important;
	justify-content: stretch;
	align-items: stretch;
	flex-direction: row;
	width: 100%;
}

.bordered-card-aside {
	background: var(--disco-gradient);
	width: 35%;
	flex: 0 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 20px 0 0 0;
}

.bordered-card-aside > img:first-of-type {
	margin-bottom: 40px;
}

.bordered-card-split .bordered-card-body {
	padding: 30px;
}

.bordered-card-split .bordered-card-heading {
	width: 100%;
	text-align: left;
	font-size: 2rem;
	letter-spacing: -1px;
	font-weight: 700;
	margin-bottom: 20px;
}

.bordered-card-split .large-text {
	width: 100%;
	text-align: left !important;
	font-size: 1.4rem;
	line-height: 28px;
}

.bordered-card-split .bordered-card-sub-heading {
	margin-bottom: 30px;
}

.bordered-card-split .features-list-feature {
	margin-top: 25px !important;
	line-height: 27px;
	color: var(--text-light-3);
}

.bordered-card-narrow:nth-of-type(1) {
	margin-top: 0;
}

.bordered-card-narrow {
	width: 65%;
}

.bordered-card .btn {
	flex: 0 0 auto;
}

.bordered-card-logo {
	display: block;
	margin: 18px auto;
	height: 36px;
}

.bordered-card::after {
	position: absolute;
	content: "";
	z-index: -1;
	background-color: rgba(255, 255, 255, 0.2);
	height: calc(100% + 16px);
	width: calc(100% + 16px);
	top: -8px;
	left: -8px;
	border-radius: 25px;
}

.bordered-card-body {
	width: 100%;
	height: 100%;
}

.bordered-card > main {
	height: 100%;
}

.bordered-card .large-text {
	text-align: center;
}

.bordered-card > main,
.bordered-card > footer {
	padding: 30px;
	border-radius: 0 0 20px 20px;
}

.bordered-card > footer {
	flex: 0 0 auto;
	padding: 15px 25px;
	background-color: var(--bg-light);
	border-top: 1px solid var(--text-light-5);
	display: flex;
	align-items: center;
}

.bordered-card-heading {
	margin-bottom: 10px;
	text-align: center;
}
.gradient-bordered-card-container {
	--border-gradient: linear-gradient(to right, #5792ff, #ff00ff);
	border: 2px solid transparent;
	border-radius: 16px;
	box-shadow: 0px 0px 0px 8px rgba(255, 255, 255, 0.66);
	background-image: var(--border-gradient);
	background-origin: border-box;
	background-clip: border-box, border-box;
}
.gradient-bordered-card {
	border-radius: 14px;
	display: flex;
	flex-direction: column;
	background-color: var(--bg-light);
}

.privacy-policy-card {
	background-color: var(--bg-dark);
	border-radius: 12px;
	padding: 40px 32px;
	width: 100%;
	height: fit-content;
	line-height: 24px;
	font-size: 1.12rem;
}

.privacy-policy-card-action {
	background-color: var(--bg-light);
	border-radius: 12px;
	margin-top: 14px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
	border: 0.1px solid var(--text-light-5);
	box-shadow: 0px 1px 2px 1px rgb(0 0 0 / 6%);
	transition: opacity 0.2s, border-color 0.2s;
}
.privacy-policy-card-action:hover {
	border-color: var(--theme);
}
.privacy-policy-card-action-text {
	font-size: 1.2rem;
}
.privacy-policy-card-action .filled-btn {
	height: 40px;
	min-width: 150px;
	margin-left: 10px;
}
.privacy-policy-card textarea {
	line-height: 24px;
	font-size: 1.12rem;
	font-style: italic;
	height: 430px !important;
	max-height: none;
	width: 100%;
}

.h-form-fields {
	display: grid;
	grid-template-columns: 100px 1fr;
	align-items: center;
	column-gap: 20px;
	row-gap: 18px;
	margin: 10px 0 10px 0;
}

.h-form-fields .select {
	text-align: left;
	font-size: 1.16rem;
	font-weight: 400;
}

.h-form-field-info {
	text-align: left;
	margin-top: -12px;
	font-size: 1rem;
	color: var(--red-dark);
	font-weight: 300;
}
.h-form-field-info-rules {
	color: var(--text-light-2);
}

.h-form-field-info-rule {
	margin-top: 3px;
	transition: color 0.2s;
}

.h-form-field-info-rule-done {
	color: var(--green-2);
}

.h-form-field-info-rule .dot {
	background-color: var(--bg-dark-2);
	margin-right: 5px;
	position: relative;
	top: -1px;
	transition: background-color 0.2s;
}

.h-form-field-info-rule-done .dot {
	background-color: var(--green-2);
}

.h-form-field-error {
	border: 1px solid var(--red-dark);
}

.h-form-fields .select-selected {
	padding: 0 20px;
}

.h-form-fields .h-form-select-additional-input {
	margin-top: 20px !important;
}

.h-form-label {
	text-align: right;
	color: var(--text-light-3);
}

.h-form .inp {
	margin: 0;
}

.h-form .radio-inp-container {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 5px;
	row-gap: 20px;
	margin-top: 15px;
}

.h-form .radio-inp-option {
	margin: 0;
	text-align-last: left;
}

.mobile-radio-inp-container,
.mobile-radio-inp {
	width: 100%;
}

.mobile-radio-inp-icon {
	position: absolute;
	display: block;
	top: 8px;
	right: 10px;
	font-size: 1.5rem;
	color: var(--text-light-4);
	padding: 4px;
	z-index: 2;
	display: flex;
	align-content: center;
	justify-content: center;
	border-radius: 50%;
	transition: background-color 0.2s;
}

.mobile-inp-modal {
	background-color: var(--bg-light-2);
}

.mobile-radio-inp-button {
	width: 100%;
	margin-top: 31px;
}

.mobile-header {
	width: 100%;
	padding: 18px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	background-color: var(--bg-light-2);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	z-index: 100;
}

.mobile-header-left {
	position: absolute;
	left: 24px;
}

.mobile-header-right {
	position: absolute;
	right: 24px;
}

.mobile-header-burger {
	margin-top: 4px;
	font-size: 1.5rem;
	color: #a4a4a4;
}

.mobile-header-logo {
	height: 22px;
}

.mobile-nav-header {
	width: 100%;
	padding: 18px;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	background-color: var(--bg-light-2);
	display: flex;
	flex-direction: row;
	position: absolute;
	left: 0;
	top: 0;
	align-items: center;
	z-index: 100;
}

.mobile-nav-header-left {
	margin-right: 10px;
	padding: 7px;
}

.mobile-nav-header-back {
	margin-top: 3px;
	font-size: 2rem;
	color: #a4a4a4;
}

.mobile-nav-header-logo {
	height: 38px;
	border-radius: 50%;
	margin-right: 4px;
}

.mobile-nav-header-title {
	font-size: 1.66rem;
	font-weight: 800;
	color: var(--text);
}

.mobile-nav-header-special {
	width: 100%;
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	position: relative;
	z-index: 100;
}

.mobile-nav-header-special-background {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--bg-light-2);
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	opacity: 0.2;
}

.mobile-nav-header-special-content {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.mobile-nav-header-special-left {
	margin-right: 6px;
	position: relative;
}

.mobile-nav-header-special-back {
	margin-top: 7px;
	font-size: 2rem;
	color: #a4a4a4;
}

.mobile-nav-header-special-logo-container {
	width: 38px;
	height: 38px;
	border-radius: 20px;
	background-color: #93a1ff;
	margin-right: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mobile-nav-header-special-logo {
	height: 19px;
}

.mobile-nav-header-special-texts {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	min-height: 100%;
}

.mobile-nav-header-special-title {
	font-size: 1.5rem;
	font-weight: 800;
	color: var(--text);
}

.mobile-nav-header-special-subtitle {
	font-size: 1.16rem;
	font-weight: 500;
	color: var(--text-light-2);
	margin-top: 2px;
}

.mobile-nav-header-special-description {
	margin-top: 14px;
	font-size: 1.33rem;
	line-height: 1.66rem;
	color: var(--text-light-2);
}

.sidebar-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: var(--dark-bg);
	z-index: 11;
}

.mobile-coming-soon-modal {
	padding: 0;
	max-width: 100%;
}

.mobile-coming-soon-modal-hero {
	padding: 32px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	background: var(--onboarding-gradient);
}

.mobile-coming-soon-modal-img {
	width: 200px;
}

.mobile-coming-soon-modal-close {
	position: absolute;
	left: 24px;
	top: 20px;
	font-size: 1.16rem;
	color: #ffffff;
}

.mobile-coming-soon-modal-content {
	padding: 20px 27px 40px;
	font-size: 1.33rem;
	line-height: 1.86rem;
	background-color: var(--bg-light);
}

.mobile-coming-soon-modal-content h3 {
	margin-bottom: 8px;
	color: var(--text);
}

.mobile-coming-soon-modal-content span {
	color: #6f6c72;
}

.mobile-coming-soon-modal-footer {
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.mobile-coming-soon-modal-footer .btn {
	width: 100%;
}

.search-inp-container {
	position: relative;
	margin: 0 24px;
}

.search-inp-container .inp {
	width: 100%;
	border-radius: 100px;
	background-color: rgba(126, 133, 142, 0.1);
	text-align: center;
	border: none;
}

.search-inp-container-placeholder {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 42px;
	border-radius: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.16rem;
	color: rgba(126, 133, 142, 0.6);
}

.search-inp-icon {
	margin-right: 10px;
}

.rectangle {
	height: 12px;
	/* background-color: var(--text-light-3); */
	background-color: var(--text-light-4);
}

.rectangle-red {
	background-color: var(--red-dark);
}

.rectangle-yellow {
	background-color: var(--mustard);
}

.rectangle-green {
	background-color: var(--dark-green);
}

.brand-logos {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 0 25px;
	margin: 5px 0 20px 0;
	background-color: var(--bg);
	border: 1px solid var(--text-light-5);
	border-radius: 12px;
	height: 80px;
}

.brand-logo {
	width: 3.2vw;
	text-align: center;
	font-size: 1.4rem;
	color: var(--text-light-3);
}

.brand-logo-true-botanicals,
.brand-logo-lovevery {
	width: 6vw;
}

@media screen and (max-width: 600px) {
	.alert-bar {
		display: none !important;
	}

	.btn-large {
		height: 46px;
		font-size: 1.16rem;
	}

	.btn-gradient {
		border: 1px solid #0b69ff;
	}

	.features-list {
		margin-bottom: 0;
	}

	.features-list-feature {
		font-size: 1.5rem;
		letter-spacing: -0.26px;
		text-align: left;
	}

	.features-list-feature img {
		height: 23px;
		margin: 0;
		margin-right: 10px;
	}

	.page-content {
		margin: 0;
		width: 100%;
		height: 100vh;
		padding: 0;
		background-color: var(--bg-light-2);
		border-radius: 0;
		left: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		flex-wrap: nowrap;
		min-height: 100vh;
		overflow-y: hidden;
	}

	.auth-page-mobile-content {
		flex: 1;
		width: 100%;
		padding: 16px 0;
		display: flex;
		flex-direction: column;
	}

	.gradient-bordered-card-container {
		box-shadow: none;
	}

	.tab-switcher-tab {
		white-space: nowrap;
	}
}
