.modal-overlay {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 25;
}

.modal {
	position: relative;
	max-width: 600px;
	width: 90%;
	border-radius: 15px;
	padding: 0 20px 20px 20px;
	background-color: var(--bg-dark-2);
	overflow: auto;
}
.modal-side {
	width: 400px;
	margin-left: 20px;
	max-height: 70%;
	border-radius: 15px;
	overflow: auto;
}
.modal-is-custom {
	padding: 0px;
	max-height: none;
	padding-right: 0px;
}
/* For modal with preview */
.modal-extended {
	max-width: 90%;
	max-height: 85%;
}

.modal-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 14px;
}

.modal-header h2 {
	font-weight: 600;
	font-size: 1.08rem;
	letter-spacing: 1px;
	text-transform: uppercase;
	color: var(--text-light-3);
	white-space: nowrap;
	margin-right: 15px;
}

.modal-header-btn {
	background-color: transparent;
	border: none;
	font-size: 2rem;
	height: 36px;
	width: 36px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	transition: color 0.2s;
	color: var(--text-light-4);
	margin-left: 10px;
	cursor: pointer;
	outline: none;
}

.modal-header-btn:hover {
	color: var(--text-light-3);
}

.modal-content {
	width: 100%;
	margin-top: 12px;
}

/* For splitting modal body */
.modal-content-split {
	display: flex;
	flex-direction: row;
}

.modal-group {
	width: 100%;
	padding: 15px;
	border-radius: 10px;
	background-color: var(--bg);
	/* margin: 10px 0; */
	max-height: 250px;
	overflow: auto;
}

/* split modal-groups inside the body */
.modal-group-split {
	margin: 10px 5px;
	width: calc(100% - 450px);
}

.modal-group-split-preview {
	width: 430px;
}

.modal-group h2 {
	/* text-transform: uppercase; */
	font-size: 1.2rem;
	color: var(--text-light);
	letter-spacing: 1px;
	font-weight: 700;
	margin-bottom: 16px;
}

.modal-group h3 {
	font-size: 1.2rem;
	color: var(--text-light);
	opacity: 0.8;
	letter-spacing: 1px;
	font-weight: 700;
	margin: 10px;
	margin-top: 20px;
	width: 92%;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

/* Preview group overide */
.modal-preview-group {
	height: 550px;
	max-height: 70%;
	/* height: 500px; */
	/* max-height: 500px; */
	overflow: auto;
}

.modal-preview-empty {
	color: var(--text-light);
	opacity: 0.7;
	height: 90%;
	padding: 40px;
	text-align: center;
	font-weight: 600;
	font-size: 1.1rem;
	display: flex;
	align-items: center;
}

.modal-preview-body {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
}

.modal-preview-body h1 {
	font-size: 1.6rem;
	text-transform: capitalize;
}

.modal-preview-link {
	text-decoration: none;
	transition: 0.15s ease-out;
}

.modal-preview-link:hover {
	color: var(--theme);
}

.modal-preview-body-text {
	margin-top: 10px;
}

.modal-preview-body-split {
	width: 50%;
	padding: 5px;
	display: flex;
	justify-content: space-evenly;
	flex-direction: column;
}

.modal-preview-body-split-center {
	align-items: center;
}

.modal-preview-image {
	width: 80%;
	object-fit: contain;
	border-radius: 10px;
}

.modal-preview-cta {
	margin-top: 20px;
}

.modal-preview-footer {
	background-color: var(--bg-dark);
	border-radius: 10px;
	padding: 15px 10px;
	margin-top: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
	overflow: auto;
}

.model-preview-footer-expandable {
	flex-wrap: wrap;
}

.modal-preview-footer-item-container {
	position: relative;
	min-width: 120px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 10px;
	overflow: hidden;
	margin-right: 8px;
}

.modal-preview-footer-item-container:hover .modal-preview-footer-item-overlay {
	transform: scale(1);
	opacity: 1;
}

.modal-preview-footer-item-image {
	width: 120px;
	height: 120px;
	object-fit: contain;
}

.modal-preview-footer-item-overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	background-color: rgba(255, 255, 255, 0.8);
	transition: 0.15s ease-in-out;
	transform: scale(0.5);
	opacity: 0;
	text-align: center;
	padding: 5px 8px;
	overflow: auto;
}

.modal-preview-footer-item-price {
	font-weight: 700;
	margin-top: 5px;
	font-size: 1.3rem;
	color: var(--text-light);
}

.modal-preview-footer-empty {
	text-align: center;
	margin: auto;
	color: var(--text-light);
}

.modal-action {
	margin: auto;
	margin-top: 25px;
}

.modal-action-loader {
	margin: auto;
	margin-top: 14px;
}

.modal-search-inp {
	width: 100% !important;

	min-width: 200px !important;
}
.modal-inline-overlay {
	background-color: transparent !important;
}

.modal-inline {
	border: 1px solid var(--text-light-5);
}

.modal-sheet {
	width: 100%;
	position: absolute;
	bottom: 0;
	border-radius: 24px 24px 0 0;
}
