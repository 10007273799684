.image-picker {
	--image-size: 64px;
	--image-gap: var(--disco-spacing-sm);
	width: 100%;
	max-width: 50vw;
	display: grid;
	gap: var(--image-gap);
	grid-template-columns: repeat(auto-fill, var(--image-size));
}

.image-picker-active-image {
	margin-right: 0px;
	grid-column: span 2;
	grid-row: span 2;
}

.image-picker-active-image img {
	border-radius: 6px;
	width: calc(var(--image-size) * 2 + var(--image-gap));
	height: calc(var(--image-size) * 2 + var(--image-gap));
	object-fit: cover;
}

.image-picker-image {
	display: flex;
	flex-wrap: wrap;
	width: var(--image-size);
	height: var(--image-size);
	border-radius: 6px;
	overflow: hidden;
	border: 2px solid transparent;
	transition: border-color 0.2s;
	cursor: pointer;
	overflow: visible;
}

.image-picker-image img {
	height: 100%;
	width: 100%;
	object-fit: cover;
	border-radius: 6px;
	pointer-events: none;
}

.image-picker-image:hover > .image-picker-image-overlay {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: #000;
	opacity: 0.3;
}

.image-picker-image-exit-btn {
	position: absolute;
	top: -8px;
	right: -8px;
	height: 20px;
	width: 20px;
	border-radius: 50%;
	color: var(--disco-text-default);
	background-color: var(--disco-text-white);
	display: flex;
	font-size: 1.25rem;
	box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.08);
	cursor: pointer;
}

.image-picker-image-exit-btn.large {
	top: var(--disco-spacing-tn);
	right: var(--disco-spacing-tn);
	height: var(--disco-spacing-md);
	width: var(--disco-spacing-md);
	color: var(--disco-text-white);
	background-color: var(--disco-text-mid);
	z-index: 999;
}

.image-picker-image-active {
	border-color: var(--theme) !important;
}

.image-picker-add {
	display: flex;
	background-color: var(--theme);
	flex-direction: column;
	color: var(--disco-bg-light);
	cursor: pointer;
}

.image-picker-add.secondary-add-btn {
	border: 1px dashed var(--text-stroke-stroke, rgba(1, 2, 4, 0.1));
	background-color: transparent;
}

.image-picker-add.secondary-add-btn:hover {
	background-color: var(--disco-bg-mid);
}

.image-picker-add.disabled-add-btn {
	opacity: 0.5;
	cursor: default;
}

.image-picker-add.disabled-add-btn:hover {
	background-color: transparent;
}

.image-picker-add-icon {
	background-color: var(--disco-bg-light);
	border-radius: 50%;
	display: flex;
	position: relative;
	top: 0;
	left: 0;
	text-align: center;
	height: calc(var(--image-size) * 0.3);
	width: calc(var(--image-size) * 0.3);
	color: var(--theme);
	border-color: var(--disco-bg-light);
}

.image-picker-show-more-btn {
	color: var(--disco-blue-default);
}

.image-picker-modal .disco-square-img-tile {
	max-width: 300px;
	max-height: 300px;
}

@media screen and (max-width: 600px) {
	.image-picker-modal {
		background-color: var(--bg);
		max-height: none;
	}
}
