.audiences-history-table-wrapper {
	border-radius: var(--disco-curve-md);
	background-color: var(--disco-bg-light);
	box-shadow: var(--disco-shadow-sm);
	position: relative;
}

.audiences-history-table-wrapper > .disco-loader {
	margin: auto;
}

.audiences-history-table-wrapper-heading {
	letter-spacing: -1px;
}

.audiences-history-provider {
	display: inline-flex;
	align-items: center;
	border-radius: var(--disco-curve-lg);
	background-color: var(--disco-bg-mid);
}

.audiences-history-provider > img {
	height: 25px;
	flex: 0 0 auto;
	margin-right: 4px;
}

.audiences-history-status {
	cursor: initial;
}

.audiences-history-status-pending {
	background-color: var(--disco-yellow-light);
	color: var(--disco-yellow-mid);
}

.audiences-history-status-sent {
	background-color: var(--disco-green-light);
	color: var(--disco-green-mid);
}

.audiences-history-status-cancelled {
	background-color: var(--disco-red-light);
	color: var(--disco-red-mid);
}
