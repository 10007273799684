.survey-question-modal {
	min-width: 550px !important;
	/* width: 500px !important; */
}
.survey-question-modal .base-form {
	width: 100%;
}

.survey-question-modal .inp-container {
	width: 100%;
}

.survey-question-modal-inp {
	width: 100%;
	margin-bottom: 20px;
	font-size: 1.35rem;
	background-color: transparent;
	font-weight: 600;
	padding: 0px 0px !important;
	border-radius: 0px;
	transition: background-color 0.2s;
	box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.08);
	border: none !important;
	padding: 15px 8px !important;
	border-radius: 7px;
	background-color: var(--bg-light);
}

.survey-question-modal-label {
	margin-top: 6px !important;
	font-size: 1rem;
	text-transform: uppercase;
}

.survey-question-modal-type-container {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.survey-question-modal-type {
	width: 180px;
	height: 180px;
	cursor: pointer;
	background-color: var(--bg-dark);
	padding: 16px;
	border-radius: 10px;
	margin: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.survey-question-modal-type img {
	width: 60%;
}

.survey-question-modal-type h3 {
	text-align: center;
	font-size: 1rem;
	font-weight: 600;
	color: var(--theme);
}

.survey-modal-action {
	display: flex;
	margin-top: 20px;
}

.survey-modal-action > .btn {
	margin: 0 4px;
}
.survey-question-modal-final-component {
	padding: 20px;
	margin-top: 30px;
	background-color: var(--bg-light);
	box-shadow: 0px 10px 20px rgba(41, 43, 52, 0.1), inset 0px -2px 0px #ffffff;
	border-radius: 10px;
	margin-bottom: 30px;
}

.survey-question-modal-final-component-btn {
	margin-left: auto;
}
