.integration-module-header-children {
	flex-grow: 1;
	justify-content: flex-end;
}
.integration-module-remote-id-display {
	width: 400px;
}
.integration-module-remote-id-display .disco-text-inp:disabled {
	opacity: 1;
}
