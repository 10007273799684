.audiences-integration {
	margin-top: 20px;
}

.audiences-integration .inline-toast {
	width: calc(100% + 40px);
	left: -20px;
}

.audiences-integration .accordion-open {
	z-index: 5;
}

.audiences-integration-beta-disabler {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.6);
	border-radius: var(--disco-curve-md);
}

.audiences-integration-beta-disabler .disco-tag {
	color: var(--disco-bg-light);
	background: var(--disco-gradient-purple-blue);
}

.audiences-integration-beta-disabler-tooltip {
	font-size: 1.1rem;
	padding: 20px 15px;
	font-weight: 300;
	top: calc(-150% - 15px);
	position: absolute;
}

.audiences-integration-beta-disabler-tooltip p {
	line-height: 18px;
}

.audiences-integration-beta-disabler-tooltip h3 {
	font-size: 1.2rem;
	margin-bottom: 10px;
	font-weight: 500;
}
.audiences-integration
	.audiences-ad-account
	> .select-container
	.select-options {
	max-height: 155px;
}

.meta-issue-banner {
	--bg: #d7090f;
	--color: #fff;
	color: var(--color);
	font-weight: 500;
	display: flex;
	background: var(--bg);
	align-items: center;
	font-size: 14px;
}
.meta-issue-banner > svg {
	--size: 24px;
	margin-right: 8px;
	width: var(--size);
	color: var(--color);
	height: var(--size);
}
