.rewards-builder-options {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.rewards-builder-option {
	width: 48%;
	border-radius: var(--disco-curve-md);
	background-color: var(--disco-bg-mid);
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	border: var(--disco-stroke-lg) solid transparent;
	transition: border-color 0.2s;
}

.rewards-builder-options-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.rewards-builder-option-selected {
	background-color: var(--theme-light);
	border-color: var(--disco-blue-mid);
}

.rewards-builder-option-name {
	color: var(--disco-blue-mid);
}

.rewards-builder-option-name span {
	margin-top: 0px;
	display: block;
	font-weight: 400;
	font-style: italic;
	margin-top: 5px;
}

.rewards-builder-option img {
	margin-top: 10px;
	height: 80px;
	border-radius: 10px;
}

.rewards-builder-option .tooltip {
	margin: 10px 0 5px 0;
}

.rewards-builder-option .tooltip-content {
	position: absolute;
	margin-top: 50px;
	width: 18vw !important;
}

.rewards-builder-option-state {
	margin: var(--disco-spacing-md) 0 var(--disco-spacing-sm) 0;
}

.rewards-builder-option .checkbox {
	background-color: var(--bg-light);
	position: relative;
}

.rewards-builder-tooltip-preview {
	width: 100%;
	height: auto !important;
}

.reward-builder .disco-card {
	position: relative;
}

.reward-builder .disco-card .disco-card-content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.reward-builder .disco-card .disco-labeled-inp {
	width: 100%;
}
.reward-builder .disco-card .disco-text-inp-container {
	width: 100%;
}

.rewards-builder-sent {
	position: absolute;
	height: 100%;
	width: 100%;
	background-color: rgba(255, 255, 255, 0.6);
	backdrop-filter: blur(5px);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.rewards-builder-sent h3 {
	width: 80%;
}
.rewards-builder-email-preview {
	width: 320px;
	height: initial;

	max-height: initial;
	height: auto;
	display: flex;
	align-items: center;
	padding: 10px !important;
}
.rewards-builder-email-preview img {
	width: 100%;
	border-radius: 10px;
}
/* 
.rewards-module-preview-image {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: 55px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	height: auto;
	width: 88%;
	overflow: scroll;
	background-color: var(--bg-light);
}

.rewards-module-preview-cover {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: 22px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	height: calc(100% - 22px);
	background-color: var(--bg-light);
	width: 88%;
	overflow: scroll;
	background-color: var(--bg-light);
} */
