.brand-module-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: var(--disco-spacing-lg);
}
.brand-module-grid-row {
	display: flex;
	flex-direction: column;
	row-gap: var(--disco-spacing-md);
}
.brand-module-ims-container {
	position: relative;
}
.brand-module-ims {
	width: calc(100% - 78px);
}
.brand-module-ims-btn {
	position: absolute;
	height: 44px;
	right: 0;
	bottom: 0;
}
.brand-module-edit-image > img {
	--size: 128px !important;
}

.brand-module-long-desc {
	flex-grow: 1;
}
.brand-module-long-desc .disco-labeled-inp {
	display: flex;
	flex-direction: column;
	height: 100%;
}
.brand-module-long-desc .disco-text-inp-container-textarea {
	flex-grow: 1;
}
