.page-setup-module {
	display: grid;
	grid-template-columns: 1fr var(--universal-preview-phone-width);
}
.page-setup-module
	.universal-preview-container:not(.universal-preview-container-full) {
	margin-top: var(--disco-spacing-lg);
}

.page-setup-module .settings-module-section {
	display: grid;
	row-gap: var(--disco-spacing-rg);
}
