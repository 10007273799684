.brand-color-edit-view {
	position: relative;
}
/* .brand-color-edit-view .edit-view-action {
	right: 306px;
} */
.brand-color-edit-view-selector-container {
	position: absolute;
	bottom: 6.5px;
	right: 8px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}
.brand-color-edit-view-icon {
	width: 30px;
	height: 30px;
	border-radius: 100px;
	margin-left: auto;
	border: 0.4px solid var(--bg-dark-2);
}
.brand-color-edit-view-selector {
	position: absolute;
	width: 300px;
	box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.2) !important;
	/* top: -10px; */
	height: fit-content;
	top: 40px;
	right: -6.5px;
	border: 1px solid var(--theme);
	border-radius: 8px !important;
}
.brand-color-edit-view-chrome {
	box-shadow: unset !important;
	background-color: transparent !important;
}

.brand-color-edit-view-chrome .flexbox-fix:last-of-type {
	display: none !important;
}
.brand-color-edit-view .edit-view-label-tooltip {
	bottom: 80px;
	z-index: 1000;
}
.brand-color-tooltip-body {
	margin-top: 2px;
	margin-bottom: 10px;
}
