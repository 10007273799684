.form-page-form {
	width: 350px !important;
	background-color: var(--bg-light);
	padding: 30px;
	padding-bottom: 50px;
	border-radius: 20px;
	color: var(--text);
}

.form-page-form .inp {
	background-color: var(--bg-dark);
}

.form-page .loader {
	margin-top: 20px;
}

.form-page-error {
	width: 280px !important;
}

.form-page .base-form-head {
	font-weight: 700;
	font-size: 1.8rem;
	color: var(--text);
}

.login-page-forgot {
	margin-top: 25px;
	margin-bottom: -5px;
	color: var(--theme);
	font-size: 1.1rem;
	font-weight: 500;
}

.register-page-help {
	font-size: 1.1rem;
	margin: 7px 0 10px 0;
}
