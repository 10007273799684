.inline-toast {
	position: absolute;
	width: 100%;
	display: flex;
	align-items: center;
	background-color: var(--disco-blue-default);
	top: 0;
	left: 0;
}

.inline-toast-icon {
	position: relative;
	top: 2px;
	margin-right: var(--disco-spacing-tn);
	font-size: var(--disco-fs-subtitle);
}

.inline-toast-warn {
	background-color: var(--disco-text-mid);
}
