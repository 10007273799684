.mobile-page-root {
	width: 100%;
	min-height: 100vh;
	background-color: var(--bg-light);
	position: relative;
}
.mobile-page-banner {
	height: 100px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: var(--disco-gradient);
}
.mobile-page-banner > img {
	/* margin-bottom: 18px; */
	height: 40%;
}
.mobile-page-img {
	margin: auto;
	display: block;
	width: inherit;
	max-width: 340px;
}
.mobile-page-content {
	min-height: calc(100vh - 120px);
	margin-top: 10px;
	padding: 0px 10px;
	padding-bottom: 120px;
	text-align: center;
	display: flex;
	justify-content: center;
	flex-direction: column;
	overflow: auto;
}
.mobile-page-content h2 {
	font-size: 46px;
	line-height: 54px;
}
.mobile-page-content .mobile-page-content-subheading {
	font-size: 28px;
	margin-top: 15px;
	font-weight: 400 !important;
	line-height: 40px;
	color: var(--text-light-3);
}
.mobile-page-click-success {
	font-size: 15px;
	color: var(--text-light);
	font-weight: 500;
	max-width: 80%;
	margin: auto;
	margin-top: 20px;
}
.mobile-page-content .btn {
	font-size: 20px;
	line-height: 24px;
	padding: 10px 18px;
	height: auto;
	margin: auto;
	margin-top: 20px;
}
.mobile-page-footer {
	position: absolute;
	background-color: rgba(255, 255, 255, 0.8);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	bottom: 0px;
	width: 100%;
}
.mobile-page-footer-content {
	padding: 25px 0px 40px 20px;
	/* padding: 25px 0px; */
	color: var(--text-light-3);
	text-align: left;
}
.mobile-page-footer-content a {
	color: var(--theme);
}
