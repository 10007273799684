.edit-view {
	border-radius: 10px;
	transition: padding 0.2s, border-color 0.2s;
	border: 1.5px solid transparent;
	outline: none;
}
.edit-view-status {
	margin-top: 15px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.edit-view-error {
	padding: 10px;
	border-color: var(--light-red) !important;
}

.edit-view-action {
	position: absolute;
	right: 10px;
	display: flex;
	align-items: center;
	top: -12px;
	height: 40px;
}

.edit-view-action > * {
	margin: 0 2px !important;
}

.edit-view .form-status {
	margin-top: 0 !important;
}

.edit-view textarea {
	max-height: 100px;
	padding: 15px !important;
}
.edit-view-label-tooltip-icon {
	font-size: 1.3rem;
	position: relative;
	top: 2.6px;
	left: 4px;
	/* display: none; */
	opacity: 0.7;
	color: var(--text-light);
}
.edit-view-label-tooltip {
	color: var(--text) !important;
}
