.completion-screen {
	width: 100% !important;
	height: 100% !important;
	position: fixed;
	max-height: initial;
	max-width: initial;
	border-radius: 0;
	background-image: url("../../img/onboarding/completion-bg.png");
	background-color: var(--dark-bg);
	background-size: cover;
	top: 0;
	bottom: 0;
	z-index: 24;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	--completion-bordered-card-width: 44%;
	z-index: 26;
}

.completion-screen .bordered-card {
	width: var(--completion-bordered-card-width);
	background-color: var(--bg-light);
	position: relative;
	/* left: 10%; */
}

.completion-screen .bordered-card-heading {
	margin-top: 10px;
}

.completion-screen-user-img {
	border-radius: 50%;
	height: 70px;
	width: 70px;
	border: 4px solid var(--bg-light);
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: -40px;
}

.completion-screen-brands {
	/* display: flex; */
	/* align-items: center; */
	/* justify-content: center; */
	width: 80%;
	margin: 10px 0 20px 0;
	overflow: hidden;
}

.completion-screen-brand {
	height: 54px;
	width: 54px;
	border-radius: 50%;
	object-fit: cover;
	border: 2px solid transparent;
	transition: border-color 0.2s;
	cursor: help;
	padding: 1.5px;
}

.completion-screen .bordered-card .large-text {
	text-align: center;
}

.completion-screen-chevron {
	width: 50px;
	display: block;
	height: 100%;

	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 1),
		rgba(255, 255, 255, 0)
	);
	font-size: 2rem;
	color: var(--text-light-3);
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.completion-screen-chevron-right {
	background: linear-gradient(
		-90deg,
		rgba(255, 255, 255, 1),
		rgba(255, 255, 255, 0)
	);
	justify-content: flex-end;
}
.completion-partner-modal {
	position: absolute;
	z-index: 25;
	height: 390px;
	box-shadow: 0px 0px 50px 20px rgba(0, 0, 0, 0.3) !important;
	width: 340px !important;
	left: calc(26% - 340px);
	top: 50vh;
	transform: translateY(-50%) !important;
	right: initial;
}
