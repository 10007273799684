.audiences-platform-connect-modal {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	background-color: var(--disco-bg-light);
}

.audiences-platform-connect-modal-logo {
	display: flex;
	align-items: center;
	font-weight: 600;
}

.audiences-platform-connect-modal-logo > img {
	height: 36px;
	margin-right: 10px;
}

.audiences-platform-connect-modal > p a {
	font-weight: 500;
	color: var(--theme-dark);
	cursor: pointer;
}

.audiences-platform-connect-modal-permissions {
	width: 100%;
}

.audiences-platform-connect-modal > footer {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	align-items: center;
	margin-top: var(--disco-spacing-rg);
}

.audiences-platform-connect-done {
	color: var(--disco-green-mid);
}
