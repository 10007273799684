.new-widget-module-container {
	width: 98%;
	margin: 0 auto;
}
.new-widget-module-container .embedded-preview-page-child {
	display: flex;
}
.new-widget-module-header {
	margin-bottom: 20px;
}
.new-widget-module-header .tab {
	margin-top: -8px;
}

.new-widget-module-card {
	flex-grow: 1;
}

.new-upsell-module .products-table th .question-tooltip,
.new-cross-sell-module .products-table th .question-tooltip {
	color: var(--text) !important;
	right: 0px;
}

.new-upsell-module .products-container-save-order,
.new-cross-sell-module .products-container-save-order {
	width: 97%;
	left: 1.5%;
	top: -10px;
}
.new-upsell-module .products-table .product-row-reorder,
.new-cross-sell-module .products-table .product-row-reorder {
	top: 25px;
}
