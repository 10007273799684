.settings-module {
	margin-top: var(--disco-spacing-lg);
}
.settings-module-section-header {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.script-module-setup-modal .modal-content {
	padding: 0px 20px;
}
.script-module-setup-modal .script-setup {
	background: transparent;
	padding: 0px 10px 20px 10px;
}
