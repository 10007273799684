.survey-closer-label {
	margin: 6px 0 4px 0;
	font-size: 1rem;
	font-weight: 500;
	text-transform: uppercase;
	font-weight: normal;
}

.survey-question-text {
	display: flex;
	align-items: center;
	font-size: 1.4rem;
}

.survey-builder-config {
	width: 100%;
	margin-top: 10px;
}
