.survey-results-inline-tooltip {
	margin: 20px;
	border-radius: 10px;
}

.survey-result-completion {
	width: 96%;
	margin: 0px auto 20px auto;
	background-color: var(--bg);
	padding: 20px;
	border-radius: 10px;
}

.survey-result-completion > h2 {
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--text-light-3);
}

.survey-result-completion > h2 span {
	color: var(--text);
	margin-left: 5px;
}

.survey-result-completion .form-status {
	margin-top: 10px;
	width: 100% !important;
}

.survey-result-completion .progress-view {
	margin-top: 15px;
}

.survey-results-table {
	width: 96%;
	padding: 20px;
	border-radius: 10px;
	border-collapse: collapse;
	margin: 0 auto 20px auto;
}

.survey-results-table > tbody > tr {
	height: 50px;
	text-align: center;
}

.survey-results-table > tbody > tr:nth-last-of-type(2n) {
	background-color: var(--bg);
}

.survey-results-table > thead > tr {
	height: 50px;
	font-size: 0.85rem;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.survey-results-table > thead > tr > th {
	border-bottom: 1px solid var(--theme);
	text-align: center;
}

.survey-results-table thead button {
	border: none;
	cursor: pointer;
	background: none;
	outline: inherit;
	color: var(--theme);
	font-family: var(--font);
	font-weight: 600 !important;
}

.survey-results-table thead button::after {
	content: " -";
	margin-left: 6px;
	display: inline-block;
}

.survey-results-table thead button.ascending::after {
	content: "↓";
	margin-left: 6px;
	display: inline-block;
}

.survey-results-table thead button.descending::after {
	content: "↑";
	margin-left: 6px;
	display: inline-block;
}
