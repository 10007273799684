.subscription-module-card {
	padding: 40px;
	max-width: 850px;
	text-align: center;
}

.subscription-module-card h2 {
	font-weight: 500;
	font-size: 1.75rem;
}

.subscription-module-card p {
	font-size: 1.1rem;
	width: 55%;
	margin: 20px auto;
	line-height: 22px;
}

.subscription-module-credits {
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	margin-top: 30px;
}

.subscription-module .credits-view {
	width: 200px;
	padding: 10px;
	background-color: var(--theme);
	margin: 0 10px;
}
.subscription-module .credits-view-value {
	font-size: 1.4rem;
	font-weight: 600;
	padding: 8px;
}

.subscription-module-earned {
	background-color: var(--dark-bg) !important;
}

.subscription-module .settings-module-icon img {
	margin-bottom: 12px;
}

.subscription-module-icon-warn {
	background-color: var(--dark-bg);
}

.subscription-module-action {
	color: var(--theme);
	cursor: pointer;
	text-decoration: underline;
}

.subscription-module .payment-set-view {
	background-color: var(--bg-dark);
	margin-top: 20px;
	margin-bottom: -10px;
}

.settings-module-main {
	overflow-x: auto;
}

/********** BILLING HISTORY SECTION **********/
.table-with-description .disco-table-container > .disco-table-header {
    display: none;
}

/********** BILLING HISTORY DATA **********/
.billing-history-card {
	margin-top: 20px;
	overflow-x: auto;
	max-height: 400px;
	padding: 20px 0 5px 0 !important;
}

.billing-history-header {
	width: 100%;
	color: #575757;
	text-align: left;
	padding-left: 20px;
	padding-bottom: 24px;
	font-weight: 600 !important;
	border-bottom: 1px solid #d7dee9;
}

.billing-period-dates {
	font-size: 1rem;
}

.non-focal-billing-data {
	color: #797979;
	font-size: 1rem;
}

.focal-billing-data {
	font-weight: 600;
	font-size: 1rem;
}

/********** BILLING DATA TABLE **********/
.billing-data-table {
	width: 100%;
	padding: 20px;
	border-collapse: collapse;
}

.billing-data-table > thead > tr {
	height: 50px;
	font-size: 0.85rem;
	letter-spacing: 1px;
	text-transform: uppercase;
}

.billing-data-table > thead > tr > th {
	text-align: left;
	border-bottom: 1px solid #d7dee9;
}

.status-column-header {
	padding-left: 5px;
}

.billing-data-table > thead > tr > th:first-child {
	padding-left: 20px;
}

.billing-data-table thead button {
	border: none;
	cursor: pointer;
	background: none;
	outline: inherit;
	color: #2c2e31;
	font-family: var(--font);
	font-weight: 700 !important;
}

.billing-data-table thead button::after,
.billing-data-table thead button.ascending::after {
	color: #aaaaaa;
	content: "\25bc";
	margin-left: 6px;
	font-size: 0.7rem;
	display: inline-block;
}

.billing-data-table thead button.descending::after {
	color: #aaaaaa;
	content: "\25bc";
	margin-left: 6px;
	font-size: 0.7rem;
	display: inline-block;
	transform: rotate(-180deg);
}

.billing-data-table > tbody > tr {
	height: 50px;
	text-align: left;

	border-bottom: 1px solid #d7dee9;
}

.billing-data-table > tbody > tr:last-child {
	border-bottom: none;
}

.billing-data-table > tbody > tr > td:first-child {
	padding-left: 20px;
}

.billing-data-table > tbody > tr > .payment-status-data {
	padding-right: 30px;
}

.billing-data-table > tbody > tr > td {
	position: relative;
}

.billing-data-table > tbody > tr > .hover-data:hover {
	cursor: pointer;
	color: var(--theme) !important;
}

/********** UPSELL AND OCU HOVER CARDS **********/
.tooltip-content {
	bottom: 70% !important;
}

/********** PAYMENT STATUS BADGE **********/
.payment-status-badge {
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	height: 30px;
	padding: 5px;
	color: #202020;
	font-weight: 500;
	font-size: 1rem;
	white-space: nowrap;
	border-radius: 100px;
	text-align: center;
}

/********** PAYMENT BADGE BY STATUS **********/
.payment-trial {
	background-color: var(--subscription-trial-bill-status-color);
}

.payment-upcoming {
	background-color: var(--subscription-upcoming-bill-status-color);
}

.payment-paid {
	background-color: var(--subscription-paid-bill-status-color);
}
.payment-module-ocu-tooltip {
	--color: var(--disco-yellow-dark);
}

/********** MEDIA QUERIES **********/

/********* CHANGE BILLING TABLE WIDTH **********/
@media only screen and (max-width: 1429px) {
	.billing-history-header {
		width: var(--subscription-table-data-width);
	}
	.billing-data-table {
		width: var(--subscription-table-data-width);
	}
}

/********** CONVERT TO MOBILE LAYOUT (GRID) **********/
@media only screen and (max-width: 930px) {
	.settings-module {
		display: grid;
		grid-template-rows: repeat(1, 1fr);
		grid-template-columns: repeat(1, 1fr);
	}
	.settings-module-aside {
		width: 100%;
	}
}
