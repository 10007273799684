.new-edit-image {
	--v-margin: 0px;
	--h-margin: auto;
	margin: var(--v-margin) var(--h-margin);
	position: relative;
	overflow: hidden;
	flex-direction: column;
}
.new-edit-image-img {
	cursor: pointer;
	--curve: var(--disco-curve-sm);
	--size: 140px;
	--width: var(--size);
	--height: var(--size);
	border-radius: var(--curve);
	width: var(--width);
	height: var(--height);
	object-fit: contain;
}
.new-edit-image-circular .new-edit-image-img {
	--curve: 100%;
}
.new-edit-image-action {
	--curve: var(--disco-curve-sm);
	--bg: transparent;
	background-color: var(--bg);
	border-radius: var(--curve);
	transition: background-color 0.2s;
	cursor: pointer;
	margin-top: var(--disco-spacing-sm);
}
.new-edit-image-action:hover {
	--bg: var(--disco-bg-mid);
}
