@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.products {
	--products-width: 96%;
	width: var(--products-width);
	margin: auto;
	height: 100%;
}

.products-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid var(--text-light-5);
	margin-top: -15px;
}

.products-header-half {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.products-header-half h2 {
	font-weight: 600;
	font-size: 1.65rem;
	margin: 6px 0;
}

.products-header-half .tabs {
	position: relative;
	top: 6px;
	height: 100%;
	border: none;
	width: auto;
	border-radius: 0px;
	margin-left: 20px;
}

.products-header-half .tab {
	background-color: transparent !important;
	padding: 0 !important;
	padding-bottom: 10px !important;
	color: var(--text-light-3);
	margin: 0 15px;
	text-transform: capitalize;
	font-size: 1.15rem;
	border-bottom: 3.5px solid transparent;
	transition: color 0.2s, border-bottom 0.2s;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	font-weight: 400;
	height: 38px;
	white-space: nowrap;
	position: relative;
}

.products-header-half .tab-active {
	color: var(--text-light);
	border-bottom-color: var(--theme);
}

.products-header-syncing {
	display: flex;
	padding: 0 20px 0 12px;
	border-radius: 8px;
	background-color: var(--theme-light);
	color: var(--theme);
	align-items: center;
	justify-content: center;
	height: 38px;
	font-size: 1.1rem;
	position: relative;
	margin-bottom: 15px;
	margin-right: 10px;
}

.products-onboarding .products-header-syncing {
	margin-bottom: 4px;
}

.products-header-syncing .loader {
	margin: 0;
}

.products-header-half > .btn {
	margin: 0 3px 10px 3px;
	padding-left: 8px;
	padding-right: 8px;
}
.products-header-minimal {
	background-color: transparent;
	border: none;
	padding-left: 5px;
	padding-right: 5px;
}

.products .disco-status-label-warning .disco-status-label-icon {
	color: var(--disco-text-colored);
}

.products-merge-save {
	margin: 40px 0 20px auto;
}

.products-refresh-loading {
	animation: spin 0.3s ease-out infinite;
}

.products-settings-modal-section {
	width: 90%;
	margin: auto;
}

.products-empty {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	background-color: var(--bg-light);
	border: 1.5px solid var(--text-light-5);
	border-radius: 16px;
	padding: 16px;
}

.products-empty span {
	color: var(--theme);
	font-size: 5rem;
}

.products-empty h2 {
	letter-spacing: -1px;
	font-size: 2rem;
	margin-top: 5px;
}

.products-empty p {
	font-size: 1.5rem;
	color: var(--text-light-3);
	font-weight: 300;
	margin-top: 5px;
}

.products-empty .btn {
	margin-top: 30px;
}
.products-collections-search {
	width: 100%;
}
