.survey-option-main {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 32px;
}
.survey-option {
	position: relative;
	border-radius: 6px;
	width: 100%;
	margin-top: 6px;
	cursor: pointer;
	padding: 10px 5px;
	background: rgb(255, 255, 255);
}

.survey-options .label {
	width: 160px !important;
	display: flex;
	align-items: center;
}

.survey-options .label .checkbox {
	margin-left: 9px;
	height: 22px !important;
	width: 22px !important;
}

.survey-option-img {
	height: 32px;
	width: 32px;
	object-fit: contain;
	margin: 0 8px;
	flex: 0 0 auto;
	border-radius: 6px;
	transition: background-color 0.2s;
}

.survey-option-img:hover {
	background-color: var(--bg-dark);
}

.survey-option .inp {
	background-color: transparent !important;
	transition: background-color 0.2s;
	min-width: 0px !important;
	width: 100% !important;
	font-weight: 500;
	padding: 0;
	font-size: 1.05rem;
	font-weight: 600;
	cursor: pointer;
	border-radius: 0px;
	margin: 0;
	border: none !important;
	box-shadow: none !important;
}

.survey-option-follow-up {
	margin-top: 20px;
}

.survey-option-act {
	font-size: 1.5rem;
	position: relative;
	top: 4px;
	margin-left: 4px;
	cursor: pointer;
	color: var(--text-light-3);
	transition: color 0.2s;
}

.survey-option-act:hover {
	color: var(--text-light);
}

.survey-option-reorder {
	margin-left: 6px;
	font-size: 1.7rem;
	margin-right: 10px;
}

.survey-option .label {
	white-space: nowrap;
	display: flex;
	align-items: center;
	font-size: 0.85rem;
	justify-content: center;
	margin: 0 5px;
}

.survey-option-follow-up {
	border: 1px solid var(--text-light-5);
	margin-top: 12px !important;
	width: 100%;
	border-radius: 20px !important;
	padding: 15px !important;
	font-size: 1rem !important;
}
