.script-module-header-children {
	flex-grow: 1;
}
.script-module .script-setup {
	max-height: 400px;
	padding-top: 5px !important;
	overflow: auto;
	margin-bottom: 10px;
}
.script-module .integrations-module-container-information {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	background-color: var(--bg-dark);
}

.script-module-content .script-text {
	height: auto;
	font-family: var(--font);
	font-size: 1.2rem;
}

.script-module-content .btn {
	font-size: 0.9rem !important;
	margin: 20px 6px;
}

.script-module-icon-green {
	background-color: var(--green) !important;
}

.script-module-icon-warn {
	background-color: var(--yellow) !important;
}

.script-module-icon-warn * {
	color: rgba(0, 0, 0, 0.6) !important;
}

.script-module-icon-warn img {
	opacity: 0.3;
}

.script-module .settings-module-icon img {
	width: 45%;
}
