.sensitive-sub-category-exclusion {
	display: flex;
	align-items: center;
	margin-top: 18px;
	position: relative;
	font-size: 1.14rem;
	width: fit-content;
}
.sensitive-sub-category-exclusion .checkbox {
	margin-right: 6px;
}
.sensitive-sub-category-exclusion .loader {
	margin-right: 6px;
}
.sensitive-sub-category-exclusion .tooltip {
	display: flex;
	align-items: center;
	margin-left: 8px;
}

.sensitive-sub-category-exclusion .tooltip-content {
	bottom: -50px !important;
	left: 100px;
}
