.product-display {
	display: flex;
	position: relative;
}

.product-display .review-indicator {
	position: relative;
	z-index: 0;
	top: 0px;
}

.product-display .tooltip {
	margin: 0 !important;
	height: 18px;
	position: relative;
	top: 5px;
}

.product-display .tooltip-content {
	left: 0;
}

.product-display-name .remote-inp-container .tooltip-content {
	top: -170%;
	left: -5px;
	bottom: initial !important;
}

.product-display .guided-tooltip {
	top: 50%;
}

.product-display-name {
	position: relative;
}

.product-display-name .remote-inp-container {
	opacity: 0;
	position: absolute;
	pointer-events: none;
	z-index: 10;
	top: -5px;
	left: -1px;
	transition: opacity 0.2s;
	width: 100%;
	min-width: 250px;
}

.product-display-name:hover .remote-inp-container,
.product-display-name .remote-inp-container:focus {
	opacity: 1 !important;
	pointer-events: initial !important;
}

.product-display-onboarding .remote-inp {
	width: 160px;
}

.product-display img {
	height: 40px;
	width: 40px;
	object-fit: cover;
	border-radius: 8px;
}

.product-display-hero {
	display: flex;
}

.product-display-variants {
	border-bottom: 1px dashed var(--text-light-5);
	cursor: pointer;
}

.product-display-variants-tooltip {
	padding: 0;
	border-color: transparent;
	background-color: var(--bg);
	width: 500px;
}

.product-display-variants-tooltip header {
	background-color: var(--bg-light);
	height: 60px;
	padding: 0 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;
	border-bottom: 1.5px solid var(--text-light-5);
}

.product-display-variants-tooltip header h3 {
	font-weight: 600;
	font-size: 1.2rem;
}

.product-display-variants-tooltip th {
	text-align: center !important;
	background-color: transparent !important;
}

.product-display-variants-tooltip td {
	padding: 0 !important;
}

.product-display-variants-tooltip td img {
	margin: 6px 0 !important;
}

.product-toggle {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-price {
	justify-content: right;
}

.product-price-discount {
	text-decoration: line-through;
}

.product-display-warn {
	border-radius: 5px;
	border: 1px solid var(--dark-yellow-2);
	color: var(--dark-yellow-2);
	top: 2px;
	max-width: fit-content;
}
