.performance-page .page-content {
	flex-direction: column !important;
	justify-content: flex-start;
}
.performance-analysis-module {
	--performance-analysis-width: 96%;
	--performance-module-height: 80vh;

	width: 100% !important;
	max-width: initial !important;
	flex-direction: column;
	height: var(--performance-module-height);
	position: relative;
	margin-top: var(--disco-spacing-lg);
}

.performance-analysis-module iframe {
	width: 100%;
	height: var(--performance-module-height);
	border: none;
	border-radius: 12px;
}

.performance-analysis-module-empty {
	flex-direction: column;
	justify-content: center;
	width: 60% !important;
	margin: auto;
	max-width: 500px !important;
	text-align: center;
	max-width: initial !important;
}

.performance-analysis-module-empty h2 {
	font-size: 2.2rem;
}

.performance-analysis-module-empty h4 {
	font-size: 1.4rem;
	color: var(--text-light);
	font-weight: 600;
	/* margin-top: 10px; */
}

.performance-analysis-module-empty p {
	font-size: 1.2rem;
	/* text-align: left; */
}
.performance-analysis-module-empty-icon {
	font-size: 5rem;
	color: var(--theme);
}

/* Maintenance */
.performance-page-maintenance-content {
	min-height: 75vh;
}
.performance-page-maintenance-content .disco-card-content {
	display: flex;
	justify-content: center;
	align-items: center;
}
.performance-page-maintenance-content iframe {
	outline: 0;
	border: 0;
	box-shadow: 0;
	display: block;
	--size: 280px;
	width: var(--size);
	height: var(--size);
	margin: auto;
}
