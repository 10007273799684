.payment-modal {
	max-width: 800px;
	max-height: 90%;
	padding: 40px;
	padding-top: 0px;
}
.payment-modal h1 {
	font-size: 4rem;
	font-weight: 800;
	text-align: center;
	margin-top: -10px;
}
.payment-modal-subtext {
	font-size: 2.3rem;
	font-weight: 300;
	color: var(--text-light);
	text-align: center;
	margin-top: 14px;
}
/* .payment-modal-subtext span {
	font-weight: 700;
	color: var(--theme);
} */

.payment-modal-plans {
	display: flex;
	width: 90%;
	margin: auto;
	margin-top: 40px;
	align-items: center;
	justify-content: space-between;
}
.payment-modal-plans > span {
	margin: 0px 20px;
	font-size: 2rem;
	color: var(--text-light-3);
}
.payment-modal-details {
	margin: auto;
	margin-top: 20px;
	text-align: center;
	width: 90%;
	color: var(--text-light-3);
}

.payment-modal-section {
	width: 90%;
	margin: auto;
	margin-top: 30px;
}
.payment-modal-addons h2 {
	font-size: 1.8rem;
}
.payment-modal-action {
	margin-top: 30px;
}
.payment-modal-action .stripe-card {
	width: 100%;
}
/* .payment-modal .payment-set-view-modal { */
/* border: 0px; */
/* margin-top: 10px; */
/* box-shadow: 0px 0px 16px rgb(3 25 38 / 5%), 0px 20px 24px rgb(3 25 38 / 15%); */
/* } */
/* .payment-modal-plan-card .subheading {

} */
