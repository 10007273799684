/* .reset-step .onboarding-step-main {
	overflow: hidden;
	height: 50%;
	position: relative;
	top: 25%;
	min-height: 550px;
}
.reset-step .onboarding-step-main-wrapper {
	justify-content: center;
	flex-direction: column;
	display: flex;
}
*/
.reset-step .btn {
	margin-left: 0px;
	margin-right: auto;
	width: 100%;
}

/* .reset-step .onboarding-step-main-header {
	width: 65%;
} */

.reset-step .guided-view-content {
	width: 45% !important;
}

.reset-step-back {
	margin-top: 26px;
	cursor: pointer;
	font-weight: 600;
	font-size: 1.02rem;
	color: var(--theme);
	transition: color 0.12s ease-in-out;
}
.reset-step-back:hover {
	color: var(--text-light);
}
