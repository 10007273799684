.central-view {
	padding: 50px 0;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.central-view-hero {
	position: relative;
	z-index: 10;
	width: 50%;
	background-color: var(--bg-light);
}

.central-view-hero > main {
	padding: 64px 64px 32px 64px;
}

.central-view-logo {
	width: 154px;
	margin-bottom: 27px;
}

.central-view .tab-switcher-container {
	position: relative;
	z-index: 10;
}

.central-view .tab-switcher-background {
	background-color: rgba(255, 255, 255, 0.08);
	border: 1px solid rgba(255, 255, 255, 0.3);
	color: var(--bg-light);
	font-size: 1.4rem;
}

.central-view .tab-switcher-tab-active {
	color: var(--text-light);
}

.central-view-background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}

.central-view-heading {
	text-align: center;
	font-size: 2rem;
	font-weight: bold;
	letter-spacing: -0.18px;
	line-height: 29px;
	color: var(--bg-light-2);
}

.central-view-subheading,
.central-view-text {
	text-align: center;
	margin-bottom: 15px;
	color: var(--theme);
	font-size: 1.9rem;
	letter-spacing: -1px;
	width: 85%;
	font-weight: 300;
	color: var(--text-light-3);
	line-height: 32px;
}

.central-view-text {
	margin: 15px 0 15px 0;
}

.central-view-mobile-hero {
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 2.25rem;
}

.central-view .disco-tab-switcher {
	z-index: 10;
}

@media screen and (max-width: 600px) {
	.central-view {
		justify-content: flex-end;
		padding-bottom: 0;
	}

	.central-view .base-form {
		justify-content: unset;
	}

	.central-view .bordered-card {
		border-radius: 20px 20px 0 0;
	}

	.central-view .base-form-head {
		font-weight: 900 !important;
		font-size: 2.3rem !important;
	}

	.central-view .h-form {
		margin: 0;
		overflow-y: auto;
	}

	.central-view .h-form-fields {
		flex: 1;
		grid-template-columns: 1fr;
		row-gap: 8px;
		width: 100%;
	}

	.central-view .h-form-fields .inp {
		width: 100%;
	}

	.central-view .h-form-label {
		text-align: left;
		margin-top: 12px;
	}

	.central-view .h-form {
		width: 100%;
	}

	.central-view .h-form .btn {
		width: 100%;
	}

	.central-view-tab-switcher {
		width: 100%;
		padding: 0 24px;
	}

	.central-view .bordered-card-heading {
		font-size: 2.33rem;
		width: 100%;
		margin-bottom: 4px;
	}

	.central-view .bordered-card-sub-heading {
		font-size: 1.5rem;
		line-height: 2.25rem;
		width: 100%;
		margin-bottom: 0;
	}

	.central-view-hero > footer {
		display: flex;
		justify-content: center;
		background-color: transparent;
		border: none;
		padding-top: 0;
	}
}
