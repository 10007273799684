.survey-config-container {
	display: flex;
	flex-direction: column;
	padding: 10px 0px;
	padding-top: 0px;
}
.survey-config-group {
	margin-top: 20px;
}
/* .survey-config-view-only .toggle-inp-container {
	margin-top: 10px;
	opacity: 0.8;
} */

.survey-config-form-status {
	font-weight: 500;
	line-height: 20px;
	font-size: 1.05rem !important;
}

.survey-config-label-dark:nth-of-type(1) {
	color: var(--text);
}
