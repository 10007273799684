.join-team-page .page-content {
	justify-content: center;
	align-items: flex-start;
}
.join-team-card {
	width: 96%;
	height: auto;
	background-color: var(--bg-light);
}

.join-team-card > h2 {
	font-size: 3rem;
	letter-spacing: -1.7px;
	color: var(--text-light);
}

.join-team-card > p {
	font-size: 1.5rem;
	margin-top: 20px;
	color: var(--text-light-3);
}

.join-team-confirm {
	color: var(--text) !important;
}

.join-team-action {
	display: flex;
	margin-top: 20px;
}

.join-team-action > * {
	margin: 0 5px;
}
