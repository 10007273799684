.payment-set-view {
	background-color: var(--bg-light);
	padding: 25px;
	border-radius: 10px;

	width: 100%;
	text-align: left;
}

.payment-set-view h5 {
	padding-top: 0px !important;
}

.payment-set-view .set-view-edit {
	background-color: var(--theme);
	color: var(--bg-light);
	padding: 8px 15px;
	margin-top: -3px;
}

.payment-set-view .loader {
	margin-top: -10px;
}
.payment-set-view-modal {
	margin-top: 20px;
	border: 2px solid var(--theme);
}
