.audiences-ad-account .disco-btn {
	margin-top: var(--disco-spacing-sm);
}

.audiences-ad-account .disco-select {
	height: 42px;
}

.audiences-ad-accounts-configure {
	display: block;
}

.audiences-ad-accounts-configure span {
	color: var(--disco-blue-default);
	cursor: pointer;
}
