.side-bar {
	position: fixed;
	top: calc(var(--bod-banner-height) + var(--alert-bar-height));
	left: 0;
	height: calc(100vh - var(--bod-banner-height) - var(--alert-bar-height));
	width: var(--side-bar-width);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	z-index: 11;
	background: var(--theme-gradient-2);
}
.side-bar .credits-view {
	cursor: unset !important;
}
.side-bar-blue {
	background-image: none;
}

.side-bar-blue .side-bar-nav-item {
	color: var(--theme-2);
}

.side-bar-hero {
	display: flex;
	width: 90%;
	margin-top: 42px;
	margin-left: 9px;
	margin-bottom: 15px;
	align-items: center;
}

.side-bar-logo {
	width: 40%;
	margin-right: 6px;
	flex: 0 0 auto;
}

.side-bar-logo img {
	width: 100%;
}

.side-bar nav {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 90%;
}

.side-bar-nav-item {
	cursor: pointer;
	padding: 8px;
	border: 1px solid transparent;
	margin: 0 6px;
	color: var(--bg-light);
	font-size: 1.15rem;
	border-radius: 6px;
	margin-top: 6px;
	font-weight: 400;
	transition: color 0.2s, background-color 0.2s, border-color 0.2s;
}

.side-bar-nav-sub-item {
	padding: 8px !important;
	padding-left: 28px;
	border-radius: 6px;
	font-weight: 500 !important;
	margin: auto;
	color: var(--text-light);
	border: 1px solid transparent;
	transition: color 0.2s, background-color 0.2s, border-color 0.2s;
	width: 82%;
}

.side-bar-nav-sub-item-no-hover {
	cursor: default !important;
}

.side-bar-nav-sub-item-no-hover:hover {
	border-color: transparent !important;
}

.side-bar-nav-sub-item:not(.side-bar-nav-sub-active):hover {
	border-color: var(--theme-light);
	/* color: var(--theme-dark); */
	color: var(--theme);
}

.side-bar-nav-sub-item-first {
	margin-top: 8px !important;
}

.side-bar-nav-sub-active {
	color: var(--theme);
	/* color: var(--theme-dark); */
	background-color: var(--theme-light);
	/* background-color: rgba(11, 105, 255, 0.1); */
}

.side-bar-nav-item:hover {
	border-color: rgba(255, 255, 255, 0.9);
	/* color: var(--text); */
}
.side-bar-nav-item:not(.side-bar-nav-active):hover {
	background-color: rgba(255, 255, 255, 0.1);
}

.side-bar-nav-icon {
	font-size: 1.6rem;
	vertical-align: middle;
	margin-right: 5px;
	position: relative;
	top: -1px;
}

.side-bar-nav-icon-partnerships {
	top: -5px;
}

.side-bar-nav-active {
	background-color: var(--bg-light);
	color: var(--text) !important;
	font-weight: 600;
}

.side-bar-btn-container {
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 0 auto 0 auto;
	width: 80%;
	margin-bottom: 30px;
}

.side-bar-referral-tooltip {
	max-width: 340px;
	top: 0px;
	left: calc(var(--side-bar-width) - 40px);
	font-size: 1.15rem;
	font-weight: 500;
}

.side-bar-badge-btn {
	height: 36px !important;
	margin: 4px 0;
}

.side-bar-badge-btn:hover {
	background-color: #fff;
	color: var(--text);
}

.side-bar .slack-btn {
	margin-bottom: 25px;
}

.side-bar-checklist-expand-btn {
	width: 18px;
	height: 18px;
	border-radius: 100px;
	border: 1px solid var(--text-light-4);
	text-align: center;
	line-height: 14px;
	font-size: 1.4rem;
	position: relative;
	top: 1px;
	margin-left: auto;
	color: var(--theme);
	cursor: pointer;
	font-weight: 600;
	transition: color 0.2s, background-color 0.2s, border-color 0.2s;
}
.side-bar-checklist-expand-btn:hover {
	color: #fff;
	background-color: var(--theme);
	border-color: var(--theme);
}
.side-bar-checklist {
	max-height: calc(100vh * 0.32);
	display: flex;
	flex-direction: column;
	padding: 0px 18px;
	background-color: var(--bg-light);
	border-radius: 8px;
	position: fixed;
	z-index: 11;
	width: calc(0.9 * var(--side-bar-width));
	bottom: 14px;
	left: calc(0.05 * var(--side-bar-width));
	box-shadow: 0px 2px 1px rgba(0, 0, 0, 0.1), -7px 5px 7px rgba(0, 0, 0, 0.1);
}
.side-bar-checklist-closed {
	padding-bottom: 0px;
	padding-top: 4px;
}
.side-bar-checklist-header {
	/* position: sticky; */
	top: 0px;
	padding: 10px 0px 0px 0px;
	z-index: 2;
	/* background-color: rgba(255, 255, 255, 0.6); */
	/* backdrop-filter: blur(4px); */
	/* -webkit-backdrop-filter: blur(4px); */
}

.side-bar-checklist-body {
	height: 100%;
	overflow: auto;
}

.side-bar-checklist-header-title {
	display: flex;
	align-items: flex-end;
}
.side-bar-checklist-header-title h2 {
	font-size: 1.2rem;
	font-weight: 600;
}
.side-bar-checklist-header-title span {
	font-size: 1.2rem;
	color: var(--text-light-3);
	margin-left: 3px;
}

.side-bar-progress {
	overflow: hidden;
	position: relative;
	height: 8px;
	margin: 15px 0 5px 0;
	background-color: var(--bg-dark-2);
	border-radius: 10px;
}
.side-bar-progress-bar {
	position: absolute;
	height: 100%;
	border-radius: 10px;
	background-image: var(--theme-gradient-progress);
}

.side-bar-checklist-item {
	display: flex;
	margin: 12px 0;
	align-items: flex-start;
	font-size: 1.05rem;
}

.side-bar-checklist-item .checkbox {
	flex: 0 0 auto;
	margin-right: 10px;
	width: 14px;
	height: 14px;
}

.side-bar-checklist-item a {
	color: var(--theme);
	/* color: var(--theme-dark); */
	text-decoration: underline;
}
.side-bar-checklist-footer {
	position: sticky;
	bottom: 0px;
	z-index: 2;
	padding-top: 1px;
	background-color: rgba(255, 255, 255, 1);
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
}
.side-bar-checklist-next {
	height: 28px !important;
	width: 100%;
	margin: 10px auto;
}

.side-bar-progress-toggle {
	text-align: center;
	padding-top: 10px;
	text-decoration: underline;
	border-top: 1.5px solid var(--text-light-5);
	cursor: pointer;
	color: var(--text-light);
	padding-bottom: 10px;
}
.side-bar-progress-toggle span {
	color: var(--theme);
	text-decoration: underline;
}
.sidebar-checklist-tooltip > div {
	margin-top: 10px;
}
.sidebar-checklist-tooltip .btn {
	margin-top: 14px;
	margin-left: -2px;
}

.mobile-side-bar-closed {
	left: -100%;
}

@media screen and (max-width: 600px) {
	.side-bar {
		z-index: 11;
		width: 89vw;
		background: var(--onboarding-gradient);
		transition: left 0.2s;
		border-radius: 0px 24px 0px 0px;
	}

	.side-bar nav {
		width: 100%;
		padding: 0 24px;
	}

	.side-bar-hero {
		position: relative;
		width: 100%;
		justify-content: center;
		margin-top: 37px;
		margin-bottom: 8px;
		margin-left: 0;
	}

	.side-bar-close {
		position: absolute;
		left: 0;
		margin-top: 3px;
		color: var(--bg-light);
		font-size: 2rem;
	}

	.side-bar-logo {
		width: unset;
		margin: 0;
		flex: 0 0 auto;
	}

	.side-bar-logo img {
		width: unset;
		height: 21px;
	}

	.side-bar-nav-item {
		font-size: 1.33rem;
		padding: 12px 0;
		margin: 0;
		border: none;
		opacity: 0.5;
	}

	.side-bar-nav-active {
		opacity: 1;
		margin: 12px 0;
		padding-top: 16px;
		padding-bottom: 4px;
	}

	.side-bar-nav-active svg {
		margin-left: 16px;
	}

	.side-bar-nav-sub-item {
		font-size: 1.16rem;
		font-weight: 400;
		padding: 12px 22px;
	}

	.side-bar-nav-icon {
		font-size: 1.33rem;
		top: -2px;
	}
}
