.sidebar-brand {
	--sidebar-brand-image-side: 45px;
	display: flex;
	width: 90%;
	align-items: center;
	padding: 10px;
	border-radius: 80px;
	margin: 0 auto 20px auto;
	background-color: var(--bg-light);
	cursor: pointer;
	transition: background-color 0.2s;
}

.sidebar-brand:hover {
	background-color: var(--bg-dark);
}

.sidebar-brand > img {
	width: var(--sidebar-brand-image-side);
	height: var(--sidebar-brand-image-side);
	border-radius: 50%;
	grid-area: image;
	margin-right: 12px;
}

.sidebar-brand-details > h2 {
	grid-area: brand-name;
	font-size: 1.28rem;
	font-weight: 600;
	margin-bottom: 3px;
}

.sidebar-brand-details > span {
	grid-area: user-name;
	color: var(--text-light-3);
}

.sidebar-dropdown {
	position: absolute;
	bottom: 20px;
	left: var(--side-bar-width);
}

.sidebar-dropdown .loader {
	margin: auto;
}

.sidebar-dropdown-option-icon {
	border-radius: 50%;
	object-fit: cover;
	position: relative;
	top: 1px;
}
