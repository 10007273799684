.account-module-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.account-module-grid-fields {
	--gap: var(--disco-spacing-md);
	display: grid;
	margin-top: var(--gap);
	row-gap: var(--gap);
}
.account-module-password-action {
	margin-left: auto;
	cursor: pointer;
}
.change-password-modal-inputs {
	display: grid;
	row-gap: var(--disco-spacing-rg);
}
