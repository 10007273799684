.brand-setup-modal-selector-inline-primary-badge {
	background-color: var(--theme);
	color: #fff;
	font-size: 0.8rem;
	display: flex;
	align-items: center;
	padding: 1px 4px;
	margin-left: 4px;
	border-radius: 4px;
}
.brand-setup-modal-selector-inline-primary-badge-draft {
	background-color: var(--dark-yellow-2);
}
.brand-setup-category-finalize-modal-select .mini-badge {
	margin: 0;
	margin-right: 5px;
	color: var(--text);
}
.brand-setup-category-finalize-modal-select .finalize-modal-row {
	padding: 0px;
	color: var(--text-light-3);
}
.brand-setup-category-finalize-modal-select .finalize-modal-row-content {
	display: flex;
	align-items: center;
}
.brand-setup-category-finalize-modal-select .finalize-modal-row-content > span {
	color: var(--text);
	margin-left: 3px;
}
.brand-setup-modal-main-empty {
	font-weight: 400;
	color: var(--text-light-3);
	line-height: 18px;
	font-size: 1.06rem;
	font-weight: 400;
}
.brand-setup-modal-main-empty u {
	color: var(--theme);
	cursor: pointer;
}
