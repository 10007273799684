.audiences-page-header .tabs {
	position: relative;
	top: 2px;
}

.audiences-module-preview {
	position: relative !important;
	display: flex;
	justify-content: space-between;
	margin-top: 1px;
	width: var(--page-content-width);
	left: calc((100% - var(--page-content-width)) / 2);
}
