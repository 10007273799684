.card-modal {
	max-height: 90%;
}
.card-cont {
	margin: 30px auto 0 auto;
	width: 95%;
	padding: 15px;
	border-radius: 10px;
	background-color: var(--bg-dark);
}

.card-btn {
	margin: 20px auto 15px auto;
	width: 160px !important;
	height: 42px !important;
}

.card-loader,
.card-modal-secret-loader {
	margin: 20px auto 15px auto;
}
.card-modal-pro-auth {
	margin-top: 20px;
	width: 100%;
	text-align: center;
	font-size: 1.1rem;
	background-color: var(--bg-light);
	padding: 30px 24px;
	border-radius: 12px;
	border: 2px solid var(--theme);
}

.card-modal-pro-auth-tick {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	align-items: center;
}
.card-modal-pro-auth-tick span {
	margin-left: 10px;
}
.card-modal-pro-auth-action {
	margin: auto;
	margin-top: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
}
.card-modal-pro-auth .filled-btn {
	padding: 14px 40px;
}
