.products-view {
	--products-header-height: 80px;
	border-radius: 10px;
}

.products-view:nth-of-type(1) {
	margin-top: 10px;
}

.collection-header {
	width: 100%;
	display: flex;
	z-index: 10;
	justify-content: space-between;
	font-size: 1.1rem;
	background-color: var(--bg-light);
	box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.07);
	height: var(--products-header-height);
	align-items: center;
	border-radius: 10px;
	border: 2px solid transparent;
	color: var(--text);
	cursor: pointer;
	transition: border-color 0.2s;
}

.collection-header:hover {
	border-color: var(--theme);
}

.collection-header:hover .icon-btn {
	color: var(--bg-light);
	background-color: var(--theme);
	border-color: var(--theme-dark-2);
}

.collection-header-expanded:hover .icon-btn {
	color: initial;
	background-color: initial;
	border-color: var(--text-light-5);
}

.collection-header-expanded {
	position: relative;
}

.collection-header-expanded:hover {
	border-color: transparent;
}

.collection-header-select-row {
	border: 1.5px solid var(--text-light-5);
	border-radius: 6px;
}

.collection-header-select-row .disco-checkbox:hover {
	border-color: var(--text-light-3);
}

.product-display .disco-checkbox:hover {
	border-color: var(--text-light-3);
}

.collection-header-select {
	box-shadow: none;
	border: none;
	border-radius: 0px 4px 4px 0px;
	border-left: 1.5px solid var(--text-light-5);
	min-height: 0;
	text-align: left;
	height: 100%;
	transition: width ease-out 0.2s;
	width: 145px;
}

.collection-header-select .disco-select-icon {
	margin-top: -5px;
	right: 0px;
}

.collection-header-select:hover {
	border-color: var(--text-light-5);
}

.collection-header-select-disabled {
	width: 100px;
}

.collection-header-select .select-options {
	width: 145px;
}

.collection-header-select-option {
	height: 30px;
	line-height: 30px;
	white-space: nowrap;
}

.collection-header-select-option:hover {
	background-color: var(--disco-bg-mid);
}

.collection-header-products {
	width: 100%;
	display: flex;
	flex: 1 1 auto;
	margin: 0 20px;
	overflow: hidden;
	position: relative;
}

.collection-header-products::after {
	content: "";
	position: absolute;
	height: 100%;
	width: 10px;
	background-image: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 1)
	);
	z-index: 10;
	right: 0;
	top: 0;
}

.collection-header-products img {
	width: 40px;
	height: 40px;
	opacity: 1;
	transition: opacity 0.2s;
	object-fit: cover;
	border-radius: 7px;
	margin: 0 7px;
	flex: 0 0 auto;
}

.collection-header-products img:hover {
	opacity: 0.6 !important;
}

.collection-header-add-btn {
	color: var(--theme);
	border-radius: 4px;
}

.products-view-onboarding .lib-table-thin {
	width: 135px !important;
}

tr:hover .products-view-table-edit {
	color: var(--theme) !important;
}

.products-view-onboarding .lib-table-search-inp {
	width: 160px;
	min-width: 0;
}
