.audiences-info {
	width: 100%;
	background-color: var(--disco-bg-light);
	box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.07);
	border-radius: var(--disco-curve-md);
}

.audiences-info > img {
	max-width: 900px;
	width: 100%;
}

.audiences-info > p {
	line-height: 30px;
}

.audiences-info > * {
	margin-bottom: 15px;
}

.audiences-info-feature {
	display: grid;
	grid-template-columns: 70px 1fr;
	column-gap: 15px;
}

.audiences-info-feature > img {
	width: 100%;
	grid-row: span 2;
}
