.product-sidebar-modal {
	width: 584px;
	--product-sidebar-image-width: 240px;
	background-color: var(--disco-bg-default);
}

.product-sidebar-modal .modal-header {
	background-color: var(--bg-light);
}

.product-sidebar-fade {
	width: 100%;
	height: 80px;
	position: fixed;
	bottom: 0px;
	background: linear-gradient(
		to bottom,
		rgba(255, 255, 255, 0),
		rgba(255, 255, 255, 1)
	);
	pointer-events: none;
}

.product-sidebar-details {
	width: 100%;
	position: relative;
	top: -5px;
}

.product-sidebar-details .remote-inp-container {
	margin-bottom: 10px;
	width: 100%;
}

.product-sidebar-details .remote-inp-container .tooltip-content p {
	white-space: initial;
}

.product-sidebar-details .remote-inp {
	width: 90% !important;
	font-size: 1.2rem;
}

.product-sidebar-toggle {
	margin-top: 0px;
	font-size: 1.1rem;
	background-color: transparent;
	color: var(--text-light);
	border: none;
	box-shadow: none;
}

.product-sidebar-toggle .toggle {
	position: relative;
	top: 1px;
}

.product-sidebar-toggle.disco-inp-container {
	padding-left: 0px;
}

.product-sidebar-edit-variants-btn {
	width: 100%;
}

.product-sidebar-modal-btn-group {
	justify-content: space-between;
}

.product-sidebar-modal .disco-modal-header {
	border-bottom: 2px solid var(--text-stroke-stroke, rgba(1, 2, 4, 0.1));
	background-color: var(--disco-bg-light);
}

.product-sidebar-image-picker {
	width: 100%;
}

.product-sidebar-select-image-container {
	justify-content: space-between;
}

.product-sidebar-image {
	width: var(--product-sidebar-image-width);
	height: var(--product-sidebar-image-width);
}

.product-sidebar-image img {
	width: var(--product-sidebar-image-width);
	height: var(--product-sidebar-image-width);
	border-radius: 6px;
}

.product-sidebar-modal .disco-status-label-info {
	--bg: var(--disco-bg-mid);
}

.product-sidebar-modal .disco-status-label-warning .disco-status-label-icon {
	color: var(--disco-text-colored);
}

.product-sidebar-modal .disco-status-label-error .disco-status-label-icon {
	color: var(--disco-text-colored);
}
