.toggle-group-heading {
	padding-bottom: 10px;
	color: var(--text);
	font-size: 1.2rem;
}

.toggle-group .collapsable-toggle {
	right: 6px;
}

.toggle-group .edit-view {
	width: 100%;
}

.toggle-group .label {
	display: flex;
	align-items: center;
	justify-content: space-between;
	text-transform: initial;
	font-size: 1.4rem;
	font-weight: 300;
	color: var(--text-light);
}

.toggle-group-expanded {
	position: relative;
}

.toggle-group-expanded::before {
	content: "";
	height: calc(100% - 58px);
	width: 1px;
	position: absolute;
	background-color: var(--text-light-5);
	top: 35px;
}

.toggle-group-nested .label {
	color: var(--text-light-3);
}

.toggle-group-nested {
	display: flex;
	width: 100%;
	align-items: center;
	flex: auto 0 0;
}

.toggle-group-nested-line {
	height: 1px;
	display: block;
	margin-right: 6px;
	content: "";
	background-color: var(--text-light-5);
	width: 15px;
	left: 0;
}

.toggle-group .labeled-inp {
	margin: 0 !important;
}

.toggle-group .toggle-inp-container {
	border: none !important;
	box-shadow: none !important;
	flex: auto 0 0;
	margin: 0 !important;
	padding: 0 !important;
	width: initial !important;
}

.toggle-group .edit-view-status {
	display: none;
}

.toggle-group .collapsable-content {
	padding-top: 10px;
}

.toggle-group .toggle .tooltip-content {
	left: 105%;
	top: -30%;
}
