.product-row {
	position: relative;
	width: 100%;
	font-size: 1rem;
	color: var(--text);
	background-color: #fcfcfc !important;
	font-weight: 600;
	vertical-align: middle;
	cursor: pointer;
	transition: background-color 0.12s ease-in-out, box-shadow 0.12s ease-in-out;
}

.product-row td {
	padding: 15px;
	text-align: center;
}

.product-row-img {
	height: 70px;
	width: 70px;
	object-fit: contain;
	border-radius: 10px;
}

.product-row-actions {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 160px;
}

.product-row-btn {
	height: 30px;
	width: 90%;
}

.product-row-delete-btn {
	border-color: var(--red) !important;
	color: var(--red) !important;
	opacity: 0.9;
}

.product-row-delete-btn:hover {
	background-color: var(--red) !important;
	color: var(--bg-light) !important;
}

.product-row-reorder {
	vertical-align: middle;
	font-size: 1.8rem;
	position: relative;
	top: 40px;
	display: flex;
	align-items: center;
	color: var(--text-light-3);
	cursor: pointer;
	padding-left: 10px;
}
.product-row-reorder span {
	color: var(--theme);
	padding: 10px;
	display: block !important;
	font-size: 1.3rem;
	font-weight: 500;
	transform: scale(0.9);
	border-radius: 10px;
	margin-left: 8px;
	min-width: 40px;
	min-height: 40px;
	background-color: #f3f7ff;
	transition: 0.12s ease-in-out;
}

.product-row-price {
	white-space: nowrap;
	color: var(--text-light-3);
}
.product-row-discount {
	color: var(--text-light-3);
}
.product-row-warning {
	color: var(--text-light-4);
	font-size: 1rem;
	font-weight: 500;
	margin-top: 4px;
}
.product-row-loader {
	margin: auto;
}

/* Drag css */
.product-row-in-drag {
	background-color: #f3f7ff !important;
	box-shadow: 0px 4px 12px rgba(87, 146, 255, 0.4),
		0px 1px 5px rgba(3, 25, 38, 0.15);
	outline: 2px solid var(--theme) !important;
}

.product-row-in-drag .product-row-reorder {
	color: var(--theme);
}
.product-row-in-drag .product-row-reorder span {
	background-color: var(--theme);
	color: #fff;
}

.product-row-delete-modal .modal-content {
	display: flex;
	flex-direction: row !important;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.product-row-delete-modal .modal-content p {
	width: 100%;
	font-size: 1.3rem;
	color: var(--text-light);
	text-align: center;
	margin-bottom: 25px;
}

.product-row-delete-modal .modal-content .btn {
	margin: 0 5px;
}
