.survey-question {
	position: relative;
	width: 100%;
	margin-top: 10px;
	padding: 20px;
	transition: background-color 0.2s;
	overflow: hidden;
	cursor: pointer;
	display: flex;
	flex-wrap: wrap;
	border-radius: 6px;
}
/* .question-text-toggle {
	position: absolute;
	top: 50px;
	right: 50px;
	font-size: 1.5rem;
} */

/* .survey-question .label {
	background-color: inherit;
	text-transform: uppercase;
	margin-top: 0 !important;
	font-size: 1.05rem;
} */

.survey-question h3 {
	background-color: inherit;
	margin-top: 12px;
	margin-bottom: 15px;
	font-size: 1.3rem;
}

.survey-question-open {
	background-color: var(--bg) !important;
}

/* .survey-question-active .label {
	color: var(--theme) !important;
	font-weight: 600;
} */

.survey-question-option {
	display: flex;
	align-items: center;
	width: 100%;
	margin: 10px 0;
	font-size: 1.05rem;
	font-weight: 500;
	flex-wrap: wrap;
	padding: 0 2px;
}

.survey-question-option:nth-of-type(1) {
	margin-top: 10px !important;
}

.survey-question-option img {
	margin-right: 10px;
	height: 23px;
	width: 23px;
	border-radius: 6px;
	object-fit: contain;
}

.survey-section {
	position: relative;
	margin-top: 30px;
	font-size: 1.2em;
	font-weight: 600;
	width: 100%;
	color: var(--text-light);
}
.survey-section-last {
	margin-bottom: 20px;
}

/* .survey-section .label {
	padding: 0 !important;
	margin: 7px 0 !important;
	font-size: 0.95rem;
	text-transform: uppercase;
} */
.survey-question .form-status {
	width: 100% !important;
	margin-top: 50px !important;
}

/* .survey-results .label {
	margin-bottom: 22px !important;
} */

.survey-question-option-follow-up {
	flex: 0 0 auto;
	width: 100% !important;
	margin-top: 10px;
	font-size: 1.1rem;
	font-weight: 500;
}

.survey-question-option-follow-up span {
	font-weight: 500;
	color: var(--text-light);
	text-transform: uppercase;
	margin-right: 8px;
	font-size: 1rem;
	border-radius: 20px;
}
.survey-question-collapsable {
	background-color: var(--bg);
	padding: 20px 20px;
	border-radius: 12px;
	border: 0px !important;
}
.survey-question-collapsable .collabsable-toggle {
	top: 12px;
}
.survey-question-collapsable .collapsable-head {
	color: var(--theme);
}
/* .survey-question-collapsable-children {
	margin-top: 20px;
} */
