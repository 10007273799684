.new-edit-view {
	position: relative;
}
.new-edit-view-action {
	right: 0px;
	position: absolute;
	align-items: center;
	top: 0px;
}
.new-edit-view-action .tag-btn {
	margin-left: var(--disco-spacing-mc);
}
.new-edit-view-status {
	margin-top: 15px;
}
