.klaviyo-emails-config .template-block {
	margin: 15px 0;
}

.klaviyo-emails-config .labeled-inp {
	margin-top: 15px !important;
}

.emails-config-row {
	display: flex;
	justify-content: space-between;
	color: var(--text-light);
	font-weight: 500;
	font-size: 1.25rem;
	align-items: center;
	margin: 20px 0;
}

.emails-config-row-hero {
	display: flex;
	align-items: center;
}

.emails-config-row .checkbox {
	margin-right: 10px;
	border-radius: 50%;
	height: 27px;
	width: 27px;
}

.emails-config-row-action {
	display: flex;
	align-items: center;
	justify-content: center;
}

.emails-config-row-action > span {
	font-size: 1.4rem;
	margin-right: 5px;
	color: var(--theme-dark);
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 26px;
	width: 26px;
	background-color: transparent;
	border-radius: 50%;
	padding-top: 2px;
	transition: background-color 0.2s;
	cursor: crosshair;
}
.emails-config-row-action > span:hover {
	background-color: var(--theme-light-2);
}
.emails-config-row .checkbox-checkmark {
	background-color: var(--theme) !important;
	border-radius: 50% !important;
	font-size: 1.6rem !important;
}

.emails-config-row a {
	font-size: 1rem;
	color: var(--theme);
}

.shopify-emails-config-service-btn {
	margin-top: 15px;
}

.shopify-emails-config-back {
	font-size: 1.5rem;
	padding-bottom: 10px;
	color: var(--text-light);
	border-bottom: 1px solid var(--text-light-5);

	margin: 20px auto 10px auto;
	cursor: pointer;
}

.shopify-emails-config-back span {
	font-size: 1.8rem;
	display: inline-block;
	vertical-align: middle;
	color: var(--text-light-4);
	position: relative;
	top: 1.5px;
}

.shopify-templates > .loader {
	margin: 10px auto;
}
.unimplemented-emails-config > h3 {
	margin-top: 20px;
	font-size: 1.8rem;
	letter-spacing: -1px;
}

.unimplemented-emails-config > p {
	font-size: 1.4rem;
	margin-top: 6px;
	color: var(--text-light);
}

.unimplemented-emails-config > .btn {
	margin-top: 20px;
}

.unimplemented-emails-config > .labeled-inp {
	margin-top: 20px;
}

.unimplemented-emails-config-message {
	padding: 10px;
	border-radius: 8px;
	background-color: var(--light-green);
	text-align: center;
	font-size: 1.2rem;
	margin-top: 20px;
	color: rgba(0, 0, 0, 0.7);
	font-weight: 500;
}
