.utm-param {
	background-color: var(--disco-bg-default);
	border-radius: 8px;
	overflow-wrap: anywhere;
}

.utm-param .disco-loader {
	flex: 0 0 auto;
}

.utm-param-delete {
	background-color: var(--disco-red-mid);
	height: 44px;
	flex: 0 0 auto;
}

.utm-param-exit-btn {
	cursor: pointer;
	background-color: transparent;
	border: none;
	box-shadow: none;
	padding: 0 0 0 0;
}
