.emails-transactional-step-skip {
	width: 25% !important;
	margin-left: 10px;
}

.emails-transactional-step > main {
	width: 44%;
}

.emails-transactional-step > aside {
	overflow: auto;
	width: 56%;
	padding: 0;
}

.emails-step-final-content {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.emails-step-final-content > h1 {
	margin-top: 40px;
	font-size: 6rem;
	font-weight: 800;
	letter-spacing: -1px;
	color: var(--text-light);
}

.emails-step-final-content > h3 {
	font-weight: 300;
	color: var(--text-light-3);
	font-size: 2.1rem;
	margin-top: 20px;
}

.emails-step-final-content-action {
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.emails-step-final-content .form-status {
	margin-top: 15px;
}
