.utm-modal {
	overflow: auto;
	width: 800px;
	max-width: initial;
}

.utm-modal-link {
	text-decoration: underline;
	color: var(--theme);
}

.utm-params-toggle .status-toggle {
	align-items: center;
}

.utm-params-toggle .status-toggle-text p {
	color: var(--disco-text-mid);
}

.utm-modal .disco-modal-header {
	border-bottom: 2px solid var(--text-stroke-stroke, rgba(1, 2, 4, 0.1));
	background-color: var(--disco-bg-light);
}

.utm-modal .floating-modal .modal-header {
	padding: var(--disco-spacing-sm) var(--disco-spacing-md) !important;
}
