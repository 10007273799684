.products-match-table {
	box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.075);
}

.products-match-table tr {
	border-radius: 7px !important;
	background-color: transparent;
}

.products-match-table th {
	background-color: transparent !important;
	text-transform: initial !important;
}

.products-match {
	margin-top: 30px;
}
.products-match .lib-table-search-inp {
	width: 150px;
	min-width: 0;
}

.products-match > .loader {
	margin: 50px auto;
}

.products-match-option {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.products-match-option img {
	height: 23px;
	width: 23px;
	object-fit: cover;
	border-radius: 6px;
	margin-right: 8px;
}

.products-match-option h4 {
	font-weight: 400;
	font-size: 1.1rem;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.products-match-select {
	padding: 8px;
	border-radius: 5px !important;
}

.products-match-select .select-icon {
	/* position: relative; */
	top: 7px;
}

.products-match-select .select-selected {
	padding: 0 3px;
}

.products-match-select .select-selected h4 {
	font-weight: 600 !important;
}

.products-match-select .inp-container {
	min-width: 0;
	width: 76%;
	margin-top: 7px;
}

.products-match-select .inp {
	min-width: 0;
	width: 100%;
}

.product-match-merged {
	width: 100%;
	background-color: var(--bg-light);
	padding: 20px;
	border-radius: 12px;
	border: 2px solid var(--theme);
}

.product-match-merged h3 {
	font-size: 1.7rem;
	color: var(--text-light);
	letter-spacing: -1px;
}

.product-match-merged p {
	color: var(--text-light-3);
	font-size: 1.4rem;
	margin-top: 12px;
}
