.modal-sheet-header {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-sheet-header h3 {
	font-size: 1.33rem;
}

.modal-sheet-header-cancel {
	position: absolute;
	left: 0px;
	font-size: 1.16rem;
	color: var(--text-light-4);
}

.modal-sheet-header-icon {
	color: var(--text);
	font-size: 1.8rem;
	margin-right: 4px;
	margin-bottom: 3px;
}
