.oauth-embed-page {
	--page-bg: #fcfdfd;
	--header-1: #010204;
	--header-3: #5c6077;
	--brand-highlight: #0b69ff;
	--redirect-btn-bg: linear-gradient(180deg, #5792ff 0%, #0062f4 100%);
}

.oauth-embed-page {
	flex-direction: column !important;
	background-color: var(--page-bg);
	justify-content: center;
	align-items: center;
	text-align: center;
	overflow-x: hidden !important;
	padding-top: 50px;
	width: 100%;
}

.oauth-embed-page h1,
.oauth-embed-page h3,
.oauth-embed-page h4 {
	font-family: Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	padding-bottom: 15px;
	padding-top: 15px;
}

/***** TOP PORTION OF THE PAGE (HEADER) *****/

.oauth-embed-page h1 {
	color: var(--header-1);
	font-weight: 900;
	font-size: 3rem;
}

.oauth-embed-page h3 {
	color: var(--header-3);
	font-weight: 400 !important;
}

.oauth-embed-page strong {
	color: var(--header-1);
	font-weight: 700 !important;
}

/***** TOP-MIDDLE PORTION OF THE PAGE (CONTENT) *****/

.oauth-embed-page .oauth-embed-page-body {
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;
	padding-top: 15px;
	padding-bottom: 15px;
}

.oauth-embed-page .oauth-embed-page-body .platform-redirect-btn {
	margin: 0 !important; /* Remove inherited margin */

	box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
	background: var(--redirect-btn-bg);
	font-size: 1.25rem !important;
	border: 1px solid #0b69ff;

	padding: 25px 20px;
	line-height: 0; /* Center text after button expansion */
}

.oauth-embed-page .oauth-embed-page-footer {
	color: var(--header-3);
	padding-bottom: 50px;
	font-weight: 800;
	font-size: 1rem;
}

.oauth-embed-page h4 {
	font-size: 1.15rem !important;
	font-weight: 400 !important;
}

.oauth-embed-page .oauth-embed-page-footer #num-of-brands {
	color: var(--brand-highlight);
	font-size: 1.15rem !important;
	font-weight: 800 !important;
}

/***** BOTTOM PORTION OF THE PAGE (FOOTER) *****/

.oauth-embed-page .oauth-embed-page-footer-img {
	max-width: 100%;
}

.oauth-embed-page .oauth-embed-page-footer-img img {
	width: 100%;
}
