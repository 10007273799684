.products-page .page-content {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row !important;
	align-items: flex-start !important;
	justify-content: flex-start !important;
}

.products-page .embedded-preview-page-child {
	width: 0%;
}

.products-page-action {
	width: 97%;
	display: flex;
	justify-content: flex-start;
	margin: 24px auto 30px auto;
}

.products-page-action .btn {
	margin-left: 8px;
	margin-right: 8px;
	width: 180px;
}

.products-page-cont {
	position: relative;
	width: calc(96vw - 500px - var(--side-bar-width));
	overflow: auto;
	margin-top: 60px;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	padding-bottom: 40px;
}

.products-page-widget-card .widget {
	min-width: 0 !important;
	width: 100% !important;
}

.products-page-widget-card {
	position: fixed;
	flex-direction: column;
	width: 470px !important;
	right: 4%;
	min-width: 0 !important;
	max-width: initial !important;
	top: 268px;
	border-radius: 15px !important;
	padding: 30px !important;
}

.products-page-widget-card h2 {
	padding: 0 6px;
	font-size: 2rem;
}

.products-page-cont h1 {
	width: 94%;
	font-size: 3rem;
	text-align: left;
	margin: auto;
}

.products-page-none {
	color: var(--text-light) !important;
	background-color: transparent !important;
	font-size: 1.8rem !important;
}
