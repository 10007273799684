.list-view {
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	margin-top: 10px;
}
.list-view-entry > .collapsable-toggle,
.list-view-entry > .collapsable-grabber {
	top: 20px;
	font-size: 1.7em;
}
.list-view-entry > .collapsable-toggle {
	right: 40px;
}
.list-view .list-view-cont {
	width: 100%;
}

.list-view .loader:not(.toggle-inp-loader) {
	margin: auto !important;
	margin-top: 10px !important;
}

.list-view-empty {
	width: 100%;
	background-color: var(--bg);
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-radius: 10px;
}

.list-view-empty span {
	font-size: 2.4rem;
	color: var(--theme);
}

.list-view-empty p {
	margin-top: 10px;
	font-size: 1.2rem;
	font-weight: 600;
	color: var(--text-light);
	text-align: center;
}

.list-view-empty .badge-btn {
	margin-top: 20px;
}
.list-view-reorder-btn {
	width: 100%;
	margin-top: 12px;
}
.list-view-reorder-container {
	width: 100%;
}
.list-view-reorder-option {
	width: 100%;
	position: relative;
	background-color: rgb(255, 255, 255);
}
.list-view-reorder-loading {
	margin: auto;
	margin-top: 12px !important;
}
