.page-boundary {
	width: 100vw;
	height: 100vh;
	color: var(--bg-light);
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
	background-image: var(--disco-gradient);
}

.page-boundary h1 {
	text-align: center;
	font-weight: 200;
	letter-spacing: 2px;
	font-size: 2.2rem;
}

.page-boundary h1 img {
	display: block;
	margin: auto;
	width: 25%;
	margin-top: 15px;
	max-width: 1000px;
}

.page-boundary h3 {
	margin-top: 30px;
	font-weight: 400;
	font-size: 1.6rem;
}

.page-boundary-action {
	margin-top: 25px;
}
.page-boundary-action-secondary {
	position: absolute;
	left: 20px;
	bottom: 20px;
}

.page-boundary-action:hover {
	background-color: #fff !important;
	color: var(--theme) !important;
}

.page-boundary-extra {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.how-it-works-modal {
	/* background-image: url("../../img/howItWorks.png"); */
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;

	height: 95%;
	max-height: 95%;
	color: rgba(0, 0, 0, 0.6);
}
