/* TODO: Cleanup unused CSS, now only being used for LoginTeamStep */
.login-step .onboarding-step-main {
	overflow: hidden;
}
.login-step .onboarding-step-main-wrapper {
	justify-content: center;
	flex-direction: column;
	padding: 50px;
	display: flex;
}

/* .login-step .onboarding-step-main-header {
	width: 65%;
} */

.login-step .guided-view-content .labeled-inp:nth-of-type(1) {
	margin-top: -25px;
}
.login-step-form-cont {
	display: flex;
	flex-direction: column;
	min-width: 425px;
	width: 60%;
	margin-top: 80px;
}
.login-step-form-cont .form-status {
	margin-top: 24px;
}
.login-step-btn-cont {
	display: flex;
	/* align-items: flex-start; */
	/* width: 100%; */
	margin-right: auto;
	align-items: flex-end;
}
.login-step-main-btn {
	margin-right: auto;
	margin-left: 0px;
	color: var(--text);
	box-shadow: 0px 1px 3px rgba(3, 25, 38, 0.15);
}

.login-step-secondary-btn {
	margin: 0px auto 14px 24px;
	font-size: 1.3rem;
	font-weight: 400;
	/* margin: 0px auto 8px 35px; */
	color: var(--theme);
}
