.install-step-feature {
	display: flex;
	margin-top: 12px;
	color: var(--text-light-1);
	font-size: 1.5rem;
	font-weight: 400;
}

.install-step-features {
	margin-top: 22px;
}

.install-step-feature span {
	margin-right: 8px;
	font-size: 2.1rem;
}
