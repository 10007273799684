.survey-options {
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	flex-wrap: wrap;
}
.survey-options .reorder-container {
	width: 100%;
}
.survey-options-add {
	width: 100%;
	height: 42px;
	border: 1px solid var(--text-light-4);
}
