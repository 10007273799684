.set-view {
	width: 85%;
	margin: 8px 0;
}

.set-view-wide {
	width: 100%;
}

.set-view h5 {
	font-weight: 400;
	letter-spacing: 1px;
	font-size: 0.9rem;
	color: var(--text-light);
	position: relative;
	padding-top: 15px;
	text-transform: uppercase;
}

.set-view h4 {
	padding-top: 6px;
	font-weight: 600;
	font-size: 1.2rem;
}

.set-view-edit {
	font-size: 0.9rem;
	position: absolute;
	font-weight: 600;
	top: 10px;
	right: 0px;
	color: var(--theme);
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 6px 9px;
	border-radius: 30px;
	outline: none;
	transition: background-color 0.2s;
}

.set-view-loader {
	position: absolute !important;
	top: 10px;
	right: 0px;
}

.set-view-edit:hover {
	background-color: rgba(0, 0, 0, 0.04);
}

.set-view-edit-icon {
	margin-right: 5px;
	font-size: 1rem;
}
.set-view-tooltip {
	margin-left: 6px;
	position: relative;
	top: 4px;
	color: var(--text-light) !important;
	font-size: 1.3rem !important;
	justify-content: center;
	align-items: center;
}
.set-view-tooltip-content {
	bottom: 16px;
}

.set-view-tooltip-content h2 {
	font-size: 1.5rem !important;
}
