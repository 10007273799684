.stripe-card {
	margin: 30px auto 0 auto;
	width: 95%;
	padding: 15px;
	border-radius: 10px;
	background-color: var(--bg-light);
	border: 1.5px solid var(--text-light-4);
}

.stripe-card-setup .disco-btn {
	margin: 20px auto 15px auto;
	width: 160px !important;
	height: 42px !important;
}

.stripe-card-setup .disco-loader {
	margin: 20px auto 15px auto;
}
.stripe-card-disable {
	pointer-events: none;
}
